import { Button, Form, Grid, theme, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { severalStore } from "../../../stores";
import Header from "../header/Header";
import { Select } from "../../../components";
import { useEffect, useState } from "react";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

function TypeRegister() {
  const { token } = useToken();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { entities, getEntities } = severalStore();
  const [idEntity, setIdEntity] = useState("0");

  useEffect(() => {
    entities.length <= 0 && getEntities();
  }, [entities.length, getEntities]);

  const styles: any = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
      borderRadius: "5px",
      boxShadow: "1px 1px 12px 0px rgba(0, 0, 0, 0.1)",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/coffee-background.png"
          })`,
        }}
      >
        <section style={styles.section}>
          <div style={styles.container}>
            <div style={styles.header}>
              <Title style={styles.title}>Registro</Title>
              <Text style={styles.text}>
                Seleccione a que tipo de entidad pertenece para iniciar el
                proceso de registro.
              </Text>
            </div>
            <Form>
              <Form.Item label="Entidad:">
                <Select
                  name={"user_types_id"}
                  value={idEntity}
                  style={{ width: "100%" }}
                  placeholder="Seleccione una opción"
                  options={entities.map((x: any) => ({
                    value: String(x.id),
                    label: x.description,
                  }))}
                  onChange={(e: any) => setIdEntity(e.target.value)}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: "0px" }}>
                <Button
                  block={true}
                  type="primary"
                  onClick={() => {
                    if (String(idEntity) !== "0")
                      navigate(`/register/${idEntity}`);
                  }}
                >
                  CONTINUAR
                </Button>
                <div style={styles.footer}>
                  <Text style={styles.text}>Ya tengo una cuenta</Text>{" "}
                  <Link to="/login">Iniciar sesión</Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        </section>
      </div>
    </>
  );
}

export default TypeRegister;
