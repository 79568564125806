import type { CollapseProps } from "antd";
import { Collapse } from "antd";

interface IProps {
  items: CollapseProps["items"];
  size?: any;
  defaultActiveKey?: any;
  style?: React.CSSProperties;
}

function Accordion({
  items,
  size = "middle",
  defaultActiveKey = ["1"],
  style = {},
}: IProps) {
  return (
    <Collapse
      items={items}
      defaultActiveKey={defaultActiveKey}
      size={size}
      style={{ ...style }}
    />
  );
}

export default Accordion;
