function About() {
  return (
    <>
      <section>
        <h1 className="associative-forms-list-title">Quiénes somos</h1>
        <p>
          Somos una plataforma colaborativa de gestión de información que tiene
          el propósito de:
        </p>
        <ol style={{ fontSize: 14 }}>
          <li>
            Apoyar la definición y seguimiento de planes y proyectos de
            reincorporación socioeconómica.
          </li>
          <li>
            Contribuir al fortalecimiento organizativo respondiendo a la
            nececidad de generar capacidades para la interlocución de las formas
            asociativas de reincorporados con los actores que intervienen en la
            ruta de implementación del acuerdo <em>Final De Paz</em>.
          </li>
          <li>
            Operar en red en el territorio para la organización ECOMUN, las
            formas asociativas y el CNR componente FARC.
          </li>
          <li>
            Darle reconocimiento y visibilidad a las formas asociativas y
            proyectos productivos.
          </li>
          <li>
            Fortalecer la capacidad de gestión y gobernabilidad de las
            organización de los reincorporados y la construcción de{" "}
            <em>Territorios en Paz</em>.
          </li>
        </ol>
      </section>

      <section>
        <h1 className="associative-forms-list-title text-center">Misión</h1>
        <p style={{ fontSize: 14 }}>
          Fortalecer la generación, rescate y difusión de conocimientos y
          propósitos comunes que faciliten el uso de información por parte de
          las formas asociativas de reincorporados, comunidades e instituciones
          que les permita desarrollar acciones estratégicas para la
          planificación local y regional en los diferentes aspectos
          organizativos y económicos.
        </p>
      </section>

      <section>
        <h1 className="associative-forms-list-title">Visión</h1>
        <p style={{ fontSize: 14 }}>
          Hacer de REDCOMUN la plataforma de gestión de información de y para
          las formas asociativas en donde todos contribuimos y todos nos
          beneficiamos.
        </p>
      </section>
    </>
  );
}

export default About;
