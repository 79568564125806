import { Button, Form, Grid, Input, theme, Typography, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { userStore } from "../../stores";
import Header from "./header/Header";
import { routes } from "../../constants";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

function Login() {
  const [messageApi, contextHolder] = message.useMessage();
  const { token } = useToken();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { login, token: myToken } = userStore();

  const onFinish = async (values: any) => {
    const result = await login(values.email, values.password);
    if (result[0] !== "") {
      navigate(routes.home);
      return;
    }
    if (result[1] === "warning") {
      messageApi.open({
        type: "warning",
        content: "Usuario no ha sido autorizado para el ingreso",
      });
      return;
    }

    messageApi.open({
      type: "error",
      content: "Email o contraseña incorrectos",
    });
  };

  const styles: any = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
      borderRadius: "5px",
      boxShadow: "1px 1px 12px 0px rgba(0, 0, 0, 0.1)",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  if (myToken) {
    return <Navigate to={routes.home} />;
  }
  return (
    <>
      {contextHolder}
      <Header />
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/coffee-background.png"
          })`,
        }}
      >
        <section style={styles.section}>
          <div style={styles.container}>
            <div style={styles.header}>
              <Title style={styles.title}>Iniciar sesión</Title>
              <Text style={styles.text}>
                Diligencie sus datos para ingresar a su cuenta.
              </Text>
            </div>
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              layout="vertical"
              requiredMark="optional"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Ingrese correo!",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  type="email"
                  placeholder="Correo electronico"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Ingrese contraseña!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Contraseña"
                />
              </Form.Item>
              <Form.Item>
                <Link
                  style={{ ...styles.forgotPassword }}
                  to="/forgot-password"
                >
                  Recordar contraseña
                </Link>
              </Form.Item>
              <Form.Item style={{ marginBottom: "0px" }}>
                <Button block={true} type="primary" htmlType="submit">
                  Ingrsesar
                </Button>
                <div style={styles.footer}>
                  <Text style={styles.text}>No tengo una cuenta</Text>{" "}
                  <Link to="/type-register">Registrarme</Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
