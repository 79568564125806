import { useEffect, useState } from "react";
import uuid from "react-uuid";
import { severalStore } from "../../../../../stores";
import { Alert, Col, Form, Input, Modal, Row, Select } from "antd";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.document_number === "") errors.document_number = true;
  if (data.first_name === "") errors.first_name = true;
  if (data.last_name === "") errors.last_name = true;
  if (data.cellphone_number === "" || !data.cellphone_number)
    errors.cellphone_number = true;
  if (data.email_address === "" || !data.email_address)
    errors.email_address = true;

  return errors;
};

function CreateEdit({ open, handleClose, data, create, edit }: any) {
  const { documentTypes }: any = severalStore();
  const [representative, setRepresentative] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setRepresentative({ ...data });
  }, [data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setRepresentative((x: any) => ({ ...x, [name]: value }));
    setErrors({});
  };

  const handleClickSave = () => {
    const errors = validateData(representative);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (representative.new) {
      const uid = uuid();
      const newRepresentative = {
        ...representative,
        type_o: representative.type === "S" ? "Suplente" : "Titular",
        document_type_o: documentTypes.find(
          (x: any) => x.id === representative.document_type
        )?.name,
      };
      create({ ...newRepresentative, id: uid, key: uid });
      return;
    }

    edit({ ...representative, new: false });
    setErrors({});
  };
  return (
    <Modal
      title={`${data.new ? "Agregar" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${data.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"1000px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Tipo documento">
              <Select
                value={representative.document_type}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={documentTypes.map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
                onChange={(value: any) =>
                  handleChange({ target: { name: "document_type", value } })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Documento">
              <Input
                placeholder="Documento"
                name="document_number"
                value={representative.document_number}
                onChange={handleChange}
                type="number"
              />
              {errors?.document_number && (
                <Alert message={errors.document_number} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Nombres">
              <Input
                placeholder="Nombres"
                name="first_name"
                value={representative.first_name}
                onChange={handleChange}
              />
              {errors?.first_name && (
                <Alert message={errors.first_name} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Apellidos">
              <Input
                placeholder="Apellidos"
                name="last_name"
                value={representative.last_name}
                onChange={handleChange}
              />
              {errors?.last_name && (
                <Alert message={errors.last_name} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Tipo">
              <Select
                value={representative.type}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={[
                  { value: "T", label: "Titular" },
                  { value: "S", label: "Suplente" },
                ]}
                onChange={(value: any) =>
                  handleChange({ target: { name: "type", value } })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Celular">
              <Input
                placeholder="Celular"
                name="cellphone_number"
                value={representative.cellphone_number}
                onChange={handleChange}
                type="number"
              />
              {errors?.cellphone_number && (
                <Alert
                  message={errors.cellphone_number}
                  type="error"
                  showIcon
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Email">
              <Input
                placeholder="Email"
                name="email_address"
                value={representative.email_address}
                onChange={handleChange}
                type="email"
              />
              {errors?.email_address && (
                <Alert message={errors.email_address} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}></Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEdit;
