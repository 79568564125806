import { userStore, severalStore } from "../../../../stores";
import { enableButtonSave } from "../../../../utilities";
import { applicationModules } from "../../../../constants";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
} from "antd";
import { Select } from "../../../../components";
import dayjs from "dayjs";
import BankAccounts from "./bankInformation/Home";

function AdditionalInformation({
  handleClickSave,
  errors,
  associate,
  setValues,
  formAnt,
}: any) {
  const { permissions } = userStore();
  const {
    associateTypesVinculation,
    formSelected,
    associateRoles,
    associateRolesComittee,
    levelSchooling,
    maritalStatus,
    disabilities,
    ethnicGroups,
    associativeSituation,
    associativeFormInitials,
  } = severalStore();

  const watchVinculationType = Form.useWatch("vinculation_type", formAnt);
  const watchOtherVinculation = Form.useWatch("other_vinculation", formAnt);
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Fecha ingreso">
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              value={dayjs(associate?.admission_date)}
              onChange={(date: any, dateString: any) =>
                setValues("admission_date", dateString)
              }
            />
          </Form.Item>
          {errors?.admission_date && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.admission_date}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Su vinculación es" name={"vinculation_type"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={associateTypesVinculation.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
          {errors?.vinculation_type && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.vinculation_type}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      {String(watchVinculationType) === "2" && (
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Cargo" name={"work_position_id"}>
              <Select
                style={{ width: "100%" }}
                placeholder="Please select"
                options={associateRoles
                  .filter(
                    (y: any) =>
                      String(y.type_organization) ===
                      // @ts-ignore
                      String(formSelected?.type?.category)
                    //||  String(y.type_organization) === "0"
                  )
                  .map((x: any) => ({
                    value: x.id,
                    label: x.name,
                  }))}
              />
            </Form.Item>
            {errors?.work_position_id && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.work_position_id}
                type="error"
                showIcon
              />
            )}
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Rol" name={"associate_role_id"}>
              <Select
                style={{ width: "100%" }}
                placeholder="Please select"
                options={associateRoles
                  .filter((y: any) => String(y.type_organization) === "0")
                  .map((x: any) => ({
                    value: x.id,
                    label: x.name,
                  }))}
              />
            </Form.Item>
            {errors?.associate_role_id && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.associate_role_id}
                type="error"
                showIcon
              />
            )}
          </Col>
        </Row>
      )}

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Comité" name={"associate_role_committee_id"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={associateRolesComittee.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
          {errors?.associate_role_committee_id && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.associate_role_committee_id}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="age" label="Edad (años)">
            <Input placeholder="Edad (años)" disabled={true} />
          </Form.Item>
          {errors?.age && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.age}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Nivel escolaridad" name={"schooling_level"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={levelSchooling.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
          {errors?.schooling_level && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.schooling_level}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Estado civil" name={"marital_status"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={maritalStatus.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
          {errors?.marital_status && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.marital_status}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Discapacidades" name={"disability"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={disabilities.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
          {errors?.disability && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.disability}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="¿Tiene familiares en el proceso de reincorporación?"
            name={"has_reincorporated_family"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              option0={false}
              options={[
                { value: "N", label: "No" },
                { value: "S", label: "Si" },
              ]}
            />
          </Form.Item>
          {errors?.has_reincorporated_family && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.has_reincorporated_family}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="¿A Cuál grupo étnico pertenece?"
            name={"ethnic_group_id"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={ethnicGroups.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
          {errors?.ethnic_group_id && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.ethnic_group_id}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Se reconoce cómo?">
            <Checkbox
              checked={associate.lgbti}
              onChange={(e) => setValues("lgbti", e.target.checked)}
            >
              LGBTI
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="¿Es reincorporado/reincorporada?"
            name={"reincorporated"}
          >
            <Select
              style={{ width: "100%" }}
              option0={false}
              placeholder="Please select"
              options={[
                { value: 0, label: "No" },
                { value: 1, label: "Si" },
              ]}
            />
          </Form.Item>
          {errors?.reincorporated && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.reincorporated}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Situación asociativa al momento de la última asamblea"
            name={"associative_situation_id"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={associativeSituation.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
          {errors?.associative_situation_id && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.associative_situation_id}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Es asociado fundador?" name={"founding_partner"}>
            <Select
              style={{ width: "100%" }}
              option0={false}
              placeholder="Please select"
              options={[
                { value: 0, label: "No" },
                { value: 1, label: "Si" },
              ]}
            />
          </Form.Item>
          {errors?.founding_partner && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.founding_partner}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="¿Se encuentra vinculado a otra forma asociativa?"
            name={"other_vinculation"}
          >
            <Select
              style={{ width: "100%" }}
              option0={false}
              placeholder="Please select"
              options={[
                { value: 0, label: "No" },
                { value: 1, label: "Si" },
              ]}
            />
          </Form.Item>
          {errors?.other_vinculation && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.other_vinculation}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      {String(watchOtherVinculation) === "1" && (
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="¿Cuál o cuales?" name={"other_vinculation_which"}>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select"
                options={associativeFormInitials.map((x: any) => {
                  return {
                    value: x.id,
                    label: x.initials,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}></Col>
        </Row>
      )}

      <Row>
        <Col>
          <p className="section-form-title">Información de contacto</p>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="cellphone_number" label="Celular">
            <Input placeholder="Celular" type="number" />
          </Form.Item>
          {errors?.cellphone_number && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.cellphone_number}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="email" label="Correo électronico">
            <Input placeholder="Correo électronico" type="email" />
          </Form.Item>
          {errors?.email && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.email}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="section-form-title">Información bancaria</p>
        </Col>
      </Row>

      <BankAccounts
        form={formSelected}
        associate={associate}
        setValue={setValues}
      />

      <Divider />
      <Row className="row-form">
        <Col xs={24} md={18} xl={18}></Col>
        <Col xs={24} md={6} xl={6}>
          {enableButtonSave(applicationModules, permissions) && (
            <Button block={true} type="primary" onClick={handleClickSave}>
              Guardar
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default AdditionalInformation;
