import { useState } from "react";
import { Alert, Button, Col, Flex, Form, Input, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "../../../../components";
import { userStore } from "../../../../stores";
import { applicationModules } from "../../../../constants";
import { enableButtonSave, messageSweetalert } from "../../../../utilities";
import CreateEditPresentations from "./CreateEditPresentations";

const columnsPresentations = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Valor",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Unidad de medida",
    dataIndex: "unit",
    key: "unit",
    width: 200,
    render: (text: any, record: any, index: any) => {
      return `${record.unit_measurement ? record.unit_measurement.name : ""}`;
    },
  },
];

function Characterization({ handleChange, record, errors }: any) {
  const { permissions } = userStore();
  const [selectedPresentation, setSelectedPresentation] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onChangeSelectionPresentation = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    setSelectedPresentation(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  return (
    <Form name="cara" layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"Nombre"}>
            <Input
              placeholder=""
              name="name"
              onChange={handleChange}
              value={record.name}
            />
          </Form.Item>
          {errors?.name && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.name}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"Descripción"}>
            <Input
              placeholder=""
              name="description"
              onChange={handleChange}
              value={record.description}
            />
          </Form.Item>
          {errors?.description && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.description}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          {enableButtonSave(applicationModules, permissions) && (
            <Flex style={{ marginBottom: 10 }}>
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                disabled={selectedPresentation.length > 0}
                onClick={() => openCreate(selectedPresentation)}
              >
                Agregar presentación
              </Button>
              <Button
                type="primary"
                size="large"
                icon={<EditOutlined />}
                disabled={selectedPresentation.length <= 0}
                onClick={() => openEdit(selectedPresentation)}
              >
                Editar presentación
              </Button>
            </Flex>
          )}
          <Table
            title={() => "Presentaciones"}
            dataSource={
              record.presentations.map((x: any) => ({ ...x, key: x.id })) || []
            }
            columns={columnsPresentations}
            bordered
            scroll={{ x: "1000" }}
            onChangeSelect={onChangeSelectionPresentation}
          />
        </Col>
        {modalOpen && (
          <CreateEditPresentations
            open={modalOpen}
            handleClose={setModalOpen}
            dataAll={record.presentations}
            data={
              selectedPresentation.length > 0
                ? { ...selectedPresentation[0], new: false }
                : {
                    id: 0,
                    name: "",
                    cost: "",
                    unit_id: 0,
                    product_id: record.id,
                    unit_measurement: null,
                    new: true,
                  }
            }
            handleChange={handleChange}
          />
        )}
      </Row>
    </Form>
  );
}

export default Characterization;
