import { Button, Col, Divider, Form, Input, Row } from "antd";
import { enableButtonSave } from "../../../../utilities";
import { applicationModules } from "../../../../constants";
import { userStore } from "../../../../stores";

function ContactInformation({ form, setValue }: any) {
  const { permissions } = userStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="contact_cellphone_number" label="Celular">
            <Input placeholder="Celular" type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="contact_email" label="Email">
            <Input placeholder="Email" type="email" />
          </Form.Item>
        </Col>
      </Row>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="contact_website" label="Sitio web">
            <Input placeholder="Sitio web" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="contact_facebook" label="Facebook">
            <Input placeholder="Facebook" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="contact_twitter" label="Twitter">
            <Input placeholder="Twitter" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="contact_instagram" label="Instagram">
            <Input placeholder="Instagram" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Row className="row-form">
        <Col xs={24} md={18} xl={18}></Col>
        <Col xs={24} md={6} xl={6}>
          {enableButtonSave(applicationModules, permissions) && (
            <Button block={true} type="primary" htmlType="submit">
              Guardar
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ContactInformation;
