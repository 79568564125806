import { Col, Form, Row } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function GoodPractices() {
  const { diagnosticQuestions, questionsOptionsDiagnostic } = severalStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(83, diagnosticQuestions).question}
            name={"q83"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 83)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(84, diagnosticQuestions).question}
            name={"q84"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 84)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(85, diagnosticQuestions).question}
            name={"q85"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 85)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(86, diagnosticQuestions).question}
            name={"q86"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 86)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(87, diagnosticQuestions).question}
            name={"q87"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 87)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(88, diagnosticQuestions).question}
            name={"q88"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 88)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default GoodPractices;
