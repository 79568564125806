import { useState, useEffect } from "react";
import "./style.scss";
import { Card, Row, Col } from "antd";
import { TeamOutlined, BorderOutlined } from "@ant-design/icons";
import { paxios } from "../../../../http";
import { URL_SERVER } from "../../../../config";

function Home() {
  const [data, setData] = useState({
    numberOfForms: 0,
    numberOfProjects: 0,
  });
  const getData = async () => {
    try {
      const u = await paxios.get(
        `${URL_SERVER}/home_public/getNumbersFormsAndProyects`
      );
      const data = u.data;
      setData(data);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Card className="card-container-footer map-bottom-sec">
      <Row className="bottom-section">
        <Col md={8} lg={10} className="col-peace-territory">
          <h1>Colombia</h1>
          <p className="text-muted">Territorio de paz</p>
        </Col>
        <Col md={8} lg={7}>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="icon-bg">
                <TeamOutlined style={{ color: "#3dd6d6", fontSize: 20 }} />
              </div>
            </Col>
            <Col>
              <div>
                <h1 >{data.numberOfForms}</h1>
                <p className="text-muted">Formas asociativas</p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={8} lg={7}>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="icon-bg">
                <BorderOutlined style={{ color: "#3dd6d6", fontSize: 20 }} />
              </div>
            </Col>
            <Col>
              <div>
                <h1>{data.numberOfProjects}</h1>
                <p className="text-muted">Iniciativas / Proyectos productivos</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default Home;
