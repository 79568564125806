import { Col, Form, Row, Input } from "antd";

const { TextArea } = Input;

function Technification({ handleChange, record }: any) {
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <Form.Item label="Describa qué tipo de procesos de tecnificación implementa en su producción">
            <TextArea
              name="technification"
              value={record.technification}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Technification;
