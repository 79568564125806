import { paxios, naxios } from "./http";
import { URL_SERVER } from "./config";

export const getForms = async () => {
  try {
    const u = await paxios.get(`${URL_SERVER}/associative_forms/getForms`);
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const updateForm = async (data: any) => {
  try {
    const u = await paxios.put(`${URL_SERVER}/associative_forms/updateForm`, {
      ...data,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getAssociatesByForm = async (id: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/associates/getAssociatesByForm/${id}`
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const createAssociate = async (data: any) => {
  try {
    const u = await paxios.post(`${URL_SERVER}/associates/createAssociate`, {
      ...data,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const updateAssociate = async (data: any) => {
  try {
    const u = await paxios.put(`${URL_SERVER}/associates/updateAssociate`, {
      ...data,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getDiagnosticQuestions = async () => {
  try {
    const u = await paxios.get(`${URL_SERVER}/diagnostic/getQuestions`);
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getDiagnostics = async (id: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/diagnostic/getDiagnosticByAssociativeForm/${id}`
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const createDiagnostic = async (data: any) => {
  try {
    const u = await paxios.post(`${URL_SERVER}/diagnostic/createDiagnostic`, {
      ...data,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const updateDiagnostic = async (data: any) => {
  try {
    const u = await paxios.put(`${URL_SERVER}/diagnostic/updateDiagnostic`, {
      ...data,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getDiagnosticsAnswers = async (id: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/diagnostic/getAnswersByDiagnostic/${id}`
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const createDiagnosticAnswers = async (data: any) => {
  try {
    const u = await paxios.post(
      `${URL_SERVER}/diagnostic/createAnswersDiagnostic`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const updateDiagnosticAnswers = async (data: any) => {
  try {
    const u = await paxios.put(
      `${URL_SERVER}/diagnostic/updateAnswersDiagnostic`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getDV = async (nit: any) => {
  try {
    const u = await naxios.get(`${URL_SERVER}/home_public/getDv/${nit}`);
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const validateRecaptch = async (token: any) => {
  try {
    const u = await naxios.post(`${URL_SERVER}/home_public/validateReCaptcha`, {
      token,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const requestResetPassword = async (data: any) => {
  try {
    const u = await paxios.post(`${URL_SERVER}/forgot-password`, {
      ...data,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getManufactureProductsByAssociativeForm = async (id: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/economic_activity/getManufactureProductsByAssociativeForm/${id}`
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const validateEmail = async (token: any, id: any) => {
  try {
    const u = await paxios.post(`${URL_SERVER}/confirm-email`, {
      token,
      id,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const resetPassword = async (data: any) => {
  try {
    const u = await paxios.post(`${URL_SERVER}/reset-password`, {
      ...data,
    });
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const createManufactureProduct = async (data: any) => {
  try {
    const u = await paxios.post(
      `${URL_SERVER}/economic_activity/createManufactureProduct`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const updateManufactureProduct = async (data: any) => {
  try {
    const u = await paxios.put(
      `${URL_SERVER}/economic_activity/updateManufactureProduct`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getFarmingProductsByAssociativeForm = async (id: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/economic_activity/getFarmingProductsByAssociativeForm/${id}`
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const createFarmingProduct = async (data: any) => {
  try {
    const u = await paxios.post(
      `${URL_SERVER}/economic_activity/createFarmingProduct`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const updateFarmingProduct = async (data: any) => {
  try {
    const u = await paxios.put(
      `${URL_SERVER}/economic_activity/updateFarmingProduct`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getLivestockProductsByAssociativeForm = async (id: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/economic_activity/getLivestockProductsByAssociativeForm/${id}`
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const createLivestockProduct = async (data: any) => {
  try {
    const u = await paxios.post(
      `${URL_SERVER}/economic_activity/createLivestockProduct`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const updateLivestockProduct = async (data: any) => {
  try {
    const u = await paxios.put(
      `${URL_SERVER}/economic_activity/updateLivestockProduct`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getServiceProductsByAssociativeForm = async (id: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/economic_activity/getServiceProductsByAssociativeForm/${id}`
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const createServiceProduct = async (data: any) => {
  try {
    const u = await paxios.post(
      `${URL_SERVER}/economic_activity/createServiceProduct`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const updateServiceProduct = async (data: any) => {
  try {
    const u = await paxios.put(
      `${URL_SERVER}/economic_activity/updateServiceProduct`,
      {
        ...data,
      }
    );
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};

export const getUsers = async () => {
  try {
    const u = await paxios.get(`${URL_SERVER}/admin/getUsers`);
    return u.data;
  } catch (error) {
    return {
      status: "error",
      errores: "",
    };
  }
};
