import { useState, useEffect } from "react";
import "./style.scss";
import { Card, Tabs, List } from "antd";
import { Link } from "react-router-dom";
import { TeamOutlined, BorderOutlined } from "@ant-design/icons";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

interface IProps {
  onChangeTab: (tab: number) => void;
  onChangeDepartment: (department: string) => void;
  onChangeMunicipality: (municipality: string) => void;
  origin?: string;
}

function Index({
  onChangeDepartment,
  onChangeMunicipality,
  onChangeTab,
  origin = "map",
}: IProps) {
  const { departments, municipalies, getDepartments, getMunicipalies } =
    severalStore();
  const [valueTab, setValueTab] = useState(0);
  const [visibleMunicipaly, setVisibleMunicipaly] = useState<any>([]);
  const [departmentSelected, setDepartmentSelected] = useState("0");
  const [municipalySelected, setMunicipalySelected] = useState("0");
  useEffect(() => {
    departments.length <= 0 && getDepartments();
    municipalies.length <= 0 && getMunicipalies();
  }, [
    departments.length,
    getDepartments,
    getMunicipalies,
    municipalies.length,
  ]);

  useEffect(() => {
    if (departmentSelected !== "0") {
      setVisibleMunicipaly(
        municipalies.filter((x: any) => String(x.department_id) === departmentSelected)
      );
    } else {
      setVisibleMunicipaly([]);
    }
    setMunicipalySelected("0");
  }, [departmentSelected, municipalies]);

  const handleChangeTab = (newValue: string) => {
    setDepartmentSelected("0");
    setValueTab(parseInt(newValue));
    onChangeTab(parseInt(newValue));
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "department") {
      setDepartmentSelected(value);
      onChangeDepartment(value);
    }
    if (name === "municipaly") {
      setMunicipalySelected(value);
      onChangeMunicipality(value);
    }
  };

  const RenderDepaMuni = () => {
    return (
      <>
        <Select
          size="large"
          name="department"
          showSearch={true}
          value={departmentSelected}
          placeholder="Departamento"
          onChange={handleChange}
          options={departments.map((x: any) => ({
            value: String(x.code),
            label: x.name,
          }))}
          style={{ marginBottom: 25 }}
        />

        <Select
          size="large"
          name="municipaly"
          showSearch={true}
          value={municipalySelected}
          placeholder="Municipio"
          onChange={handleChange}
          options={visibleMunicipaly.map((x: any) => ({
            value: String(x.code),
            label: x.name,
          }))}
          style={{ marginBottom: 25 }}
        />
      </>
    );
  };

  return (
    <Card className="card-container">
      <Tabs
      className="dieguito"
        defaultActiveKey={"1"}
        items={[
          {
            key: "0",
            label: (
              <div className="filter-map-tab-text">
                FORMAS <br /> ASOCIATIVAS
              </div>
            ),
            children: (
              <div>
                <RenderDepaMuni />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {origin === "map" && (
                    <Link
                      color="inherit"
                      to="/associative-form-project-list"
                      style={{ color: "#000" }}
                    >
                      VER EN LISTA
                    </Link>
                  )}
                  {origin === "list" && (
                    <Link color="inherit" to="/mapa" style={{ color: "#000" }}>
                      VER EN MAPA
                    </Link>
                  )}
                </div>
              </div>
            ),
            icon: <TeamOutlined className="filter-map-tab-icon" />,
          },
          {
            key: "1",
            label: (
              <div className="filter-map-tab-text">
                INICIATIVAS Y <br /> PROYECTOS
              </div>
            ),
            children: (
              <div>
                <RenderDepaMuni />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {origin === "map" && (
                    <Link
                      color="inherit"
                      to="/associative-form-project-list"
                      style={{ color: "#000" }}
                    >
                      VER EN LISTA
                    </Link>
                  )}
                  {origin === "list" && (
                    <Link color="inherit" to="/mapa" style={{ color: "#000" }}>
                      VER EN MAPA
                    </Link>
                  )}
                </div>

                <div className="m-2 text-center">
                  <hr />
                  <h2 className="simbologia-text">Simbología</h2>
                  <List
                    itemLayout="horizontal"
                    dataSource={[
                      {
                        title: "Iniciativas productivas",
                        icon: (
                          <span className="box-simbologia color-morado-simbologia"></span>
                        ),
                      },
                      {
                        title: "Proyectos productivos",
                        icon: (
                          <span className="box-simbologia color-verde-simbologia"></span>
                        ),
                      },
                    ]}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={item.icon}
                          title={item.title}
                          description=""
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            ),
            icon: <BorderOutlined className="filter-map-tab-icon" />,
          },
        ]}
        size="large"
        activeKey={String(valueTab)}
        onChange={handleChangeTab}
        centered
      />
    </Card>
  );
}

export default Index;
