import { useState } from "react";
import { Form, Row, Modal, Col, Input, Alert } from "antd";
import uuid from "react-uuid";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.name === "") errors.name = "Ingrese Nombre";
  return errors;
};

function CreateEditInputs({
  open,
  handleClose,
  data,
  dataAll,
  handleChange,
}: any) {
  const [record, setRecord] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  const handleChangeLocal = (e: any) => {
    const { name, value } = e.target;
    setRecord((prev: any) => ({ ...prev, [name]: value }));
  };

  const create = (data: any) => {
    const a = [...dataAll, { ...data, new: false }];
    handleChange({ target: { name: "products_inputs", value: a } });
    handleClose(false);
  };

  const edit = (data: any) => {
    const a = dataAll.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    handleChange({ target: { name: "products_inputs", value: a } });
    handleClose(false);
  };

  const handleClickSave = () => {
    const errors = validateData(record);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (record.new) {
      const uid = uuid();
      const newPresentation = {
        ...record,
      };
      create({ ...newPresentation, id: uid, key: uid });
      return;
    }

    edit({
      ...record,
      new: false,
    });
    setErrors({});
  };

  return (
    <Modal
      title={`${record.new ? "Agregar" : "Editar"} insumo`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${record.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"800px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Nombre">
              <Input
                name="name"
                value={record.name}
                onChange={handleChangeLocal}
              />
              {errors?.name && (
                <Alert message={errors.name} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEditInputs;
