import { useState } from "react";
import { Button, Col, Flex, Form, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "../../../../components";
import { userStore } from "../../../../stores";
import { applicationModules } from "../../../../constants";
import { enableButtonSave, messageSweetalert } from "../../../../utilities";
import CreateEditInputs from "./CreateEditInputs";

const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
  },
];

function Inputs({ handleChange, record }: any) {
  const { permissions } = userStore();
  const [selected, setSelected] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onChangeSelection = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelected(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          {enableButtonSave(applicationModules, permissions) && (
            <Flex style={{ marginBottom: 10 }}>
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                disabled={selected.length > 0}
                onClick={() => openCreate(selected)}
              >
                Agregar insumo
              </Button>
              <Button
                type="primary"
                size="large"
                icon={<EditOutlined />}
                disabled={selected.length <= 0}
                onClick={() => openEdit(selected)}
              >
                Editar insumo
              </Button>
            </Flex>
          )}

          <Table
            dataSource={
              record.products_inputs.map((x: any) => ({ ...x, key: x.id })) ||
              []
            }
            columns={columns}
            bordered
            scroll={{ x: "1000" }}
            onChangeSelect={onChangeSelection}
          />
        </Col>

        {modalOpen && (
          <CreateEditInputs
            open={modalOpen}
            handleClose={setModalOpen}
            dataAll={record.products_inputs}
            data={
              selected.length > 0
                ? { ...selected[0], new: false }
                : {
                    id: 0,
                    name: "",
                    product_type_id: 3,
                    product_id: record.id,
                    new: true,
                  }
            }
            handleChange={handleChange}
          />
        )}
      </Row>
    </Form>
  );
}

export default Inputs;
