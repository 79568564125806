import { useState, useEffect, useCallback } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { paxios } from "../../../../http";
import { URL_SERVER } from "../../../../config";
import { severalStore } from "../../../../stores";
import { Breadcrumb, Button, Col, Pagination, Row } from "antd";
import { CloudDownloadOutlined, CaretRightOutlined } from "@ant-design/icons";
import { dowloadExcel } from "../../../../utilities";
import { Loading } from "../../../../components";

const perPage = 5;

const calculateFromTo = (
  page: number,
  perPage: number,
  quantityRecord: number
) => {
  let newTo = page * perPage - 1;
  const newFrom = newTo - perPage + 1;

  if (newTo > quantityRecord) {
    newTo = quantityRecord - 1;
  }

  if (page === 1) {
    return {
      from: 0,
      to: perPage - 1,
    };
  }

  return {
    from: newFrom,
    to: newTo,
  };
};

function Home({ filterDepa = "0", filterMuni = "0" }: any) {
  const {
    departments,
    municipalies,
    setFaSelectdMap,
    setShowLoading,
  }: {
    departments: any;
    municipalies: any;
    setFaSelectdMap: any;
    showLoading: boolean;
    setShowLoading: any;
  } = severalStore();
  const navigate = useNavigate();
  const [dataFa, setDataFa] = useState<any>([]);
  const [dataFaVisible, setDataFaVisible] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fromTo, setFromTo] = useState({
    from: 0,
    to: perPage - 1,
  });

  const getAssociativeFormsMap = async () => {
    try {
      setShowLoading(true);
      const u = await paxios.get(
        `${URL_SERVER}/home_public/associative_forms_map`
      );
      const data = u.data.features.map((x: any) => ({ ...x.properties }));
      setDataFa(data);
      setDataFaVisible(data);
      setShowLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getAssociativeFormsMap();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataFaVisible]);

  const filterInfoDepartment = (info: any, departmentId: any) => {
    const newInfo = info.filter(
      (x: any) => String(x.department_id) === departmentId
    );
    setDataFaVisible(newInfo);
  };

  const filterInfoMunicipality = (info: any, municipalityId: any) => {
    const newInfo = info.filter(
      (x: any) => String(x.municipality_id) === municipalityId
    );
    setDataFaVisible(newInfo);
  };

  const onChangeDepartment = useCallback(
    (department: string) => {
      if (String(department) === "0") {
        setDataFaVisible(dataFa);
        return;
      }
      filterInfoDepartment(dataFa, department);
    },
    [dataFa]
  );

  const onChangeMunicipality = useCallback(
    (municipality: string) => {
      if (String(municipality) === "0") {
        setDataFaVisible(dataFaVisible);
        return;
      }
      filterInfoMunicipality(dataFaVisible, municipality);
    },
    // eslint-disable-next-line
    [filterMuni]
  );

  useEffect(() => {
    onChangeDepartment(filterDepa);
  }, [filterDepa, onChangeDepartment]);

  useEffect(() => {
    onChangeMunicipality(filterMuni);
  }, [filterMuni, onChangeMunicipality]);

  const handleClickViewForm = (form: any) => {
    setFaSelectdMap(form);
    navigate("/associative-form-view");
  };

  const organizeExcelData = (data: any) => {
    const newData = data.map((x: any) => {
      return {
        Id: x.id,
        Nombre: x.name,
        Dirección: x.address,
        "# Asociados Hombres": x.men,
        "# Asociados Mujeres": x.women,
        "# Proyectos Asociados": x.publicProjects.length,
        "Fecha creación": x.created_date,
        Departamento: x.department ? x.department.name : "",
        Municipio: x.municipality ? x.municipality.name : "",
        Descripción: x.description,
        Correo: x.email,
        Telefono: x.phonenumber,
        Tipo: x.association_types ? x.association_types.name : "",
        "Sitio web": x.website,
        Nit: x.nit,
        " ": x.initials,
      };
    });

    return newData;
  };

  const handleClickDowloadExcel = () => {
    const data = organizeExcelData(dataFaVisible);
    dowloadExcel(data, "formas_asociativas");
  };

  const handleChangePagination = (page: any) => {
    setCurrentPage(page);
    const res = calculateFromTo(page, perPage, dataFaVisible.length);
    setFromTo(res);
  };

  return (
    <Row>
      <Col md={24} lg={24}>
        <Breadcrumb
          items={[
            {
              title: (
                <Link
                  color="inherit"
                  to="/"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Inicio
                </Link>
              ),
            },

            {
              title: "Formas Asociativas",
            },
          ]}
        />

        <div className="mt-4">
          <h1 className="associative-forms-list-title">Formas asociativas</h1>
        </div>

        <Row>
          <Col md={18} lg={18}>
            {filterDepa !== "0" && (
              <div className="col filters">
                <div className="pb-2 text-muted" style={{ marginBottom: 10 }}>
                  Tus filtros
                </div>
                <div>
                  {filterDepa !== "0" && (
                    <span className="filter-boxes">
                      {
                        departments.find(
                          (x: any) => String(x.code) === filterDepa
                        )?.name
                      }
                    </span>
                  )}
                  {filterMuni !== "0" && (
                    <span className="filter-boxes">
                      {
                        municipalies.find(
                          (x: any) => String(x.code) === filterMuni
                        )?.name
                      }
                    </span>
                  )}
                </div>
              </div>
            )}
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<CloudDownloadOutlined />}
              onClick={handleClickDowloadExcel}
            >
              Exportar excel
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={24}>
            <section className="pb-2">
              <p>
                Se encontraron{" "}
                <span className="fw-bold">{dataFaVisible.length}</span>
                &nbsp;formas asociativas comprometidas a construir un mejor
                país.
              </p>
            </section>
          </Col>
        </Row>
        <Row>
          <Col
            md={24}
            lg={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Pagination
              defaultCurrent={1}
              total={dataFaVisible.length * 2}
              current={currentPage}
              onChange={handleChangePagination}
              showSizeChanger={false}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col md={24} lg={24}>
            {dataFaVisible
              .filter(
                (y: any, index: any) =>
                  index >= fromTo.from && index <= fromTo.to
              )
              .map((x: any) => (
                <div
                  key={x.id}
                  className="form-associative-view-associative-form-list mb-3"
                >
                  <div className="form-associative-view-associative-form-list-item">
                    <div className="top">
                      <Row>
                        <Col md={17} lg={17} xs={12}>
                          <div className="form-associative-view-associative-form-pInfo">
                            <Row className="d-flex align-items-center">
                              <Col
                                md={5}
                                lg={5}
                                className="col-sm-auto d-flex justify-content-sm-center"
                              >
                                <img
                                  className="img-fluid"
                                  width="85"
                                  height="90"
                                  src={`${URL_SERVER}/home_public/getLogoAssociativeForms/${x.nit}`}
                                  alt="Logo de la forma asociativa"
                                />
                              </Col>
                              <Col md={19} lg={19} className="mt-2 mt-sm-0">
                                <h4>
                                  {x.name}
                                  <strong> ({x?.initials}) </strong>
                                  <span>
                                    {x.association_types
                                      ? x.association_types.name
                                      : ""}
                                  </span>
                                </h4>

                                <div>
                                  <p>{x?.description}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col
                          md={7}
                          lg={7}
                          xs={12}
                          style={{
                            backgroundColor: "rgba(0, 0, 0, 0.06)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="no-projects">
                            <div>
                              <span className="tag-number">
                                {x.publicProjects.length}
                              </span>
                              <span>Iniciativas y proyectos</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="bottom">
                      <Row className="align-items-center">
                        <Col
                          md={5}
                          lg={5}
                          className="v-line text-muted text-center"
                        >
                          <div className="fw-bold">{x.women} mujeres</div>
                          <div className="small">Asociadas</div>
                        </Col>
                        <Col
                          md={5}
                          lg={5}
                          className="v-line text-muted text-center"
                        >
                          <div className="fw-bold">{x.men} hombres</div>
                          <div className="small">Asociados</div>
                        </Col>
                        <Col
                          md={12}
                          lg={12}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="btn-view"
                            onClick={() => handleClickViewForm(x)}
                            style={{ cursor: "pointer" }}
                          >
                            Ver forma asociativa <CaretRightOutlined />
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              ))}
          </Col>
        </Row>
      </Col>
      <Loading />
    </Row>
  );
}

export default Home;
