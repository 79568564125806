import "./style.scss";
import { Col, Row } from "antd";
import Header from "../header/Header";
import Menu from "../menu/Menu";

function Page({ children }: any) {
  return (
    <div>
      <Header />
      <Row>
        <Col
          xs={0}
          md={5}
          xl={4}
          xxl={3}
          /* flex="200px" */ className="col-main-menu"
        >
          <Menu style={{ paddingTop: 20 }} />
        </Col>
        <Col
          className="col-main-content"
          xs={24}
          md={19}
          xl={20}
          xxl={21} /* flex="auto" */
        >
          {children}
        </Col>
      </Row>
    </div>
  );
}

export default Page;
