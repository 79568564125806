import { Accordion, CircularProgressWithLabel } from "../../../../components";
import { severalStore } from "../../../../stores";
import FinancialAnalysis from "./FinancialAnalysis";

function Financial({
  handleClickSave,
  errors,
  data,
  setValues,
  visible = true,
}: any) {
  const { subCategoryQuestionDiagnostic }: any = severalStore();
  return (
    <Accordion
      style={visible ? {} : { display: "none" }}
      size={"large"}
      defaultActiveKey={["13"]}
      items={[
        {
          key: "13",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 13)
            ?.name,
          children: <FinancialAnalysis />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedFinancial.completedFinancialAnalysis}
            />
          ),
        },
      ]}
    />
  );
}

export default Financial;
