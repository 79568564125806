import React, { useState } from "react";
import { Loading, Page, TabsTitle } from "../../../components";
import { Col, Row } from "antd";
import User from "./Users";
//import AuxiliaryTables from "./AuxiliaryTables";

const tabs = [
  {
    value: 0,
    label: (
      <div className="ant-segmented-item-label" title="Usuarios">
        USUARIOS
      </div>
    ),
  },
  /* {
    value: 1,
    label: (
      <div className="ant-segmented-item-label" title="Tablas Auxiliares">
        TABLAS AUXILIARES
      </div>
    ),
  }, */
];

export default function Home() {
  const [value, setValue] = useState<number>(0);

  return (
    <Page>
      <Row>
        <Col flex={"auto"}>
          <TabsTitle options={tabs} value={value} setValue={setValue} />
          {value === 0 && <User />}
          {/* {value === 1 && <AuxiliaryTables />} */}
        </Col>
      </Row>
      <Loading/>
    </Page>
  );
}
