import { useState } from "react";
import { Form, Row, Modal, Col, Alert, Input } from "antd";
import uuid from "react-uuid";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.initial_date === "") errors.initial_date = "Ingrese Fecha inicial";
  if (data.end_date === "") errors.end_date = "Ingrese Fecha final";
  return errors;
};

function CreateEditSalesPrices({
  open,
  handleClose,
  data,
  dataAll,
  handleChange,
  typeTable,
}: any) {
  const { unitsMeasurementSalePriceLivestockProduct } = severalStore();
  const [record, setRecord] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  const handleChangeLocal = (e: any) => {
    const { name, value } = e.target;
    setRecord((prev: any) => ({ ...prev, [name]: value }));
  };

  const create = (data: any) => {
    const a = [...dataAll, { ...data, new: false }];
    handleChange({ target: { name: typeTable, value: a } });
    handleClose(false);
  };

  const edit = (data: any) => {
    const a = dataAll.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    handleChange({ target: { name: typeTable, value: a } });
    handleClose(false);
  };

  const handleClickSave = () => {
    const errors = validateData(record);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (record.new) {
      const uid = uuid();
      const newPresentation = {
        ...record,
        unit_measurement: unitsMeasurementSalePriceLivestockProduct.find(
          (x: any) => String(x.id) === String(record.unit_id)
        ),
      };
      create({ ...newPresentation, id: uid, key: uid });
      return;
    }

    edit({
      ...record,
      new: false,
    });
    setErrors({});
  };

  return (
    <Modal
      title={`${record.new ? "Agregar" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${record.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"800px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label={"Precio"}>
              <Input
                placeholder=""
                name="price"
                onChange={handleChangeLocal}
                value={record.price}
                type="number"
              />
            </Form.Item>
            {errors?.price && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.price}
                type="error"
                showIcon
              />
            )}
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Cantidad">
              <Input
                placeholder=""
                name="quantity"
                onChange={handleChangeLocal}
                value={record.quantity}
                type="number"
              />
            </Form.Item>
            {errors?.quantity && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.quantity}
                type="error"
                showIcon
              />
            )}
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Unidad">
              <Select
                name="unit_id"
                value={record.unit_id}
                onChange={handleChangeLocal}
                options={unitsMeasurementSalePriceLivestockProduct.map(
                  (x: any) => ({
                    value: x.id,
                    label: x.name,
                  })
                )}
                placeholder="Seleccionar"
                option0={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEditSalesPrices;
