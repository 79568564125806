import axios from "axios";

let normalAxios = axios.create();
normalAxios.defaults.headers.common["cache-control"] = "no-cache";
normalAxios.defaults.headers.post["Content-Type"] = "no-cache";
normalAxios.defaults.headers.put["Content-Type"] = "no-cache";
normalAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 400) {
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

let privateAxios = axios.create();
privateAxios.defaults.headers.common["cache-control"] = "no-cache";
privateAxios.defaults.headers.post["Content-Type"] = "no-cache";
privateAxios.defaults.headers.put["Content-Type"] = "no-cache";
privateAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    let status = null;
    try {
      status = error.response.status;
    } catch (error) {}

    if (status === 401) {
      return Promise.reject(error.response);
    } else if (status === 400) {
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

export const setJWT = (jwt: any) => {
  privateAxios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export const naxios = normalAxios;
export const paxios = privateAxios;

export async function downloadFile(
  url: any,
  filename: any,
  data: any,
  setConsultingServer: any = null
) {
  try {
    const response = await paxios({
      url: url,
      method: "POST",
      responseType: "blob",
      data: data,
    });

    const _url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = _url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setConsultingServer !== null && setConsultingServer(false);

    return true;
  } catch (error) {
    setConsultingServer !== null && setConsultingServer(false);
    return false;
  }
}

export const getImageBase64 = async (url: string, data: any) => {
  try {
    const result = await paxios.post(url, data);
    return `${result.data}`;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export async function uploadFile(data: any) {
  const { url, files, setProgess, additionalData } = data;
  const formData = new FormData();
  for (const file of files) {
    formData.append(file.name, file.file);
  }

  for (let i = 0; i < additionalData.length; i++) {
    formData.append(additionalData[i].name, additionalData[i].value);
  }

  const res = await paxios.post(url, formData, {
    onUploadProgress: (ProgressEvent) => {
      let progress: any = 0;
      if (ProgressEvent.total)
        progress =
          Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) + "%";
      setProgess !== null && setProgess(progress);
    },
  });

  return res.data;
}
