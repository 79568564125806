import { Flex, Progress } from "antd";

function CircularProgressWithLabel({ value, size = 100 }: any) {
  return (
    <Flex align="center" wrap style={{ justifyContent: "center" }}>
      <Progress
        type="circle"
        size={size}
        percent={value}
        format={() => `${Math.round(value)}%`}
        strokeColor="#3dd6d6"
      />
    </Flex>
  );
}

export default CircularProgressWithLabel;
