import { Col, Form, Input, Row, Alert, Divider } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import SowingepochHarvestperiodTable from "./SowingepochHarvestperiodTable";

function CharacterizationCrop({ handleChange, record, errors }: any) {
  const { productionType, unitMeasurementPresentation }: any = severalStore();
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"Nombre"}>
            <Input
              placeholder=""
              name="name"
              onChange={handleChange}
              value={record.name}
            />
          </Form.Item>
          {errors?.name && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.name}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"Descripción"}>
            <Input
              placeholder=""
              name="description"
              onChange={handleChange}
              value={record.description}
            />
          </Form.Item>
          {errors?.description && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.description}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Qué tipo de producción implementa?">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              option0={false}
              options={productionType.map((x: any) => {
                return {
                  value: String(x.id),
                  label: x.name,
                };
              })}
              name="production_type_id"
              value={record.production_type_id}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <SowingepochHarvestperiodTable
            handleChange={handleChange}
            record={record}
            titleTable={"Época de siembra"}
            typeTable={"sowing_epoch"}
          />
        </Col>
      </Row>
      <Divider />
      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <SowingepochHarvestperiodTable
            handleChange={handleChange}
            record={record}
            titleTable={"Periodo de cosecha"}
            typeTable={"harvest_period"}
          />
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={"¿Cuál es la cantidad producida por ciclo del cultivo?"}
          >
            <Input
              placeholder=""
              name="quantity_produced_crop_cycle"
              onChange={handleChange}
              value={record.quantity_produced_crop_cycle}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Cuál es la unidad de medida para la cantidad producida por ciclo del cultivo?">
            <Select
              name="unit_measurement_crop_cycle_id"
              value={record.unit_measurement_crop_cycle_id}
              onChange={handleChange}
              options={unitMeasurementPresentation.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
              option0={true}
              placeholder="Seleccionar"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default CharacterizationCrop;
