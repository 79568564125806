import { Accordion, CircularProgressWithLabel } from "../../../../components";
import { severalStore } from "../../../../stores";
import OrganizationalStructure from "./OrganizationalStructure";
import FunctioningOrganizationalStructure from "./FunctioningOrganizationalStructure";
import Participation from "./Participation";
import GenderEquity from "./GenderEquity";
import ConflictResolution from "./ConflictResolution";
import RolesAndDecisions from "./RolesAndDecisions";

function Organizational({
  handleClickSave,
  errors,
  data,
  setValues,
  visible = true,
}: any) {
  const { subCategoryQuestionDiagnostic }: any = severalStore();
  return (
    <Accordion
      style={visible ? {} : { display: "none" }}
      size={"large"}
      items={[
        {
          key: "1",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 1)
            ?.name,
          children: <OrganizationalStructure />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={
                data.completedOrganizational.completedOrganizationalStructure
              }
            />
          ),
        },
        {
          key: "2",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 2)
            ?.name,
          children: <FunctioningOrganizationalStructure />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={
                data.completedOrganizational
                  .completedFunctioningOrganizationalStructure
              }
            />
          ),
        },
        {
          key: "3",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 3)
            ?.name,
          children: (
            <Participation
              handleClickSave={handleClickSave}
              errors={errors}
              data={data}
              setValues={setValues}
            />
          ),
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedOrganizational.completedParticipation}
            />
          ),
        },
        {
          key: "4",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 4)
            ?.name,
          children: (
            <GenderEquity
              handleClickSave={handleClickSave}
              errors={errors}
              data={data}
              setValues={setValues}
            />
          ),
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedOrganizational.completedGenderEquity}
            />
          ),
        },
        {
          key: "5",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 5)
            ?.name,
          children: <ConflictResolution />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedOrganizational.completedConflictResolution}
            />
          ),
        },
        {
          key: "6",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 6)
            ?.name,
          children: <RolesAndDecisions />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedOrganizational.completedRolesAndDecisions}
            />
          ),
        },
      ]}
    />
  );
}

export default Organizational;
