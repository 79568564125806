import { Breadcrumb, Button, Col, Row } from "antd";
import { PhoneOutlined, MailOutlined, LaptopOutlined } from "@ant-design/icons";
import { severalStore } from "../../../../stores";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Header from "../../header/Header";
import "./style.scss";
import { URL_SERVER } from "../../../../config";
import { CircularProgressWithLabel } from "../../../../components";

function Home() {
  const navigate = useNavigate();
  const {
    faSelectdMap,
    setIpSelectedMap,
  }: { faSelectdMap: any; setIpSelectedMap: any } = severalStore();
  const projectConverJson = (projects: any) => {
    if (typeof projects === "string") {
      return JSON.parse(projects);
    }
    return projects;
  };

  const handleClickViewProject = (form: any) => {
    setIpSelectedMap({ ...form, associative_form_id: faSelectdMap.id });
    navigate("/project-iniciative-view");
  };

  if (!faSelectdMap) {
    return <Navigate to={"/"} />;
  }
  return (
    <>
      <Header />
      <Row>
        <Col md={1} lg={1}></Col>
        <Col md={20} lg={20}>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link
                    color="inherit"
                    to="/"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    Inicio
                  </Link>
                ),
              },
              {
                title: (
                  <Link
                    color="inherit"
                    to="/associative-form-project-list"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    Formas Asociativas
                  </Link>
                ),
              },
              {
                title: faSelectdMap ? faSelectdMap.name : "",
              },
            ]}
          />
          <div className="mb-3" style={{ marginBottom: "1rem" }}>
            <h1 className="associative-forms-list-title fw-bold p-2">
              {faSelectdMap?.name}{" "}
              {faSelectdMap?.initials && `(${faSelectdMap?.initials})`}
            </h1>
            <div className="subtitle-list">
              {faSelectdMap?.type && faSelectdMap?.type.name}
            </div>
          </div>
          <Row style={{ justifyContent: "space-between" }}>
            <Col xs={24} md={18} lg={18}>
              <div className="form-associative-view-statistics-info">
                <Row style={{ justifyContent: "space-between" }}>
                  <Col xs={24} md={11} lg={11}>
                    <div className="form-associative-view-statistics-info-box">
                      <Row className="text-center-row">
                        <Col xs={24} md={12} lg={12} className="col">
                          <div className="form-associative-view-statistics-info-box-item">
                            <div>
                              <CircularProgressWithLabel
                                value={
                                  (faSelectdMap.women * 100) /
                                  (faSelectdMap.women + faSelectdMap.men)
                                }
                              />
                            </div>
                            <div>{faSelectdMap.women} Mujeres</div>
                          </div>
                        </Col>
                        <Col xs={24} md={12} lg={12} className="col">
                          <div className="form-associative-view-statistics-info-box-item">
                            <div>
                              <CircularProgressWithLabel
                                value={
                                  (faSelectdMap.men * 100) /
                                  (faSelectdMap.women + faSelectdMap.men)
                                }
                              />
                            </div>
                            <div>{faSelectdMap.men} Hombres</div>
                          </div>
                        </Col>
                        <div className="col-line-left d-flex align-items-center justify-content-center text-center">
                          <div className="form-associative-view-statistics-info-box-item">
                            <div className="item-box-number mb-2">
                              {faSelectdMap.women + faSelectdMap.men}
                            </div>
                            <div>Asociadas y asociados</div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <div className="form-associative-view-statistics-info-box">
                      <div className="form-associative-view-statistics-info-box-item">
                        <div className="item-box-number">
                          {typeof faSelectdMap?.publicProjects === "string"
                            ? JSON.parse(faSelectdMap?.publicProjects).length
                            : faSelectdMap?.publicProjects.length}
                        </div>
                        <div>Iniciativas / Proyectos productivos</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} md={5} lg={5}>
              <div style={{ marginBottom: "1rem" }}>
                <img
                  className="img-fluid"
                  width="75"
                  height="75"
                  src={`${URL_SERVER}/home_public/getLogoAssociativeForms/${faSelectdMap.nit}`}
                  alt={"logo"}
                />
              </div>
              <h3 className="fw-bold">Información de contacto</h3>
              <div>{faSelectdMap?.address}</div>
              <div>
                {faSelectdMap?.department &&
                  (typeof faSelectdMap?.department === "string"
                    ? JSON.parse(faSelectdMap?.department).name
                    : faSelectdMap?.department.name)}
                {", "}
                {faSelectdMap?.municipality &&
                  (typeof faSelectdMap?.municipality === "string"
                    ? JSON.parse(faSelectdMap?.municipality).name
                    : faSelectdMap?.municipality.name)}
              </div>
              <div className="mt-3">
                <div className="form-associative-view-contact-information">
                  <PhoneOutlined
                    className="mat-icon"
                    style={{ fontSize: "1.5rem" }}
                  />
                  <div>{faSelectdMap?.phoneNumber}</div>
                </div>
                <div className="form-associative-view-contact-information">
                  <MailOutlined
                    className="mat-icon"
                    style={{ fontSize: "1.5rem" }}
                  />
                  <div>{faSelectdMap?.email}</div>
                </div>
                <div className="form-associative-view-contact-information">
                  <LaptopOutlined
                    className="mat-icon"
                    style={{ fontSize: "1.5rem" }}
                  />
                  <div>{faSelectdMap?.website}</div>
                </div>
              </div>
            </Col>
          </Row>

          <section>
            <hr className="statistics-h-line" />
          </section>

          <section>
            <h1 className="mat-h1 fw-bold mb-2">
              Iniciativas / Proyectos productivos
            </h1>
          </section>

          {projectConverJson(faSelectdMap?.publicProjects).length <= 0 && (
            <h2>Aún no hay iniciativas</h2>
          )}

          <section>
            {projectConverJson(faSelectdMap?.publicProjects).map((x: any) => (
              <div
                key={x.id}
                className="form-associative-view-associative-form-list mb-3"
              >
                <div className="form-associative-view-associative-form-list-item">
                  <div className="top">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-associative-view-associative-form-pInfo">
                          <div>
                            <h4 className="mat-h4">
                              {x.name}
                              <div className="d-flex fw-normal">
                                {x.type.name === "PGN 8 millones" && (
                                  <div className="subtitle-list">
                                    Proyecto productivo
                                  </div>
                                )}
                                {x.type.name !== "PGN 8 millones" && (
                                  <div className="subtitle-list">
                                    Iniciativa productiva
                                  </div>
                                )}
                                <div>&nbsp;·&nbsp;{x.status.name}</div>
                              </div>
                            </h4>
                          </div>
                          <div>
                            <p className="mb-2">{x.description}</p>
                          </div>
                          <Button
                            type="text"
                            onClick={() => handleClickViewProject(x)}
                          >
                            Ver detalle
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>
        </Col>
      </Row>
    </>
  );
}

export default Home;
