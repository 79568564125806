import { Col, Form, Input, Row } from "antd";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

function Labour({ handleChange, record }: any) {
  const { typeLaborContract }: any = severalStore();
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={"¿Cuántas peronas se requieren para un ciclo del cultivo?"}
          >
            <Input
              placeholder=""
              name="people_required_crop_cycle"
              onChange={handleChange}
              value={record.people_required_crop_cycle}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Tipo de contratación de la mano de obra">
            <Select
              name="type_labor_contract_id"
              value={record.type_labor_contract_id}
              onChange={handleChange}
              options={typeLaborContract.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
              option0={true}
              placeholder="Seleccionar"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Labour;
