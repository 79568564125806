import { Col, Divider, Form, Input, Row } from "antd";
import CostMatriz from "./CostMatriz";
import ExpenditureMatriz from "./ExpenditureMatriz";

function Costing({ handleChange, record }: any) {
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <CostMatriz handleChange={handleChange} record={record} />
        </Col>
      </Row>
      <Divider />
      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <ExpenditureMatriz handleChange={handleChange} record={record} />
        </Col>
      </Row>
      <Divider />
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="¿Cuál fue el costo final por unidad del producto, para el último lote producido?">
              <Input value={record.final_cost_unit} disabled />
            </Form.Item>
          </Col>

          <Col xs={24} md={11} xl={11}>
            <Form.Item label="¿Cuál es el precio  de venta por unidad en pesos del producto?">
              <Input
                name="selling_price_per_unit_product"
                value={record.selling_price_per_unit_product}
                onChange={handleChange}
                type="number"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Utilidad del lote">
              <Input
                value={record.batch_utility}
                disabled
                status={parseFloat(record.batch_utility) < 0 ? "error" : ""}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Costing;
