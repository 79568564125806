import "./style.scss";
import { useEffect } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "antd";
import { LayoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  CircularProgressWithLabel,
  Accordion,
  Loading,
} from "../../../../components";
import { severalStore, userStore } from "../../../../stores";
import { updateForm } from "../../../../servicesHTTP";
import GeneralInformation from "./GeneralInformation";
import LegalRepresentatives from "./legalRepresentatives/Home";
import ContactInformation from "./ContactInformation";
import BankInformation from "./bankInformation/Home";
import {
  enableButtonSave,
  messageSweetalert,
  getNameForm,
} from "../../../../utilities";
import { applicationModules } from "../../../../constants";

function Profile({ getFormsLocal }: any) {
  const [form] = Form.useForm();
  const { permissions } = userStore();
  const {
    setShowLoading,
    update: updateStore,
    formSelected,
  }: any = severalStore();

  /*  useEffect(() => {
    if (forms.length > 0) {
      if (String(formSelected.id) === "0") {
        setFormSelected(forms[0]);
        updateStore({ formSelected: forms[0] });

        setFormsSelected([forms[0].key]);
        preSelectTable(refTableForms, [forms[0].key]);
      }
    }
  }, [forms, updateStore, formSelected.id]); */

  useEffect(() => {
    if (formSelected) {
      let values = {};
      if (formSelected.numberLoad > 1) {
        const v = form.getFieldsValue();
        if (v.nit !== "") {
          values = v;
        }
      }
      form.setFieldsValue({ ...formSelected, ...values });
    }
  }, [form, formSelected]);

  const save = async (data: any) => {
    setShowLoading(true);
    delete data.economic_activity_object;
    delete data.economic_activity_2_object;
    delete data.economic_activity_3_object;
    const result = await updateForm(data);
    if (result.status === "ok") {
      messageSweetalert("Terminado", <p>Datos guardados.</p>);
      getFormsLocal();
    } else {
      messageSweetalert(
        "Terminado",
        <p>No se guardaron los datos.</p>,
        "error"
      );
    }
    setShowLoading(false);
  };

  const onFinish = async (values: any) => {
    //console.log(values, formSelected);
    delete formSelected.numberLoad;
    const data = {
      ...formSelected,
      ...values,
      name: values.name.toUpperCase(),
    };
    save(data);
  };

  const setValues = (name: string, value: any) => {
    //setFormSelected((prev: any) => ({ ...prev, [name]: value }));
    updateStore({ formSelected: { ...formSelected, [name]: value } });
  };

  return (
    <>
      <Row>
        <Col xs={24} md={12} xl={12} xxl={12}>
          <div className="mt-4">
            <h1 className="associative-forms-list-title">
              Perfil de la forma asociativa
            </h1>
            {getNameForm(formSelected) !== "" && (
              <span> ({getNameForm(formSelected)})</span>
            )}
          </div>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link
                    color="inherit"
                    to="/"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    Inicio
                  </Link>
                ),
              },
              {
                title: "Forma Asociativa",
              },
              {
                title: "Perfil",
              },
            ]}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col xs={24} md={4} xl={2} xxl={2}>
          <Card
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgressWithLabel
              value={
                formSelected
                  ? formSelected.percentage_progress_information.percentageTotal
                  : 0
              }
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <LayoutOutlined style={{ color: "#3DD6D6", fontSize: 45 }} />
              <p>Perfil</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} md={1} xl={1} xxl={1}></Col>
        <Col xs={24} md={19} xl={21} xxl={21}>
          <Form
            form={form}
            name="normal_login"
            initialValues={formSelected}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
            autoComplete="off"
            size="large"
          >
            <Accordion
              size={"large"}
              items={[
                {
                  key: "1",
                  label: "Información general",
                  children: (
                    <GeneralInformation
                      formSelected={formSelected}
                      setValues={setValues}
                      formAnt={form}
                    />
                  ),
                  extra: (
                    <CircularProgressWithLabel
                      size={35}
                      value={
                        formSelected
                          ? formSelected.percentage_progress_information
                              .percentageGeneral
                          : 0
                      }
                    />
                  ),
                },
                {
                  key: "2",
                  label: "Representante legal",
                  children: (
                    <LegalRepresentatives
                      form={formSelected}
                      setValue={setValues}
                    />
                  ),
                  extra: (
                    <CircularProgressWithLabel
                      size={35}
                      value={
                        formSelected
                          ? formSelected.percentage_progress_information
                              .percentageLegal
                          : 0
                      }
                    />
                  ),
                },
                {
                  key: "3",
                  label: "Información de contacto",
                  children: (
                    <ContactInformation
                      form={formSelected}
                      setValue={setValues}
                    />
                  ),
                  extra: (
                    <CircularProgressWithLabel
                      size={35}
                      value={
                        formSelected
                          ? formSelected.percentage_progress_information
                              .percentageContact
                          : 0
                      }
                    />
                  ),
                },
                {
                  key: "4",
                  label: "Información bancaria",
                  children: (
                    <BankInformation form={formSelected} setValue={setValues} />
                  ),
                  extra: (
                    <CircularProgressWithLabel
                      size={35}
                      value={
                        formSelected
                          ? formSelected.percentage_progress_information
                              .percentageBank
                          : 0
                      }
                    />
                  ),
                },
              ]}
            />
            <Row className="row-form" style={{ marginTop: 20 }}>
              <Col xs={24} md={18} xl={18}></Col>
              <Col xs={24} md={6} xl={6}>
                {enableButtonSave(applicationModules, permissions) && (
                  <Button block={true} type="primary" htmlType="submit">
                    Guardar
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Loading />
    </>
  );
}

export default Profile;
