import { Col, Form, Input, Row } from "antd";
import { optionsYesNo } from "../../../../constants";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";

function CharacterizationProduction({ handleChange, record }: any) {
  const { landProperty }: any = severalStore();
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿La producción está orientada a la comercialización?">
            <Select
              name="production_oriented_marketing"
              value={record.production_oriented_marketing}
              onChange={handleChange}
              options={optionsYesNo}
              placeholder="Seleccionar"
              option0={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Qué área de tierra usa para el desarrollo de la actividad económica en hectáreas?"
            }
          >
            <Input
              placeholder=""
              name="area_used_development_economic_activity_hectares"
              onChange={handleChange}
              value={record.area_used_development_economic_activity_hectares}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Cuál es la figura de uso o tenencia de la tierra donde desarrolla la actividad económica agrícola?">
            <Select
              name="use_ownership_land_economic_activity_id"
              value={record.use_ownership_land_economic_activity_id}
              onChange={handleChange}
              options={landProperty.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
              placeholder="Seleccionar"
              option0={true}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>
    </Form>
  );
}

export default CharacterizationProduction;
