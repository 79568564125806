import { Navigate, Outlet } from "react-router-dom";
import { userStore } from "../stores";
import { validatePermissions } from "../utilities";

function PrivateRoute({ module }: { module: number }) {
  const { token, permissions } = userStore();

  const validateAccess = () => {
    if (!token) return false;
    if (module === 0) return true;

    return validatePermissions(module, permissions);
  };

  return validateAccess() ? <Outlet /> : <Navigate to={"/"} />;
}

export default PrivateRoute;
