import "./style.scss";
import { useState, useEffect } from "react";
import { Breadcrumb, Col, Row } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { severalStore } from "../../../../stores";
import { Loading } from "../../../../components";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { paxios } from "../../../../http";
import { URL_SERVER } from "../../../../config";
import Header from "../../header/Header";

function Home() {
  const navigate = useNavigate();
  const {
    ipSelectedMap,
    setFaSelectdMap,
    setShowLoading,
  }: {
    ipSelectedMap: any;
    setFaSelectdMap: any;
    setShowLoading: any;
  } = severalStore();
  const [dataForm, setDataForm] = useState<any>(null);
  const getAssociativeFormsMap = async (associative_form_id: any) => {
    try {
      setShowLoading(true);
      const u = await paxios.get(
        `${URL_SERVER}/home_public/associative_forms_map`
      );
      const data = u.data.features.map((x: any) => ({ ...x.properties }));
      setDataForm(data.find((x: any) => x.id === associative_form_id));
      setShowLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getAssociativeFormsMap(ipSelectedMap.associative_form_id);
    // eslint-disable-next-line
  }, [ipSelectedMap.associative_form_id]);

  const handleClickViewForm = (form: any) => {
    setFaSelectdMap(form);
    navigate("/associative-form-view");
  };

  if (!ipSelectedMap) {
    return <Navigate to={"/"} />;
  }
  return (
    <>
      <Header />
      <Row>
        <Col md={1} lg={1}></Col>
        <Col md={20} lg={20}>
          <div className="container">
            <div className="row">
              <div className="col">
                <section>
                  <div className="mt-5 mt-lg-0">
                    <Breadcrumb
                      items={[
                        {
                          title: (
                            <Link
                              color="inherit"
                              to="/"
                              style={{ textDecoration: "none", color: "#000" }}
                            >
                              Inicio
                            </Link>
                          ),
                        },
                        {
                          title: (
                            <Link
                              color="inherit"
                              to="/associative-form-project-list"
                              style={{ textDecoration: "none", color: "#000" }}
                            >
                              Formas Asociativas
                            </Link>
                          ),
                        },
                        {
                          title: ipSelectedMap ? ipSelectedMap.name : "",
                        },
                      ]}
                    />
                  </div>
                </section>

                <section className="pt-3" style={{ marginTop: "1.5rem" }}>
                  <Row
                    className="row"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Col xs={24} md={18} lg={18} className="col-md-9">
                      <div className="mb-3">
                        <h1 className="associative-forms-list-title fw-bold p-2">
                          {ipSelectedMap?.name}
                        </h1>
                        <div className="form-associative-view-associative-form-pInfo">
                          <Row className="d-flex align-items-center">
                            <Col className="mt-2 mt-sm-0">
                              <h4>
                                <span className="subtitle-list">
                                  {ipSelectedMap.type_project === "P"
                                    ? "Proyecto productivo"
                                    : "Iniciativa productiva"}
                                  <b
                                    style={{
                                      marginLeft: 5,
                                      color: "#000",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {ipSelectedMap.status
                                      ? ipSelectedMap.status.name
                                      : ""}
                                  </b>
                                </span>
                              </h4>
                            </Col>
                            <p>{ipSelectedMap?.description}</p>
                          </Row>
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} md={5} lg={5} className="col">
                      <div
                        className="calendar-section"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        <h4 className="fw-bold">Departamento</h4>
                        <div>
                          <h4 className="m-0 text-center fw-bold">
                            {ipSelectedMap.department
                              ? ipSelectedMap.department.name
                              : ""}
                          </h4>
                        </div>
                      </div>

                      <div className="calendar-section w-100 ms-sm-2 ms-lg-0 d-block p-1">
                        <h4 className="fw-bold">Municipio</h4>
                        <div>
                          <h4 className="m-0 text-center fw-bold">
                            {ipSelectedMap.municipality
                              ? ipSelectedMap.municipality.name
                              : ""}
                          </h4>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </section>
              </div>
            </div>

            <section className="mt-4">
              <h2 className="fw-bold">Forma asociativa</h2>
            </section>

            {dataForm && (
              <div className="form-associative-view-associative-form-list mb-3">
                <div className="form-associative-view-associative-form-list-item">
                  <div className="top">
                    <Row>
                      <Col md={17} lg={17} xs={12}>
                        <div className="form-associative-view-associative-form-pInfo">
                          <Row className="d-flex align-items-center">
                            <Col className="col-sm-auto d-flex justify-content-sm-center">
                              <img
                                className="img-fluid"
                                width="85"
                                height="90"
                                src={`${URL_SERVER}/home_public/getLogoAssociativeForms/${dataForm.nit}`}
                                alt="Logo de la forma asociativa"
                              />
                            </Col>
                            <Col className="mt-2 mt-sm-0">
                              <h4>
                                {dataForm.name}
                                <strong> ({dataForm?.initials}) </strong>
                                <span>
                                  {dataForm.association_types
                                    ? dataForm.association_types.name
                                    : ""}
                                </span>
                              </h4>

                              <div>
                                <p>{dataForm?.description}</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col
                        className="col-md-auto"
                        md={7}
                        lg={7}
                        xs={12}
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.06)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="no-projects">
                          <div>
                            <span className="tag-number">
                              {dataForm.publicProjects
                                ? dataForm.publicProjects.length
                                : "0"}
                            </span>
                            <span>Iniciativas y proyectos</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="bottom">
                    <Row className="align-items-center">
                      <Col
                        md={5}
                        lg={5}
                        className="v-line text-muted text-center"
                      >
                        <div className="fw-bold">{dataForm.women} mujeres</div>
                        <div className="small">Asociadas</div>
                      </Col>
                      <Col
                        md={5}
                        lg={5}
                        className="v-line text-muted text-center"
                      >
                        <div className="fw-bold">{dataForm.men} hombres</div>
                        <div className="small">Asociados</div>
                      </Col>
                      <Col
                        md={12}
                        lg={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          className="btn-view"
                          onClick={() => handleClickViewForm(dataForm)}
                          style={{ cursor: "pointer" }}
                        >
                          Ver forma asociativa <CaretRightOutlined />
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Loading />
    </>
  );
}

export default Home;
