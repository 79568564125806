import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { userStore, severalStore } from "./stores";

export const messageSweetalert = async (
  title: any,
  content: any,
  icon: any = "success",
  showCancelButton: any = false
) => {
  const MySwal = withReactContent(Swal);

  return MySwal.fire({
    title: title,
    html: content,
    icon: icon,
    showCancelButton: showCancelButton,
  });
};

export const orderArrayObject = (arr: [], property: string) => {
  return arr.sort((a, b) => {
    // @ts-ignore
    const nameA = a[property].toUpperCase();
    // @ts-ignore
    const nameB = b[property].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};

export const dowloadExcel = (
  datos: any,
  nombreArchivo: any = "datos",
  formato: any = { columna: null, formato: null }
) => {
  const ws = XLSX.utils.json_to_sheet(datos);
  if (formato.columna) {
    for (let i = 0; i <= datos.length; i++) {
      ws[`${formato.columna}${i + 1}`].z = formato.formato;
    }
  }
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, nombreArchivo);
  XLSX.writeFile(wb, `${nombreArchivo}.xlsx`);
};

export const validatePermissions = (module: number, permissions: any[]) => {
  const permission: any = permissions.find(
    (x: any) => String(x.id_module) === String(module)
  );
  if (!permission) return false;

  if (
    String(permission.fa) !== "0" ||
    String(permission.eco) !== "0" ||
    String(permission.fede) !== "0"
  )
    return true;

  return false;
};

export const enableButtonSave = (
  applicationModules: any,
  permissions: any[],
  module = "characterization"
) => {
  const userTypeId = userStore.getState().user_type_id;
  const userAssociativeFormId = userStore.getState().associative_form_id;
  const formSelected: any = severalStore.getState().formSelected;
  if (permissions.length <= 0) return false;

  const permission: any = permissions.find(
    (x: any) => String(x.id_module) === String(applicationModules[module])
  );

  if (!permission) return false;

  if (String(userTypeId) === "1") {
    if (String(permission.fa) === "2") return true;
  }

  if (String(userTypeId) === "8") {
    if (
      String(permission.fede) === "2" &&
      String(userAssociativeFormId) === String(formSelected?.id)
    )
      return true;
  }

  if (String(userTypeId) === "5") {
    if (
      String(permission.eco) === "2" &&
      String(userAssociativeFormId) === String(formSelected?.id)
    )
      return true;
  }

  return false;
};

export const getDiagnosticQuestion = (id: any, questions: any[]) => {
  const question = questions.find((x: any) => String(x.id) === String(id));

  if (question) return question;

  return {
    id: 0,
    question: "No encontrada",
  };
};

export const getNameForm = (form: any) => {
  if (!form) return "";
  if (!form.name) return "";
  return form.name;
};
