import { Accordion, CircularProgressWithLabel } from "../../../../components";
import { severalStore } from "../../../../stores";
import General from "./General";
import ProductionResources from "./ProductionResources";
import Processes from "./Processes";
import Sustainability from "./Sustainability";
import EnvironmentalGoodPractices from "./EnvironmentalGoodPractices";
import Integration from "./Integration";

function EconomicActivity({
  handleClickSave,
  errors,
  data,
  setValues,
  visible = true,
}: any) {
  const { subCategoryQuestionDiagnostic }: any = severalStore();
  return (
    <Accordion
      style={visible ? {} : { display: "none" }}
      size={"large"}
      defaultActiveKey={["7"]}
      items={[
        {
          key: "7",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 7)
            ?.name,
          children: <General />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedEconomicActivity.completedGeneral}
            />
          ),
        },
        {
          key: "8",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 8)
            ?.name,
          children: <ProductionResources />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={
                data.completedEconomicActivity.completedProductionResources
              }
            />
          ),
        },
        {
          key: "9",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 9)
            ?.name,
          children: <Processes />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedEconomicActivity.completedProcesses}
            />
          ),
        },
        {
          key: "10",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 10)
            ?.name,
          children: <Sustainability />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedEconomicActivity.completedSustainability}
            />
          ),
        },
        {
          key: "11",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 11)
            ?.name,
          children: <EnvironmentalGoodPractices />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={
                data.completedEconomicActivity
                  .completedEnvironmentalGoodPractices
              }
            />
          ),
        },
        {
          key: "12",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 12)
            ?.name,
          children: <Integration />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedEconomicActivity.completedIntegration}
            />
          ),
        },
      ]}
    />
  );
}

export default EconomicActivity;
