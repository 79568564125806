import { Alert, Col, Form, Input, Row } from "antd";
import { optionsYesNo } from "../../../../constants";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import CyclesDuration from "./CyclesDuration";

function CharacterizationProduction({ handleChange, record, errors }: any) {
  const { landProperty, productionPurpose }: any = severalStore();
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿La producción está orientada a la comercialización?">
            <Select
              name="production_oriented_marketing"
              value={record.production_oriented_marketing}
              onChange={handleChange}
              options={optionsYesNo}
              placeholder="Seleccionar"
              option0={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Qué área de tierra usa para el desarrollo de la actividad económica en hectáreas?"
            }
          >
            <Input
              placeholder=""
              name="area_used_development_economic_activity_hectares"
              onChange={handleChange}
              value={record.area_used_development_economic_activity_hectares}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Cuál es la figura de uso o tenencia de la tierra donde desarrolla la actividad económica agrícola?">
            <Select
              name="use_ownership_land_economic_activity_id"
              value={record.use_ownership_land_economic_activity_id}
              onChange={handleChange}
              options={landProperty.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
              placeholder="Seleccionar"
              option0={true}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"¿Qué especie produce, o variedad si corresponde?"}>
            <Input
              placeholder=""
              name="species"
              onChange={handleChange}
              value={record.species}
            />
          </Form.Item>
          {errors?.species && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.species}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Cuál es el propósito de su producción pecuaria?">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              option0={false}
              options={productionPurpose.map((x: any) => {
                return {
                  value: String(x.id),
                  label: x.name,
                };
              })}
              name="purpose_id"
              value={record.purpose_id}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <CyclesDuration
            handleChange={handleChange}
            record={record}
            titleTable={"Ciclos de producción del producto/lote para la venta"}
            typeTable={"production_cycles_duration"}
          />
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={"¿Cuántos animales tiene destinados a la producción?"}
          >
            <Input
              placeholder=""
              name="productive_inventory"
              onChange={handleChange}
              value={record.productive_inventory}
              type="number"
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={"¿Cuántas unidades produce por cada ciclo de venta o lote?"}
          >
            <Input
              placeholder=""
              name="production_volume"
              onChange={handleChange}
              value={record.production_volume}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Cuenta con los permisos necesarios para el desarrollo de la actividad productiva pecuaria?">
            <Select
              name="operating_health_permits"
              value={record.operating_health_permits}
              onChange={handleChange}
              options={optionsYesNo}
              placeholder="Seleccionar"
              option0={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>
    </Form>
  );
}

export default CharacterizationProduction;
