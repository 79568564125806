import { useState, useEffect, useCallback } from "react";
import { paxios } from "../../../../http";
import { URL_SERVER } from "../../../../config";
import { Breadcrumb, Button, Col, Pagination, Row } from "antd";
import { CloudDownloadOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { severalStore } from "../../../../stores";
import { dowloadExcel } from "../../../../utilities";
import { Loading } from "../../../../components";

const perPage = 5;

const calculateFromTo = (
  page: number,
  perPage: number,
  quantityRecord: number
) => {
  let newTo = page * perPage - 1;
  const newFrom = newTo - perPage + 1;

  if (newTo > quantityRecord) {
    newTo = quantityRecord - 1;
  }

  if (page === 1) {
    return {
      from: 0,
      to: perPage - 1,
    };
  }

  return {
    from: newFrom,
    to: newTo,
  };
};

function Home({ filterDepa = "0", filterMuni = "0" }: any) {
  const {
    departments,
    municipalies,
    setIpSelectedMap,
    setShowLoading,
  }: {
    departments: any;
    municipalies: any;
    setIpSelectedMap: any;
    showLoading: boolean;
    setShowLoading: any;
  } = severalStore();
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [dataVisible, setDataVisible] = useState<any>([]);
  const [dataForm, setDataForm] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fromTo, setFromTo] = useState({
    from: 0,
    to: perPage - 1,
  });

  const getProjectMap = async () => {
    try {
      const u = await paxios.get(`${URL_SERVER}/home_public/projects_map`);
      const data = u.data.features.map((x: any) => ({ ...x.properties }));
      setData(data);
      setDataVisible(data);
    } catch (error) {}
  };

  const getAssociativeFormsMap = async () => {
    try {
      const u = await paxios.get(
        `${URL_SERVER}/home_public/associative_forms_map`
      );
      const data = u.data.features.map((x: any) => ({ ...x.properties }));
      setDataForm(data);
    } catch (error) {}
  };

  const getData = async () => {
    setShowLoading(true);
    await getProjectMap();
    await getAssociativeFormsMap();
    setShowLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataVisible]);

  const filterInfoDepartment = (info: any, departmentId: any) => {
    const newInfo = info.filter(
      (x: any) => String(x.department_code) === departmentId
    );
    setDataVisible(newInfo);
  };

  const filterInfoMunicipality = (info: any, municipalityId: any) => {
    const newInfo = info.filter(
      (x: any) => String(x.municipality_code) === municipalityId
    );
    setDataVisible(newInfo);
  };

  const onChangeDepartment = useCallback(
    (department: string) => {
      if (String(department) === "0") {
        setDataVisible(data);
        return;
      }
      filterInfoDepartment(data, department);
    },
    [data]
  );

  const onChangeMunicipality = useCallback(
    (municipality: string) => {
      if (String(municipality) === "0") {
        setDataVisible(dataVisible);
        return;
      }
      filterInfoMunicipality(dataVisible, municipality);
    },
    // eslint-disable-next-line
    [filterMuni]
  );

  useEffect(() => {
    onChangeDepartment(filterDepa);
  }, [filterDepa, onChangeDepartment]);

  useEffect(() => {
    onChangeMunicipality(filterMuni);
  }, [filterMuni, onChangeMunicipality]);

  const handleChangePagination = (e: any, page: any) => {
    setCurrentPage(page);
    const res = calculateFromTo(page, perPage, dataVisible.length);
    setFromTo(res);
  };

  const handleClickViewProject = (form: any) => {
    setIpSelectedMap(form);
    navigate("/project-iniciative-view");
  };

  const getFormName = (dataForm: any, associative_form_id: any) => {
    if (dataForm.length <= 0) return "";

    const form = dataForm.find((x: any) => x.id === associative_form_id);

    if (form) {
      return form.name;
    }

    return "";
  };

  const organizeExcelData = (data: any) => {
    const newData = data.map((x: any) => {
      return {
        "Id Forma Asociativa": x.associative_form_id,
        "Nombre Forma Asociativa": getFormName(dataForm, x.associative_form_id),
        "Fecha de creacion": "",
        "Fecha final": x.enddate,
        "Codigo Departamento": x.department_code,
        Departamento: x.department ? x.department.name : "",
        "Codigo Municipio": x.municipality_code,
        Municipio: x.municipality ? x.municipality.name : "",
        Id: x.id,
        Nombre: x.name,
        Descripción: x.description,
        Productos: x.products_count,
        Estado: x.status ? x.status.name : "",
        "Tipo Proyecto": x.type,
        Tipo: "Projects",
      };
    });

    return newData;
  };

  const handleClickDowloadExcel = () => {
    const data = organizeExcelData(dataVisible);
    dowloadExcel(data, "proyectos_iniciativas");
  };
  return (
    <Row>
      <Col md={24} lg={24}>
        <Breadcrumb
          items={[
            {
              title: (
                <Link
                  color="inherit"
                  to="/"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Inicio
                </Link>
              ),
            },

            {
              title: "Iniciativas y Proyectos productivos",
            },
          ]}
        />

        <div className="mt-4">
          <h1 className="associative-forms-list-title">
            Iniciativas y Proyectos productivos
          </h1>
        </div>

        <Row>
          <Col md={18} lg={18}>
            {filterDepa !== "0" && (
              <div className="col filters">
                <div className="pb-2 text-muted" style={{ marginBottom: 10 }}>
                  Tus filtros
                </div>
                <div>
                  {filterDepa !== "0" && (
                    <span className="filter-boxes">
                      {
                        departments.find(
                          (x: any) => String(x.code) === filterDepa
                        )?.name
                      }
                    </span>
                  )}
                  {filterMuni !== "0" && (
                    <span className="filter-boxes">
                      {
                        municipalies.find(
                          (x: any) => String(x.code) === filterMuni
                        )?.name
                      }
                    </span>
                  )}
                </div>
              </div>
            )}
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<CloudDownloadOutlined />}
              onClick={handleClickDowloadExcel}
            >
              Exportar excel
            </Button>
          </Col>
        </Row>

        <Row>
          <Col md={24} lg={24}>
            <section className="pb-2">
              <p>
                Se encontraron{" "}
                <span className="fw-bold">{dataVisible.length}</span>
                &nbsp;iniciativas/proyectos productivos comprometidas a
                construir un mejor país.
              </p>
            </section>
          </Col>
        </Row>

        <Row>
          <Col
            md={24}
            lg={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Pagination
              defaultCurrent={1}
              total={dataVisible.length * 2}
              current={currentPage}
              onChange={handleChangePagination}
              showSizeChanger={false}
            />
          </Col>
        </Row>

        {dataVisible
          .filter(
            (y: any, index: any) => index >= fromTo.from && index <= fromTo.to
          )
          .map((x: any) => (
            <div
              key={x.id}
              className="form-associative-view-associative-form-list mb-3"
            >
              <div className="form-associative-view-associative-form-list-item">
                <div className="top">
                  <Row>
                    <Col>
                      <div className="form-associative-view-associative-form-pInfo">
                        <Row className="d-flex align-items-center">
                          <Col className="mt-2 mt-sm-0">
                            <h4>
                              {x.name}

                              <span>
                                {x.type_project === "P"
                                  ? "Proyecto productivo"
                                  : "Iniciativa productiva"}
                                <b
                                  style={{
                                    marginLeft: 5,
                                    color: "#000",
                                    fontWeight: 400,
                                  }}
                                >
                                  {x.status ? x.status.name : ""}
                                </b>
                              </span>
                            </h4>

                            <div>
                              <p style={{ fontSize: 14 }}>{x?.description}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="bottom">
                  <Row className="align-items-center">
                    <Col md={14} lg={14} className="text-muted">
                      <div className="fw-bold" style={{ fontSize: 14 }}>
                        Nombre de la forma asociativa
                      </div>
                      <div
                        className="small"
                        style={{ fontSize: 12, fontWeight: 400 }}
                      >
                        {getFormName(dataForm, x.associative_form_id)}
                      </div>
                    </Col>
                    <Col
                      md={10}
                      lg={10}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        className="btn-view"
                        onClick={() => handleClickViewProject(x)}
                        style={{ cursor: "pointer" }}
                      >
                        Ver detalle <CaretRightOutlined />
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          ))}
      </Col>
      <Loading />
    </Row>
  );
}

export default Home;
