import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import { Loading, Page, Table, TabsTitle } from "../../../components";
import banner from "../../../assets/Images/associative-form-banner1.png";
import Profile from "./profile/Profile";
import Associates from "./associates/Associates";
import { severalStore } from "../../../stores";
import { getForms } from "../../../servicesHTTP";

const tabs = [
  {
    value: 0,
    label: (
      <div className="ant-segmented-item-label" title="perfil">
        PERFIL
      </div>
    ),
  },
  {
    value: 1,
    label: (
      <div className="ant-segmented-item-label" title="asociados y asociadas">
        ASOCIADOS Y ASOCIADAS
      </div>
    ),
  },
];
const now = dayjs();
const initialData = {
  id: 0,
  commercial_contact_cellphone_number: "",
  commercial_contact_email: "",
  commercial_contact_cellphone_number_2: "",
  commercial_contact_web_site: "",
  commercial_registration_id: "",
  contact_cellphone_number: "",
  contact_document_number: "",
  contact_document_type: "",
  contact_first_name: " ",
  contact_last_name: "",
  contact_cellphone_number_2: "",
  coverage: "",
  created_date: now.format("YYYY-MM-DD"),
  dv: "",
  initials: "",
  last_renovation_date: now.format("YYYY-MM-DD"),
  legality_control_date: now.format("YYYY-MM-DD"),
  legality_control_supersolidaria: "",
  name: "",
  nit: "",
  other_territorial_link: "",
  registration_date: now.format("YYYY-MM-DD"),
  special_tributary_regime: "N",
  village: "",
  association_type_id: "0",
  department_id: "",
  municipality_id: "",
  vinculation_id: 0,
  vinculation_type_id: 0,
  latitude: "",
  longitude: "",
  address: "",
  description: "",
  constitution_act: "",
  women: 0,
  men: 0,
  associated: 0,
  lgtbi: null,
  admin_women: null,
  disabled: null,
  indigenous: null,
  afro: null,
  ecomun_region: null,
  status: null,
  pdet_zone_id: null,
  migration: null,
  percentage: 79,
  observations: null,
  has_branches: 0,
  federation_character: 0,
  economic_activity: 0,
  economic_activity_object: null,
  economic_activity_2: 0,
  economic_activity_2_object: null,
  economic_activity_3: 0,
  economic_activity_3_object: null,
  economic_activity_other: "",
  area_economic_activity: "U",
  address_economic_activity: "",
  department_id_economic_activity: 0,
  municipality_id_economic_activity: 0,
  village_economic_activity: "",
  latitude_economic_activity: 0,
  longitude_economic_activity: 0,
  integration_process: 0,
  integration_process_which: [],
  branches: [],
  chamber_commerce_id: 0,
  niif_id: 0,
  legal_representative: [],
  contact_email: "",
  contact_website: "",
  contact_facebook: "",
  contact_twitter: "",
  contact_instagram: "",
  bank_accounts: [],
  percentage_progress_information: {
    percentageGeneral: 0,
    percentageContact: 0,
    percentageLegal: 0,
    percentageBank: 0,
    percentageTotal: 0,
  },
  numberLoad: 1,
};

const tableColumns = [
  {
    title: "Nit",
    dataIndex: "nit",
    key: "nit",
    width: 200,
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Sigla",
    dataIndex: "initials",
    key: "initials",
    width: 200,
  },
  {
    title: "Tipo organización",
    dataIndex: "type",
    key: "type",
    width: 200,
    render: (text: any, record: any, index: any) => {
      return `${record.type ? record.type.name : ""}`;
    },
  },
];

function Home() {
  const {
    update: updateStore,
    setShowLoading,
    federationsTypesIds,
    formSelected,
  }: any = severalStore();
  const refTableFede = useRef<any>(null);
  const refTableForms = useRef<any>(null);
  const [federations, setFederations] = useState<any[]>([]);
  const [forms, setForms] = useState<any[]>([]);
  const [visibleForms, setVisibleForms] = useState<any[]>([]);
  const [value, setValue] = useState<number>(0);

  const getFormsLocal = useCallback(async () => {
    setShowLoading(true);
    const data = await getForms();
    if (data.status === "ok") {
      const fede = data.data.filter((x: any) =>
        //@ts-ignore
        federationsTypesIds.includes(String(x.association_type_id))
      );

      const forms = data.data.filter(
        (x: any) =>
          //@ts-ignore
          !federationsTypesIds.includes(String(x.association_type_id))
      );

      if (forms.length > 0 && fede.length <= 0) {
        updateStore({ formSelected: { ...forms[0], numberLoad: 2 } });
      }

      if (fede.length > 0) {
        preSelectTable(refTableFede, [fede[0].key]);
        setVisibleForms(
          forms.filter((x: any) => {
            if (x.belongs_to_federation.length <= 0) return false;
            if (x.belongs_to_federation[0].id_federation === fede[0].id)
              return true;

            return false;
          })
        );
        updateStore({ formSelected: fede[0] });
      }

      setForms(forms);
      setFederations(fede);
    }
    setShowLoading(false);
  }, [setShowLoading, federationsTypesIds, updateStore]);

  useEffect(() => {
    getFormsLocal();
  }, [getFormsLocal]);

  const preSelectTable = (refTable: any, keys: any) => {
    if (refTable.current !== null) {
      refTable.current.setSelectedRowKeys(keys);
    }
  };

  const onChangeSelectFede = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    preSelectTable(refTableForms, []);
    if (newSelectedRowKeys.length > 0) {
      setVisibleForms(
        forms.filter((x: any) => {
          if (x.belongs_to_federation.length <= 0) return false;
          if (
            x.belongs_to_federation[0].id_federation === newSelectedRowKeys[0]
          )
            return true;

          return false;
        })
      );
      updateStore({ formSelected: newSelectedRows[0] });
    } else {
      setVisibleForms([...forms]);
      updateStore({ formSelected: { ...initialData } });
    }
  };

  const onChangeSelectForms = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    preSelectTable(refTableFede, []);
    if (newSelectedRowKeys.length > 0) {
      updateStore({ formSelected: newSelectedRows[0] });
    } else {
      updateStore({ formSelected: { ...initialData } });
    }
  };

  return (
    <Page>
      <Row>
        <Col flex="auto">
          <div className="container-image-banner-page">
            <img
              className="image-banner-page"
              src={banner}
              alt="Banner caracterización"
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 30, paddingLeft: "5%", paddingRight: "5%" }}>
        <Col flex={"auto"}>
          {federations.length > 0 && (
            <>
              <Table
                title={() => "Federaciones"}
                dataSource={federations || []}
                columns={tableColumns}
                bordered
                scroll={{ x: "1000" }}
                onChangeSelect={onChangeSelectFede}
                ref={refTableFede}
                clickSelect={true}
              />

              <Table
                title={() => "Organizaciones Asociadas"}
                dataSource={visibleForms}
                columns={tableColumns}
                bordered
                scroll={{ x: "1000" }}
                onChangeSelect={onChangeSelectForms}
                ref={refTableForms}
                clickSelect={true}
              />
            </>
          )}
        </Col>
      </Row>
      {formSelected && (
        <Row style={{ marginTop: 30, paddingLeft: "5%", paddingRight: "5%" }}>
          <Col flex={"auto"}>
            <TabsTitle options={tabs} value={value} setValue={setValue} />
            {value === 0 && <Profile getFormsLocal={getFormsLocal} />}
            {value === 1 && <Associates />}
          </Col>
        </Row>
      )}
      <Loading />
    </Page>
  );
}

export default Home;
