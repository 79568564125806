import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import { Loading, Page, Table } from "../../../components";
import { severalStore } from "../../../stores";
import { getForms } from "../../../servicesHTTP";
import { getNameForm } from "../../../utilities";
import ManufactureProducts from "./manufactureProducts/Home";
import FarmingProducts from "./farmingProducts/Home";
import LivestockProducts from "./livestockProducts/Home";
import ServiceProducts from "./ServiceProducts/Home";

const tableColumns = [
  {
    title: "Nit",
    dataIndex: "nit",
    key: "nit",
    width: 200,
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Sigla",
    dataIndex: "initials",
    key: "initials",
    width: 200,
  },
  {
    title: "Tipo organización",
    dataIndex: "type",
    key: "type",
    width: 200,
    render: (text: any, record: any, index: any) => {
      return record.type ? `${record.type.name}`: ""
    },
  },
];

function Home() {
  const {
    update: updateStore,
    setShowLoading,
    federationsTypesIds,
    formSelected,
    groupEconomicActivity,
    economicActivity,
    getSeveralData,
  }: any = severalStore();
  const refTableFede = useRef<any>(null);
  const refTableForms = useRef<any>(null);
  const [federations, setFederations] = useState<any[]>([]);
  const [forms, setForms] = useState<any[]>([]);
  const [visibleForms, setVisibleForms] = useState<any[]>([]);

  const getFormsLocal = useCallback(async () => {
    setShowLoading(true);
    const data = await getForms();
    if (data.status === "ok") {
      const fede = data.data.filter((x: any) =>
        //@ts-ignore
        federationsTypesIds.includes(String(x.association_type_id))
      );

      const forms = data.data.filter(
        (x: any) =>
          //@ts-ignore
          !federationsTypesIds.includes(String(x.association_type_id))
      );

      if (forms.length > 0 && fede.length <= 0) {
        updateStore({ formSelected: { ...forms[0], numberLoad: 2 } });
      }

      if (fede.length > 0) {
        preSelectTable(refTableFede, [fede[0].key]);
        setVisibleForms(
          forms.filter((x: any) => {
            if (x.belongs_to_federation.length <= 0) return false;
            if (x.belongs_to_federation[0].id_federation === fede[0].id)
              return true;

            return false;
          })
        );
        updateStore({ formSelected: fede[0] });
      }

      setForms(forms);
      setFederations(fede);
    }
    setShowLoading(false);
  }, [setShowLoading, federationsTypesIds, updateStore]);

  useEffect(() => {
    getFormsLocal();
  }, [getFormsLocal]);

  useEffect(() => {
    (async () => await getSeveralData())();
    // eslint-disable-next-line
  }, []);

  const preSelectTable = (refTable: any, keys: any) => {
    if (refTable.current !== null) {
      refTable.current.setSelectedRowKeys(keys);
    }
  };

  const onChangeSelectFede = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    preSelectTable(refTableForms, []);
    if (newSelectedRowKeys.length > 0) {
      setVisibleForms(
        forms.filter((x: any) => {
          if (x.belongs_to_federation.length <= 0) return false;
          if (
            x.belongs_to_federation[0].id_federation === newSelectedRowKeys[0]
          )
            return true;

          return false;
        })
      );
      updateStore({ formSelected: newSelectedRows[0] });
    } else {
      setVisibleForms([...forms]);
      updateStore({ formSelected: null });
    }
  };

  const onChangeSelectForms = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    preSelectTable(refTableFede, []);
    if (newSelectedRowKeys.length > 0) {
      updateStore({ formSelected: newSelectedRows[0] });
    }
  };

  const selectTypeProduct = (id_group: any) => {
    switch (id_group) {
      case 3:
        return <ManufactureProducts />;

      case 1:
        return <FarmingProducts />;

      case 2:
        return <LivestockProducts />;

      case 4:
        return <ServiceProducts />;

      default:
        return <div>sin productos</div>;
    }
  };
  return (
    <Page>
      <Row style={{ marginTop: 30, paddingLeft: "5%", paddingRight: "5%" }}>
        <Col flex={"auto"}>
          {federations.length > 0 && (
            <>
              <Table
                title={() => "Federaciones"}
                dataSource={federations || []}
                columns={tableColumns}
                bordered
                scroll={{ x: "1000" }}
                onChangeSelect={onChangeSelectFede}
                ref={refTableFede}
                clickSelect={true}
              />

              <Table
                title={() => "Organizaciones Asociadas"}
                dataSource={visibleForms || []}
                columns={tableColumns}
                bordered
                scroll={{ x: "1000" }}
                onChangeSelect={onChangeSelectForms}
                ref={refTableForms}
                clickSelect={true}
              />
            </>
          )}
        </Col>
      </Row>

      <Row
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <Col xs={24} md={24} xl={24} xxl={24} style={{ textAlign: "center" }}>
          <div className="mt-4">
            <h2 className="associative-forms-list-title">Lista productos</h2>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={24} xl={24} xxl={24} style={{ textAlign: "center" }}>
          {getNameForm(formSelected) !== "" && (
            <span> {getNameForm(formSelected)}</span>
          )}
        </Col>
      </Row>

      {formSelected?.economic_activity_object && (
        <>
          <Row
            style={{
              marginTop: 10,
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <Col xs={24} md={12} xl={12} xxl={12}>
              <div className="mt-4">
                <h3 className="associative-forms-list-title">
                  Actividad económica principal:&nbsp;
                </h3>
                {formSelected.economic_activity !== "" && (
                  <>
                    <h3 className="associative-forms-list-title">
                      {
                        groupEconomicActivity.find(
                          (x: any) =>
                            x.id ===
                            formSelected.economic_activity_object.id_group
                        ).name
                      }
                      -
                    </h3>
                    <h3 className="associative-forms-list-title">
                      {
                        economicActivity.find(
                          (x: any) => x.id === formSelected.economic_activity
                        ).name
                      }
                    </h3>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            {selectTypeProduct(formSelected.economic_activity_object.id_group)}
          </Row>
        </>
      )}

      {formSelected?.economic_activity_2_object && (
        <>
          <Row
            style={{
              marginTop: 10,
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <Col xs={24} md={12} xl={12} xxl={12}>
              <div className="mt-4">
                <h3 className="associative-forms-list-title">
                  Actividad económica secundaria:&nbsp;
                </h3>
                {formSelected.economic_activity_2 !== "" && (
                  <>
                    <h3 className="associative-forms-list-title">
                      {
                        groupEconomicActivity.find(
                          (x: any) =>
                            x.id ===
                            formSelected.economic_activity_2_object.id_group
                        ).name
                      }
                      -
                    </h3>

                    <h3 className="associative-forms-list-title">
                      {
                        economicActivity.find(
                          (x: any) => x.id === formSelected.economic_activity_2
                        ).name
                      }
                    </h3>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            {selectTypeProduct(
              formSelected.economic_activity_2_object.id_group
            )}
          </Row>
        </>
      )}

      {formSelected?.economic_activity_3_object && (
        <>
          <Row
            style={{
              marginTop: 10,
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <Col xs={24} md={12} xl={12} xxl={12}>
              <div className="mt-4">
                <h3 className="associative-forms-list-title">
                  Actividad económica terciaria:&nbsp;
                </h3>
                {formSelected.economic_activity_3 !== "" && (
                  <>
                    <h3 className="associative-forms-list-title">
                      {
                        groupEconomicActivity.find(
                          (x: any) =>
                            x.id ===
                            formSelected.economic_activity_3_object.id_group
                        ).name
                      }
                      -
                    </h3>
                    <h3 className="associative-forms-list-title">
                      {
                        economicActivity.find(
                          (x: any) => x.id === formSelected.economic_activity_3
                        ).name
                      }
                    </h3>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            {selectTypeProduct(
              formSelected.economic_activity_3_object.id_group
            )}
          </Row>
        </>
      )}

      <Loading />
    </Page>
  );
}

export default Home;
