import { useState } from "react";
import { Form, Row, Modal, Col, Input, Alert } from "antd";
import uuid from "react-uuid";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.name === "") errors.name = "Ingrese Nombre";
  if (data.cost === "") errors.cost = "Ingrese valor";
  if (String(data.unit_id) === "0") errors.unit_id = "Seleccione Unidad";
  return errors;
};

function CreateEditPresentations({
  open,
  handleClose,
  data,
  dataAll,
  handleChange,
}: any) {
  const { unitMeasurementPresentation }: any = severalStore();
  const [record, setRecord] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  const handleChangeLocal = (e: any) => {
    const { name, value } = e.target;
    setRecord((prev: any) => ({ ...prev, [name]: value }));
  };

  const create = (data: any) => {
    const a = [...dataAll, { ...data, new: false }];
    handleChange({ target: { name: "presentations", value: a } });
    handleClose(false);
  };

  const edit = (data: any) => {
    const a = dataAll.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    handleChange({ target: { name: "presentations", value: a } });
    handleClose(false);
  };

  const handleClickSave = () => {
    const errors = validateData(record);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (record.new) {
      const uid = uuid();
      const newPresentation = {
        ...record,
        unit_measurement: unitMeasurementPresentation.find(
          (x: any) => String(x.id) === String(record.unit_id)
        ),
      };
      create({ ...newPresentation, id: uid, key: uid });
      return;
    }

    edit({
      ...record,
      new: false,
      unit_measurement: unitMeasurementPresentation.find(
        (x: any) => String(x.id) === String(record.unit_id)
      ),
    });
    setErrors({});
  };

  return (
    <Modal
      title={`${record.new ? "Agregar" : "Editar"} presentación`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${record.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"800px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Nombre">
              <Input
                name="name"
                value={record.name}
                onChange={handleChangeLocal}
              />
              {errors?.name && (
                <Alert message={errors.name} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Valor">
              <Input
                type="number"
                name="cost"
                value={record.cost}
                onChange={handleChangeLocal}
              />
              {errors?.cost && (
                <Alert message={errors.cost} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Unidad de medida">
              <Select
                name="unit_id"
                value={record.unit_id}
                onChange={handleChangeLocal}
                options={unitMeasurementPresentation.map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
                option0={true}
                placeholder="Seleccionar"
              />
              {errors?.unit_id && (
                <Alert message={errors.unit_id} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}></Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEditPresentations;
