import "./cashTransactionsModal.scss";
import downloadReportFormIcon01 from "../../../assets/Images/sarlaft-images/download-report-form-icon-01.png";
import downloadFillOutManualIcon01 from "../../../assets/Images/sarlaft-images/download-fill-out-manual-icon-01.png";
import { downloadFile } from "../../../http";
import { URL_SERVER } from "../../../config";
import { Card, Modal } from "antd";

function CashTransactionsModal({ open, handleClose, number }: any) {
  const getTitle = (number: any) => {
    let titleShow: string = "Transacciones en efectivo";
    switch (number) {
      case 1:
        titleShow = "Transacciones en efectivo";
        break;
      case 2:
        titleShow = "Transacciones en tarjeta de crédito y debito";
        break;
      case 3:
        titleShow = "Clientes exonerados";
        break;
      case 4:
        titleShow = "Productos";
        break;
      case 5:
        titleShow = "Ausencia de operaciones sospechosas";
        break;
      default: {
        titleShow = "Presencia de operaciones sospechosas";
        break;
      }
    }

    return titleShow;
  };

  const getReport = (type: number) => {
    switch (type) {
      case 1:
        return "Form_Efectivo.xls";
      case 2:
        return "Form_Tarjetas.xls";
      case 3:
        return "Form_Exonerados.xls";
      case 4:
        return "Form_Productos.xls";
      case 5:
        return "Reporte_Operacion_Sospechosa.pdf";
      default: {
        return "Reporte_Operacion_Sospechosa.pdf";
      }
    }
  };

  const getManual = (type: number) => {
    switch (type) {
      case 1:
        return "Instructivo_Efectivo.png";
      case 2:
        return "Instructivo_Tarjetas.png";
      case 3:
        return "Instructivo_Exonerados.png";
      case 4:
        return "Instructivo_Productos.png";
      case 5:
        return "Reporte_Operacion_Sospechosa.pdf";
      default: {
        return "Reporte_Operacion_Sospechosa.pdf";
      }
    }
  };

  const downloadReport = (option: any) => {
    const file = getReport(option);
    downloadFile(`${URL_SERVER}/sarlaft/dowloadfiles`, file, { file });
  };

  const downloadManual = (option: any) => {
    const file = getManual(option);
    downloadFile(`${URL_SERVER}/sarlaft/dowloadfiles`, file, { file });
  };
  return (
    <Modal
      centered
      open={open}
      onCancel={() => handleClose(false)}
      width={"800px"}
      footer={null}
      title={<h1 className="matExp-title">{getTitle(number)}</h1>}
      className="modal-cash-transactions"
    >
      <Card title="" className="mat-dialog-container">
        <div>
          <h2 className="mb-2">¿Que deseas hacer?</h2>
          {parseInt(number) > 0 && parseInt(number) < 5 && (
            <button
              className="reportKind-downSection"
              onClick={() => downloadReport(number)}
            >
              <div className="reportKind-downSection-icon-container me-4">
                <img
                  className="img-fluid"
                  src={downloadReportFormIcon01}
                  alt=""
                />
              </div>
              <h4 className="mb-0">Descargar formulario del reporte</h4>
            </button>
          )}

          <button
            className="reportKind-downSection"
            onClick={() => downloadManual(number)}
          >
            <div className="reportKind-downSection-icon-container me-4">
              <img
                className="img-fluid"
                src={downloadFillOutManualIcon01}
                alt=""
              />
            </div>
            <h4 className="mb-0">Descargar instructivo de diligenciamiento</h4>
          </button>
        </div>
      </Card>
    </Modal>
  );
}

export default CashTransactionsModal;
