import { useState } from "react";
import { Form, Row, Modal, Col, Alert, DatePicker } from "antd";
import uuid from "react-uuid";
import dayjs from "dayjs";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.initial_date === "") errors.initial_date = "Ingrese Fecha inicial";
  if (data.end_date === "") errors.end_date = "Ingrese Fecha final";
  return errors;
};

function CreateEditCyclesDuration({
  open,
  handleClose,
  data,
  dataAll,
  handleChange,
  typeTable,
}: any) {
  const [record, setRecord] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  const handleChangeLocal = (e: any) => {
    const { name, value } = e.target;
    setRecord((prev: any) => ({ ...prev, [name]: value }));
  };

  const create = (data: any) => {
    const a = [...dataAll, { ...data, new: false }];
    handleChange({ target: { name: typeTable, value: a } });
    handleClose(false);
  };

  const edit = (data: any) => {
    const a = dataAll.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    handleChange({ target: { name: typeTable, value: a } });
    handleClose(false);
  };

  const handleClickSave = () => {
    const errors = validateData(record);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (record.new) {
      const uid = uuid();
      const newPresentation = {
        ...record,
      };
      create({ ...newPresentation, id: uid, key: uid });
      return;
    }

    edit({
      ...record,
      new: false,
    });
    setErrors({});
  };

  return (
    <Modal
      title={`${record.new ? "Agregar" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${record.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"800px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Fecha inicial">
              <DatePicker
                allowClear={false}
                style={{ width: "100%" }}
                name="initial_date"
                format={{
                  format: "YYYY-MM-DD",
                  type: "mask",
                }}
                value={dayjs(record?.initial_date)}
                onChange={(date: any, dateString: any) =>
                  handleChangeLocal({
                    target: { name: "initial_date", value: dateString },
                  })
                }
              />
            </Form.Item>
            {errors?.initial_date && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.initial_date}
                type="error"
                showIcon
              />
            )}
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Fecha final">
              <DatePicker
                allowClear={false}
                style={{ width: "100%" }}
                name="end_date"
                format={{
                  format: "YYYY-MM-DD",
                  type: "mask",
                }}
                value={dayjs(record?.end_date)}
                onChange={(date: any, dateString: any) =>
                  handleChangeLocal({
                    target: { name: "end_date", value: dateString },
                  })
                }
              />
            </Form.Item>
            {errors?.end_date && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.end_date}
                type="error"
                showIcon
              />
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEditCyclesDuration;
