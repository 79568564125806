import { Col, Form, Input, Row } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function General() {
  const {
    diagnosticQuestions,
    groupEconomicActivity,
    economicActivity,
    questionsOptionsDiagnostic,
    formSelected,
  } = severalStore();

  const orderEconomicActivity = (
    groupEconomicActivity: any,
    economicActivity: any
  ) => {
    const items: any[] = [];

    groupEconomicActivity.map((x: any) => {
      items.push({
        label: x.name,
        title: x.name,
        options: economicActivity
          .filter((z: any) => String(z.id_group) === String(x.id))
          .map((y: any) => {
            return {
              label: y.name,
              value: String(y.id),
            };
          }),
      });
      return null;
    });

    return items;
  };

  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(25, diagnosticQuestions).question}
            name={"q25"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 25)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            name="q26"
            label={getDiagnosticQuestion(26, diagnosticQuestions).question}
          >
            <Input placeholder="" type="number" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(27, diagnosticQuestions).question}
          >
            <Select
              placeholder="Seleccionar"
              options={orderEconomicActivity(
                groupEconomicActivity,
                economicActivity
              )}
              value={String(formSelected.economic_activity)}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(28, diagnosticQuestions).question}
          >
            <Select
              placeholder="Seleccionar"
              options={orderEconomicActivity(
                groupEconomicActivity,
                economicActivity
              )}
              disabled={true}
              value={String(formSelected.economic_activity_2)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(107, diagnosticQuestions).question}
          >
            <Select
              placeholder="Seleccionar"
              options={orderEconomicActivity(
                groupEconomicActivity,
                economicActivity
              )}
              disabled={true}
              value={String(formSelected.economic_activity_3)}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default General;
