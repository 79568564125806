import { useState } from "react";
import Header from "../header/Header";
import { Row, Col } from "antd";
import FilterMap from "./filterMap/Index";
import FormAsociativeList from "./formAssociativeList/Home";
import ProjectsList from "./projectIniciativeList/Home";

function HomePublicList() {
  const [filterDepa, setFilterDepa] = useState<string>("0");
  const [filterMuni, setFilterMuni] = useState<string>("0");
  const [currentTab, setCurrentTab] = useState(0);

  const onChangeDepartment = (department: string) => {
    setFilterDepa(String(department));
  };

  const onChangeMunicipality = (municipality: string) => {
    setFilterMuni(String(municipality));
  };
  return (
    <>
      <Header />
      <Row style={{ marginTop: 30 }}>
        <Col md={1} lg={1}></Col>
        <Col
          xs={24}
          md={8}
          lg={8}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FilterMap
            onChangeTab={setCurrentTab}
            onChangeDepartment={onChangeDepartment}
            onChangeMunicipality={onChangeMunicipality}
            origin="list"
          />
        </Col>
        <Col md={14} lg={14}>
          {currentTab === 0 && (
            <FormAsociativeList
              filterDepa={filterDepa}
              filterMuni={filterMuni}
            />
          )}
          {currentTab === 1 && (
            <ProjectsList filterDepa={filterDepa} filterMuni={filterMuni} />
          )}
        </Col>
      </Row>
    </>
  );
}

export default HomePublicList;
