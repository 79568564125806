import { Col, Form, Row } from "antd";
import { Select } from "../../../../components";
import { optionsYesNo } from "../../../../constants";

function PermitsLicenses({ handleChange, record }: any) {
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿La prestación del servicio requiere permisos?">
            <Select
              name="operating_permits"
              value={record.operating_permits}
              onChange={handleChange}
              options={optionsYesNo}
              placeholder="Seleccionar"
              option0={true}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Cuenta con los permisos necesarios para la prestación del servicio?">
            <Select
              name="have_necessary_permits"
              value={record.have_necessary_permits}
              onChange={handleChange}
              options={optionsYesNo}
              placeholder="Seleccionar"
              option0={true}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default PermitsLicenses;
