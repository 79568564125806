import { useState, useEffect } from "react";
import { severalStore, userStore } from "../../../../../stores";
import { Button, Col, Divider, Flex, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "../../../../../components";
import { messageSweetalert, enableButtonSave } from "../../../../../utilities";
import { applicationModules } from "../../../../../constants";
import CreateEdit from "./CreateEdit";

const representativesColumns = [
  {
    title: "Tipo documento",
    dataIndex: "document_type_o",
    key: "document_type_o",
    width: 200,
  },
  {
    title: "Documento",
    dataIndex: "document_number",
    key: "document_number",
    width: 200,
  },
  {
    title: "Nombres",
    dataIndex: "first_name",
    key: "first_name",
    width: 200,
  },
  {
    title: "Apellidos",
    dataIndex: "last_name",
    key: "last_name",
    width: 200,
  },
  {
    title: "Tipo",
    dataIndex: "type_o",
    key: "type_o",
    width: 200,
  },
  {
    title: "Celular",
    dataIndex: "cellphone_number",
    key: "cellphone_number",
    width: 200,
  },
  {
    title: "Email",
    dataIndex: "email_address",
    key: "email_address",
    width: 200,
  },
];

const initialData = {
  id: 0,
  cellphone_number: "",
  document_number: "",
  document_type: "CC",
  email_address: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  type: "T",
};

function Home({ form, setValue, handleClickSave }: any) {
  const { permissions } = userStore();
  const { documentTypes, getSeveralData } = severalStore();
  const [representatives, setRepresentatives] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const loadSeveralData = async (documentTypes: any[], getSeveralData: any) => {
    if (documentTypes.length <= 0) {
      await getSeveralData();
    }
  };

  useEffect(() => {
    loadSeveralData(documentTypes, getSeveralData);
  }, [documentTypes, getSeveralData]);

  useEffect(() => {
    setRepresentatives([
      ...form.legal_representative.map((x: any) => ({ ...x, key: x.id })),
    ]);
  }, [form.legal_representative]);

  const onChangeSelectionRepresentatives = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    setSelected(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const create = (data: any) => {
    const a = [...representatives, { ...data, new: false }];
    setValue("legal_representative", a);
    setRepresentatives(a);
    setModalOpen(false);
  };

  const edit = (data: any) => {
    const a = representatives.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    setValue("legal_representative", a);
    setRepresentatives(a);
    setSelected([data]);
    setModalOpen(false);
  };

  return (
    <>
      <Row className="row-form">
        <Col span={23}>
          {enableButtonSave(applicationModules, permissions) && (
            <Flex style={{ marginBottom: 10 }}>
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                disabled={selected.length > 0}
                onClick={() => openCreate(selected)}
              >
                Agregar
              </Button>
              <Button
                type="primary"
                size="large"
                icon={<EditOutlined />}
                disabled={selected.length <= 0}
                onClick={() => openEdit(selected)}
              >
                Editar
              </Button>
            </Flex>
          )}

          <Table
            dataSource={representatives || []}
            columns={representativesColumns}
            bordered
            scroll={{ x: "1000" }}
            onChangeSelect={onChangeSelectionRepresentatives}
          />
        </Col>
      </Row>
      {modalOpen && (
        <CreateEdit
          open={modalOpen}
          handleClose={setModalOpen}
          data={
            selected.length > 0
              ? selected[0]
              : {
                  ...initialData,
                  associative_form_id: form.id,
                  new: true,
                }
          }
          create={create}
          edit={edit}
        />
      )}
      <Divider />
      <Row className="row-form">
        <Col xs={24} md={18} xl={18}></Col>
        <Col xs={24} md={6} xl={6}>
          {enableButtonSave(applicationModules, permissions) && (
            <Button block={true} type="primary" htmlType="submit">
              Guardar
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default Home;
