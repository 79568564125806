import { Spin } from "antd";
import { severalStore } from "../stores";
function Loading() {
  const {
    showLoading,
  }: {
    showLoading: boolean;
  } = severalStore();
  return <Spin spinning={showLoading} fullscreen size="large" style={{ zIndex: 1300 }} />;
}

export default Loading;
