import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Flex, Row } from "antd";
import { PlusOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Page, Loading, Table } from "../../../components";

import banner from "../../../assets/Images/intro-banner1.png";
import { severalStore, userStore } from "../../../stores";
import {
  getForms,
  getDiagnosticQuestions,
  getDiagnostics,
  createDiagnostic,
  updateDiagnostic,
  createDiagnosticAnswers,
  updateDiagnosticAnswers,
} from "../../../servicesHTTP";
import Answers from "./Answers";
import Create from "./Create";
import {
  enableButtonSave,
  getNameForm,
  messageSweetalert,
} from "../../../utilities";
import { applicationModules } from "../../../constants";

const now = dayjs();
const initialData = {
  id: 0,
  create_date: now.format("YYYY-MM-DD"),
  update_date: now.format("YYYY-MM-DD"),
  year: now.format("YYYY"),
  period: "0",
  type: 0,
  state: "0",
  new: true,
};

const tableColumnsForms = [
  {
    title: "Nit",
    dataIndex: "nit",
    key: "nit",
    width: 200,
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "Sigla",
    dataIndex: "initials",
    key: "initials",
    width: 200,
  },
  {
    title: "Tipo organización",
    dataIndex: "type",
    key: "type",
    width: 200,
    render: (text: any, record: any, index: any) => {
      return `${record.type ? record.type.name : ""}`;
    },
  },
];

function Home() {
  const {
    setShowLoading,
    formSelected,
    update: updateStore,
    federationsTypesIds,
    getSeveralData,
  }: any = severalStore();
  const { permissions } = userStore();
  const [federations, setFederations] = useState<any[]>([]);
  const [forms, setForms] = useState<any[]>([]);
  const [visibleForms, setVisibleForms] = useState<any[]>([]);
  //const [questions, setQuestions] = useState([]);
  const [diagnostics, setDiagnostics] = useState<any[]>([]);
  const [selected, setSelected] = useState({ ...initialData });
  //const [selectedAnswers, setSelectedAnswers] = useState({});
  const [modalOpenAnswers, setModalOpenAnswers] = useState<boolean>(false);
  const [modalOpenCreate, setModalOpenCreate] = useState<boolean>(false);
  const [tableColumns] = useState([
    {
      title: "Año",
      dataIndex: "year",
      key: "year",
      width: 100,
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return record.period_type_object ? record.period_type_object.name : "";
      },
    },
    {
      title: "Periodo",
      dataIndex: "period",
      key: "period",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return record.period_object ? record.period_object.period : "";
      },
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return String(record.state) === "0" ? "En modificación" : "Finalizado";
      },
    },
    {
      title: "Porcentaje de diligenciamiento",
      dataIndex: "completed",
      key: "completed",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return `${record.completed}%`;
      },
    },
    {
      title: "Fecha creación",
      dataIndex: "create_date",
      key: "create_date",
      width: 200,
    },
    {
      title: "Fecha modificación",
      dataIndex: "update_date",
      key: "update_date",
      width: 200,
    },
    {
      title: "Acción",
      dataIndex: "acciones",
      key: "acciones",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return (
          <Flex style={{ marginBottom: 10 }}>
            <Button
              type="link"
              size="large"
              icon={<EditOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => {
                setSelected({ ...record, new: false });
                setModalOpenCreate(true);
              }}
            >
              Modificar periodo
            </Button>
            <Button
              type="link"
              size="large"
              icon={<EyeOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => {
                setSelected({ ...record, new: false });
                setModalOpenAnswers(true);
              }}
            >
              Editar diagnóstico
            </Button>
          </Flex>
        );
      },
    },
  ]);
  const refTableFede = useRef<any>(null);
  const refTableForms = useRef<any>(null);

  const getFormsLocal = useCallback(async () => {
    setShowLoading(true);
    const data = await getForms();
    if (data.status === "ok") {
      const fede = data.data.filter((x: any) =>
        //@ts-ignore
        federationsTypesIds.includes(String(x.association_type_id))
      );

      const forms = data.data.filter(
        (x: any) =>
          //@ts-ignore
          !federationsTypesIds.includes(String(x.association_type_id))
      );

      if (forms.length > 0 && fede.length <= 0) {
        updateStore({ formSelected: forms[0] });
      }

      if (fede.length > 0) {
        preSelectTable(refTableFede, [fede[0].key]);
        setVisibleForms(
          forms.filter((x: any) => {
            if (x.belongs_to_federation.length <= 0) return false;
            if (x.belongs_to_federation[0].id_federation === fede[0].id)
              return true;

            return false;
          })
        );
        updateStore({ formSelected: fede[0] });
      }

      setForms(forms);
      setFederations(fede);
    }
    setShowLoading(false);
  }, [setShowLoading, federationsTypesIds, updateStore]);

  const getQuestions = useCallback(async () => {
    setShowLoading(true);
    const data = await getDiagnosticQuestions();
    if (data.status === "ok") {
      updateStore({ diagnosticQuestions: data.data });
    }
    setShowLoading(false);
  }, [setShowLoading, updateStore]);

  const getDiagnosticsLocal = useCallback(
    async (id: any) => {
      setShowLoading(true);
      const data = await getDiagnostics(id);
      if (data.status === "ok") {
        setDiagnostics(data.data);
      }
      setShowLoading(false);
    },
    [setShowLoading]
  );

  useEffect(() => {
    (async () => await getSeveralData())();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      await getFormsLocal();
      await getQuestions();
    })();
  }, [getFormsLocal, getQuestions]);

  useEffect(() => {
    (async () => {
      formSelected && (await getDiagnosticsLocal(formSelected.id));
    })();
  }, [getDiagnosticsLocal, formSelected]);

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      const pre: any = diagnostics.find((x: any) => x.id === selected.id);
      if (pre) {
        setSelected(pre);
      }
    }
    // eslint-disable-next-line
  }, [diagnostics]);

  const saveRecord = async (data: any) => {
    let answer;
    if (String(data.id) === "0") {
      answer = await createDiagnostic({
        ...data,
        associative_form_id: formSelected.id,
      });
    } else {
      answer = await updateDiagnostic(data);
    }

    if (answer.status === "ok") {
      formSelected && (await getDiagnosticsLocal(formSelected.id));
      if (String(data.id) === "0") {
        setSelected({ ...initialData });
      }
      messageSweetalert("Terminado", "Datos guardados");
      setModalOpenCreate(false);
    } else if (answer.status === "error") {
      messageSweetalert(
        "Error",
        `Error al guardar: ${answer.errores}`,
        "error"
      );
    }
  };

  const saveRecordAnswers = async (data: any) => {
    setShowLoading(true);
    let answer;
    if (String(data.id) === "0") {
      answer = await createDiagnosticAnswers(data);
    } else {
      answer = await updateDiagnosticAnswers(data);
    }

    if (answer.status === "ok") {
      messageSweetalert("Terminado", "Datos guardados");
      formSelected && (await getDiagnosticsLocal(formSelected.id));
    } else if (answer.status === "error") {
      messageSweetalert(
        "Error",
        `Error al guardar: ${answer.errores}`,
        "error"
      );
    }
    setShowLoading(false);
  };

  const preSelectTable = (refTable: any, keys: any) => {
    if (refTable.current !== null) {
      refTable.current.setSelectedRowKeys(keys);
    }
  };

  const onChangeSelectFede = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    preSelectTable(refTableForms, []);
    if (newSelectedRowKeys.length > 0) {
      setVisibleForms(
        forms.filter((x: any) => {
          if (x.belongs_to_federation.length <= 0) return false;
          if (
            x.belongs_to_federation[0].id_federation === newSelectedRowKeys[0]
          )
            return true;

          return false;
        })
      );
      updateStore({ formSelected: newSelectedRows[0] });
    } else {
      setDiagnostics([]);
      setVisibleForms([...forms]);
    }
  };

  const onChangeSelectForms = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    preSelectTable(refTableFede, []);
    if (newSelectedRowKeys.length > 0) {
      updateStore({ formSelected: newSelectedRows[0] });
    } else {
      setDiagnostics([]);
    }
  };

  return (
    <Page>
      <Row>
        <Col flex="auto">
          <div className="container-image-banner-page">
            <img
              className="image-banner-page"
              src={banner}
              alt="Banner diagnóstico"
            />
          </div>
        </Col>
      </Row>
      {federations.length > 0 && (
        <Row
          style={{
            marginTop: 30,
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingBottom: 50,
          }}
        >
          <Col span={24}>
            <Table
              title={() => "Federaciones"}
              dataSource={federations || []}
              columns={tableColumnsForms}
              bordered
              scroll={{ x: "1000" }}
              onChangeSelect={onChangeSelectFede}
              ref={refTableFede}
              clickSelect={true}
            />

            <Table
              title={() => "Formas asociativas"}
              dataSource={visibleForms || []}
              columns={tableColumnsForms}
              bordered
              scroll={{ x: "1000" }}
              onChangeSelect={onChangeSelectForms}
              ref={refTableForms}
              clickSelect={true}
            />
          </Col>
        </Row>
      )}

      <Row
        style={{
          marginTop: 20,
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: 70,
        }}
      >
        <Col xs={24} md={12} xl={12} xxl={12}>
          <div className="mt-4">
            <h2 className="associative-forms-list-title">
              Lista de diagnósticos realizados
            </h2>
            {getNameForm(formSelected) !== "" && (
              <span> ({getNameForm(formSelected)})</span>
            )}
          </div>
        </Col>
      </Row>

      {formSelected && (
        <Row style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          <Col span={24}>
            <Flex style={{ marginBottom: 10 }}>
              {enableButtonSave(applicationModules, permissions) && (
                <Button
                  type="primary"
                  size="large"
                  icon={<PlusOutlined />}
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setSelected({ ...initialData });
                    setModalOpenCreate(true);
                  }}
                >
                  Agregar diagnóstico
                </Button>
              )}
            </Flex>
            <Table
              dataSource={diagnostics || []}
              columns={tableColumns}
              bordered
              scroll={{ x: "1000" }}
              selection={false}
            />
          </Col>
        </Row>
      )}
      {modalOpenCreate && (
        <Create
          open={modalOpenCreate}
          handleClose={setModalOpenCreate}
          data={{ ...selected }}
          saveRecord={saveRecord}
        />
      )}

      {modalOpenAnswers && (
        <Answers
          open={modalOpenAnswers}
          handleClose={setModalOpenAnswers}
          data={{ ...selected }}
          saveRecord={saveRecordAnswers}
        />
      )}

      <Loading />
    </Page>
  );
}

export default Home;
