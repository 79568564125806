import { useState } from "react";
import "./style.scss";
import { Layout, Button, Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import android from "../../assets/Images/red-comun.png";
import RightMenu from "./RightMenu";
import { userStore, severalStore } from "../../stores";
import Menu from "../menu/Menu";

function Header() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { update } = severalStore();
  const { name } = userStore();

  const showDrawer = () => {
    setVisible(!visible);
  };

  const handleClickHome = () => {
    update({ itemMenuSelected: "0" });
    navigate(`/home`);
  };

  return (
    <nav className="navbar">
      <Layout>
        <Layout.Header className="nav-header">
          <div className="logo">
            <h3 className="brand-font">
              {/* <Link to="/home"> */}
              <img
                className="logo-redcomun"
                src={android}
                alt="RedComún"
                style={{ cursor: "pointer" }}
                onClick={handleClickHome}
              />
              {/* </Link> */}
            </h3>
          </div>
          <div className="navbar-menu">
            <div className="leftMenu">
              {/* <LeftMenu mode={"horizontal"} /> */}
            </div>
            <Button className="menuButton" type="text" onClick={showDrawer}>
              <MenuOutlined />
            </Button>
            <div
              className="rightMenu"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="col btn-avatar-user-tags">
                <span>Bienvenido, </span>
                <span>{name}</span>
              </div>
              <RightMenu mode={"horizontal"} />
            </div>

            <Drawer
              title={
                <img className="logo-redcomun" src={android} alt="RedComún" />
              }
              placement="left"
              closable={true}
              onClose={showDrawer}
              open={visible}
              style={{ zIndex: 1250 }}
            >
              <Menu style={{ width: 378, paddingTop: 10 }} />
            </Drawer>
          </div>
        </Layout.Header>
      </Layout>
    </nav>
  );
}

export default Header;
