import { Menu, MenuProps } from "antd";
import { Link } from "react-router-dom";
type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    label: (
      <Link
        style={{ textDecoration: "none" }}
        className="mat-button navbar-btns"
        to="/login"
      >
        INGRESAR
      </Link>
    ),
    key: "home",
  },
  {
    label: (
      <Link
        style={{ textDecoration: "none" }}
        className="mat-button navbar-btns register-btn"
        to="/type-register"
      >
        REGISTRARME
      </Link>
    ),
    key: "typeRegister",
  },
];

const RightMenu = ({ mode }: any) => {
  return <Menu mode={mode} items={items} />;
};

export default RightMenu;

/* <Menu mode={mode}>
      <Menu.SubMenu
        title={
          <>
            <Avatar icon={<UserOutlined />} />
            <span className="username">John Doe</span>
          </>
        }
      >
        <Menu.Item key="project">
          <CodeOutlined /> Projects
        </Menu.Item>
        <Menu.Item key="about-us">
          <UserOutlined /> Profile
        </Menu.Item>
        <Menu.Item key="log-out">
          <LogoutOutlined /> Logout
        </Menu.Item>
      </Menu.SubMenu>
    </Menu> */
