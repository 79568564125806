import { useEffect, useState } from "react";
import { Table } from "../../../../../components";
import { Button, Col, Flex, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { messageSweetalert } from "../../../../../utilities";
import CreateEdit from "./CreateEdit";

const branchesColumns = [
  {
    title: "Área",
    dataIndex: "area_o",
    key: "area_o",
    width: 200,
  },
  {
    title: "Dirección",
    dataIndex: "address",
    key: "address",
    width: 200,
  },
  {
    title: "Departamento",
    dataIndex: "department_id",
    key: "department_id",
    width: 200,
    render: (text: any, record: any, index: any) => {
      return `${record.department.name}`;
    },
  },
  {
    title: "Municipio",
    dataIndex: "municipio_id",
    key: "municipio_id",
    width: 200,
    render: (text: any, record: any, index: any) => {
      return `${record.municipality.name}`;
    },
  },
  {
    title: "Vereda",
    dataIndex: "village",
    key: "village",
    width: 200,
  },
];

const initialData = {
  id: 0,
  area: "U",
  address: "",
  department_id: 0,
  municipality_id: "0",
  village: "",
};

function Home({ form, setValue }: any) {
  const [branches, setBranches] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    setBranches([...form.branches.map((x: any) => ({ ...x, key: x.id }))]);
  }, [form.branches]);

  const onChangeSelect = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelected(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const create = (data: any) => {
    const a = [...branches, { ...data, new: false }];
    setValue("branches", a);
    setBranches(a);
    setModalOpen(false);
  };

  const edit = (data: any) => {
    const a = branches.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    setValue("branches", a);
    setBranches(a);
    setSelected([data]);
    setModalOpen(false);
  };

  return (
    <Row className="row-form">
      <Col span={23}>
        <Flex style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            style={{ marginRight: 10 }}
            disabled={selected.length > 0}
            onClick={() => openCreate(selected)}
          >
            Agregar
          </Button>
          <Button
            type="primary"
            size="large"
            icon={<EditOutlined />}
            disabled={selected.length <= 0}
            onClick={() => openEdit(selected)}
          >
            Editar
          </Button>
        </Flex>
        <Table
          dataSource={branches || []}
          columns={branchesColumns}
          bordered
          scroll={{ x: "1000" }}
          onChangeSelect={onChangeSelect}
        />
      </Col>
      {modalOpen && (
        <CreateEdit
          open={modalOpen}
          handleClose={setModalOpen}
          data={
            selected.length > 0
              ? selected[0]
              : {
                  ...initialData,
                  id_associativeforms: form.id,
                  new: true,
                }
          }
          create={create}
          edit={edit}
        />
      )}
    </Row>
  );
}

export default Home;
