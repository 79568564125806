import { useState } from "react";
import "./aboutUs.scss";
import { Row, Col } from "antd";
import Header from "../header/Header";
import { TabsTitle } from "../../../components";
import ecomun from "../../../assets/Images/about-us-section/about-us-images/ECOMUN.png";
import arn from "../../../assets/Images/about-us-section/about-us-images/ARN.png";
import cnr from "../../../assets/Images/about-us-section/about-us-images/CNR.jpg";
import onu from "../../../assets/Images/about-us-section/about-us-images/ONU-Colombia.jpg";
import About from "./About";
import History from "./History";
import Platform from "./Platform";

const tabs = [
  {
    value: 0,
    label: (
      <div className="ant-segmented-item-label" title="Quiénes somos">
        Quiénes somos
      </div>
    ),
  },
  {
    value: 1,
    label: (
      <div className="ant-segmented-item-label" title="Historia">
        Historia
      </div>
    ),
  },
  {
    value: 2,
    label: (
      <div className="ant-segmented-item-label" title="Plataforma">
        Plataforma
      </div>
    ),
  },
];

function AboutUs() {
  const [value, setValue] = useState<number>(0);
  return (
    <>
      <Header />
      <Row style={{ marginTop: "2.5rem" }}>
        <Col xs={24} md={2}></Col>
        <Col xs={24} md={20}>
          <TabsTitle options={tabs} value={value} setValue={setValue} />

          {value === 0 && <About />}
          {value === 1 && <History />}
          {value === 2 && <Platform />}

          <section className="logotype-section">
            <img className="logotype" src={ecomun} alt="Logo ECOMUN" />
            <img className="logotype" src={cnr} alt="Logo CNR FACR" />
            <img className="logotype" src={onu} alt="Logo Misión ONU" />
            <img className="logotype" src={arn} alt="Logo ARN" />
          </section>
        </Col>
        <Col xs={24} md={2}></Col>
      </Row>
    </>
  );
}

export default AboutUs;
