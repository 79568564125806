import "../../../styles/styles_old/styles_old.scss";
import "./home.scss";
import { Page } from "../../../components";
import banner from "../../../assets/Images/sarlaft-images/mean-banner.png";
import bannerSarlaft from "../../../assets/Images/sarlaft-images/banner-sarlaft.png";
import cashTransactionsIcon from "../../../assets/Images/sarlaft-images/cash-transactions-icon.png";
import cardTransactions from "../../../assets/Images/sarlaft-images/card-transactions.png";
import exonaratedClients from "../../../assets/Images/sarlaft-images/exonarated-clients.png";
import products from "../../../assets/Images/sarlaft-images/products.png";
import suspiciousActivities from "../../../assets/Images/sarlaft-images/suspicious-activities.png";
import suspiciousActivitiesPresence from "../../../assets/Images/sarlaft-images/suspicious-activities-presence.png";
import { Breadcrumb, Button, Col, Row } from "antd";
import { QuestionCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../constants";
import { useState } from "react";
import CashTransactionsModal from "./CashTransactionsModal";
import CalendarReportsModal from "./CalendarReportsModal";

function Home() {
  const navigate = useNavigate();
  const [cashTransactionsModalOpen, setCashTransactionsModalOpen] =
    useState<boolean>(false);
  const [calendarReportsModalOpen, setCalendarReportsModalOpen] =
    useState<boolean>(false);
  const [cashTransactions, setCashTransactions] = useState(1);
  const openDialog = (number: number) => {
    setCashTransactions(number);
    setCashTransactionsModalOpen(true);
  };
  return (
    <Page>
      <Row>
        <Col flex="auto">
          <div className="container-image-banner-page">
            <img
              className="image-banner-page"
              src={banner}
              alt="Banner Guía para generar reportes UIAF"
            />
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: 50 }}>
        <Col span={3}></Col>
        <Col span={18}>
          <div className="container-image-banner-page">
            <img
              className="image-banner-page"
              src={bannerSarlaft}
              alt="Qué es y para que sirve sarlaft imágen"
            />
          </div>
        </Col>
        <Col span={3}></Col>
      </Row>

      <div style={{ marginTop: 50, paddingLeft: "50px", paddingRight: "50px" }}>
        <Row>
          <Col xs={24} md={12} xl={12} xxl={12}>
            <div className="mt-4">
              <h1 className="associative-forms-list-title">Guía Sarlaft</h1>
            </div>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link
                      color="inherit"
                      to="/"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      Inicio
                    </Link>
                  ),
                },
                {
                  title: "Guía Sarlaft",
                },
              ]}
            />
          </Col>
          <Col
            className="container-helpButtons"
            xs={24}
            md={12}
            xl={12}
            xxl={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div
              className="col-md-auto button-sec1 p-0"
              style={{ marginRight: 10, display: "flex", alignItems: "center" }}
            >
              <div
                className="nav-dashboard-item-icon"
                style={{ marginRight: 7 }}
              >
                <QuestionCircleOutlined className="icon-button-home-help-sarlaft" />
              </div>
              <Button
                type="primary"
                className="helpButtons"
                onClick={() => navigate(routes.sarlaftHelpCenter)}
              >
                Centro de ayuda
              </Button>
            </div>

            <div
              className="col-md-auto button-sec1 pe-0"
              style={{ marginRight: 10, display: "flex", alignItems: "center" }}
            >
              <div
                className="nav-dashboard-item-icon"
                style={{ marginRight: 7 }}
              >
                <CalendarOutlined className="icon-button-home-help-sarlaft" />
              </div>
              <Button
                className="helpButtons"
                type="primary"
                onClick={() => setCalendarReportsModalOpen(true)}
              >
                Calendario de reportes
              </Button>
            </div>
          </Col>
        </Row>

        <div className="apply-css-home-sarlaft">
          <Row style={{ marginTop: 50 }}>
            <div className="col-12 mb-4">
              <h2 className="mb-0 fw-bold active">
                ¿Deseas realizar reporte objetivo?
              </h2>
            </div>
          </Row>
          <Row style={{ marginTop: 10, justifyContent: "space-between" }}>
            <Col md={11} xxl={5}>
              <button className="white-box two" onClick={() => openDialog(1)}>
                <div className="d-flex align-items-center flex-column p-4 box-sarlaft-new">
                  <div className="sarlaft-icon-cont">
                    <img
                      className="img-fluid"
                      src={cashTransactionsIcon}
                      alt=""
                    />
                  </div>
                  <h3 className="fw-bold mb-2">Transacciones en efectivo</h3>
                  <p className="mb-0">
                    Click aquí para hacer tus reportes de transacciones en
                    efectivo.
                  </p>
                </div>
              </button>
            </Col>

            <Col md={11} xxl={5}>
              <button className="white-box two" onClick={() => openDialog(2)}>
                <div className="d-flex align-items-center flex-column p-4 box-sarlaft-new">
                  <div className="sarlaft-icon-cont">
                    <img className="img-fluid" src={cardTransactions} alt="" />
                  </div>
                  <h3 className="fw-bold mb-2">
                    Transacciones en tarjeta de crédito y debito
                  </h3>
                  <p className="mb-0">
                    Click aquí para hacer tus reportes de transacciones en
                    tarjeta de crédito y debito.
                  </p>
                </div>
              </button>
            </Col>

            <Col md={11} xxl={5}>
              <button className="white-box two" onClick={() => openDialog(3)}>
                <div className="d-flex align-items-center flex-column p-4 box-sarlaft-new">
                  <div className="sarlaft-icon-cont">
                    <img className="img-fluid" src={exonaratedClients} alt="" />
                  </div>
                  <h3 className="fw-bold mb-2">Clientes exonerados</h3>
                  <p className="mb-0">
                    Click aquí para hacer tus reportes de clientes exonerados.
                  </p>
                </div>
              </button>
            </Col>

            <Col md={11} xxl={5}>
              <button className="white-box two" onClick={() => openDialog(4)}>
                <div className="d-flex align-items-center flex-column p-4 box-sarlaft-new">
                  <div className="sarlaft-icon-cont">
                    <img className="img-fluid" src={products} alt="" />
                  </div>
                  <h3 className="fw-bold mb-2">Productos</h3>
                  <p className="mb-0">
                    Click aquí para hacer tus reportes de productos.
                  </p>
                </div>
              </button>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <div className="col-12 mb-4">
              <h2 className="mb-0 fw-bold active">
                ¿Deseas realizar reporte subjetivo?
              </h2>
            </div>
          </Row>

          <Row style={{ justifyContent: "space-between" }}>
            <Col md={11} xxl={5}>
              <button className="white-box two" onClick={() => openDialog(5)}>
                <div className="d-flex align-items-center flex-column p-4 box-sarlaft-new">
                  <div className="sarlaft-icon-cont">
                    <img
                      className="img-fluid"
                      src={suspiciousActivities}
                      alt=""
                    />
                  </div>
                  <h3 className="fw-bold mb-2">
                    Ausencia de operaciones sospechosas
                  </h3>
                  <p className="mb-0">
                    Click aquí para hacer tus reportes de ausencia de
                    operaciones sospechosas.
                  </p>
                </div>
              </button>
            </Col>

            <Col md={11} xxl={5} onClick={() => openDialog(6)}>
              <button className="white-box two">
                <div className="d-flex align-items-center flex-column p-4 box-sarlaft-new">
                  <div className="sarlaft-icon-cont">
                    <img
                      className="img-fluid"
                      src={suspiciousActivitiesPresence}
                      alt=""
                    />
                  </div>
                  <h3 className="fw-bold mb-2">
                    Presencia de operaciones sospechosas
                  </h3>
                  <p className="mb-0">
                    Click aquí para hacer tus reportes de presencia de
                    operaciones sospechosas.
                  </p>
                </div>
              </button>
            </Col>
            <Col md={11} xxl={5}></Col>
            <Col md={11} xxl={5}></Col>
          </Row>
          {cashTransactionsModalOpen && (
            <CashTransactionsModal
              open={cashTransactionsModalOpen}
              handleClose={setCashTransactionsModalOpen}
              number={cashTransactions}
            />
          )}

          {calendarReportsModalOpen && (
            <CalendarReportsModal
              open={calendarReportsModalOpen}
              handleClose={setCalendarReportsModalOpen}
            />
          )}
        </div>
      </div>
    </Page>
  );
}

export default Home;
