import { useState } from "react";
import { Button, Col, Flex, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "../../../../components";
import { userStore } from "../../../../stores";
import { applicationModules } from "../../../../constants";
import { enableButtonSave, messageSweetalert } from "../../../../utilities";
import CreateEditSowingepochHarvestperiod from "./CreateEditSowingepochHarvestperiod";
import dayjs from "dayjs";
const now = dayjs();

const columns = [
  {
    title: "Fecha inicial",
    dataIndex: "initial_date",
    key: "initial_date",
  },
  {
    title: "Fecha final",
    dataIndex: "end_date",
    key: "end_date",
  },
];

function SowingepochHarvestperiodTable({
  handleChange,
  record,
  titleTable,
  typeTable,
}: any) {
  const { permissions } = userStore();
  const [selected, setSelected] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onChangeSelection = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelected(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };
  return (
    <Row className="row-form">
      <Col xs={24} md={24} xl={24}>
        {enableButtonSave(applicationModules, permissions) && (
          <Flex style={{ marginBottom: 10 }}>
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              style={{ marginRight: 10 }}
              disabled={selected.length > 0}
              onClick={() => openCreate(selected)}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              size="large"
              icon={<EditOutlined />}
              disabled={selected.length <= 0}
              onClick={() => openEdit(selected)}
            >
              Editar
            </Button>
          </Flex>
        )}

        <Table
          title={() => titleTable}
          dataSource={
            record[typeTable].map((x: any) => ({
              ...x,
              key: x.id,
            })) || []
          }
          columns={columns}
          bordered
          scroll={{ x: "1000" }}
          onChangeSelect={onChangeSelection}
        />
      </Col>

      {modalOpen && (
        <CreateEditSowingepochHarvestperiod
          open={modalOpen}
          handleClose={setModalOpen}
          dataAll={record[typeTable]}
          typeTable={typeTable}
          data={
            selected.length > 0
              ? { ...selected[0], new: false }
              : {
                  id: 0,
                  initial_date: now.format("YYYY-MM-DD"),
                  end_date: now.format("YYYY-MM-DD"),
                  product_id: record.id,
                  new: true,
                }
          }
          handleChange={handleChange}
        />
      )}
    </Row>
  );
}

export default SowingepochHarvestperiodTable;
