import { Avatar, Menu, MenuProps } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { userStore } from "../../stores";
import { useEffect } from "react";
import avantar from "../../assets/Images/login_avatar.png";
type MenuItem = Required<MenuProps>["items"][number];

const RightMenu = ({ mode }: any) => {
  const { logout, name, getUser } = userStore();

  useEffect(() => {
    name === "" && getUser();
  }, [name, getUser]);

  const items: MenuItem[] = [
    {
      key: "avatar",
      label: (
        <>
          <Avatar
            className="btn-avatar-user-img"
            src={avantar}
            size={50}
          />
          <span className="username">{name}</span>
        </>
      ),
      children: [
        {
          key: "logout",
          icon: <LogoutOutlined />,
          label: "Salir",
          onClick: () => logout(),
        },
      ],
    },
  ];
  return <Menu mode={mode} items={items} style={{ width: 100, height: 65 }} />;
};

export default RightMenu;
