import { Accordion, CircularProgressWithLabel } from "../../../../components";
import { severalStore } from "../../../../stores";
import Obligations from "./Obligations";
import GoodPractices from "./GoodPractices";

function Administrative({
  handleClickSave,
  errors,
  data,
  setValues,
  visible = true,
}: any) {
  const { subCategoryQuestionDiagnostic }: any = severalStore();
  return (
    <Accordion
      style={visible ? {} : { display: "none" }}
      size={"large"}
      defaultActiveKey={["14"]}
      items={[
        {
          key: "14",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 14)
            ?.name,
          children: <Obligations />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedAdministrative.completedObligations}
            />
          ),
        },
        {
          key: "15",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 15)
            ?.name,
          children: <GoodPractices />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedAdministrative.completedGoodPractices}
            />
          ),
        },
      ]}
    />
  );
}

export default Administrative;
