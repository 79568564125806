import { Menu, MenuProps } from "antd";
import { Link } from "react-router-dom";
type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    label: (
      <Link
        style={{ textDecoration: "none" }}
        className="mat-button navbar-btns"
        to="/mapa"
      >
        MAPA
      </Link>
    ),
    key: "home",
  },
  {
    label: (
      <Link
        style={{ textDecoration: "none" }}
        className="mat-button navbar-btns"
        to="/aboutus"
      >
        QUIÉNES SOMOS
      </Link>
    ),
    key: "aboutus",
  },
];

const LeftMenu = ({ mode }: any) => {
  return <Menu mode={mode} items={items} />;
};

export default LeftMenu;
