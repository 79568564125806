import { useState, useEffect } from "react";
import { Button, Col, Divider, Flex, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { severalStore, userStore } from "../../../../../stores";
import { Table } from "../../../../../components";
import { messageSweetalert, enableButtonSave } from "../../../../../utilities";
import { applicationModules } from "../../../../../constants";
import CreateEdit from "./CreateEdit";

const accountsColumns = [
  {
    title: "Entidad",
    dataIndex: "bank_id",
    key: "bank_id",
    width: 200,
    render: (text: any, record: any, index: any) => {
      return `${record.bank.name}`;
    },
  },
  {
    title: "Tipo",
    dataIndex: "bank_account_type_o",
    key: "bank_account_type_o",
    width: 200,
  },
  {
    title: "Número de cuenta",
    dataIndex: "account_number",
    key: "account_number",
    width: 200,
  },
];

const initialData = {
  id: 0,
  account_number: "",
  bank_account_type: "A",
  opening_date: "",
  bank_id: 0,
  associate_id: 0,
};

function Home({ form, setValue, handleClickSave }: any) {
  const { permissions } = userStore();
  const { banks, getSeveralData } = severalStore();
  const [accounts, setAccounts] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const loadSeveralData = async (banks: any[], getSeveralData: any) => {
    if (banks.length <= 0) {
      await getSeveralData();
    }
  };

  useEffect(() => {
    loadSeveralData(banks, getSeveralData);
  }, [banks, getSeveralData]);

  useEffect(() => {
    setAccounts([...form.bank_accounts.map((x: any) => ({ ...x, key: x.id }))]);
  }, [form.bank_accounts]);

  const onChangeSelectionBanksAccount = (
    newSelectedRowKeys: any,
    newSelectedRows: any
  ) => {
    setSelected(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const create = (data: any) => {
    const a = [...accounts, { ...data, new: false }];
    setValue("bank_accounts", a);
    setAccounts(a);
    setModalOpen(false);
  };

  const edit = (data: any) => {
    const a = accounts.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    setValue("bank_accounts", a);
    setAccounts(a);
    setSelected([data]);
    setModalOpen(false);
  };

  return (
    <>
      <Row className="row-form">
        <Col span={23}>
          {enableButtonSave(applicationModules, permissions) && (
            <Flex style={{ marginBottom: 10 }}>
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                disabled={selected.length > 0}
                onClick={() => openCreate(selected)}
              >
                Agregar
              </Button>
              <Button
                type="primary"
                size="large"
                icon={<EditOutlined />}
                disabled={selected.length <= 0}
                onClick={() => openEdit(selected)}
              >
                Editar
              </Button>
            </Flex>
          )}
          <Table
            dataSource={accounts || []}
            columns={accountsColumns}
            bordered
            scroll={{ x: "1000" }}
            onChangeSelect={onChangeSelectionBanksAccount}
          />
        </Col>
        {modalOpen && (
          <CreateEdit
            open={modalOpen}
            handleClose={setModalOpen}
            data={
              selected.length > 0
                ? selected[0]
                : {
                    ...initialData,
                    associative_form_id: form.id,
                    new: true,
                  }
            }
            create={create}
            edit={edit}
          />
        )}
      </Row>
      <Divider />
      <Row className="row-form">
        <Col xs={24} md={18} xl={18}></Col>
        <Col xs={24} md={6} xl={6}>
          {enableButtonSave(applicationModules, permissions) && (
            <Button block={true} type="primary" htmlType="submit">
              Guardar
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default Home;
