import "./styles.scss";

interface IProps {
  title: any;
  icon: any;
  content: any;
}

function SquareTitleWidget({ title, icon, content }: IProps) {
  return (
    <div className="square-title-widget-box">
      <div className="square-title-widget-box-title">
        {title}{" "}
        <img
          src={icon}
          alt="Imagen"
        />
      </div>
      <div className="square-title-widget-box-content">{content}</div>
    </div>
  );
}

export default SquareTitleWidget;
