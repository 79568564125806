import { useEffect } from "react";
import { Button, Col, DatePicker, Divider, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import { Select } from "../../../../components";
import { severalStore, userStore } from "../../../../stores";
import Branches from "./branches/Home";
import { enableButtonSave } from "../../../../utilities";
import { applicationModules } from "../../../../constants";

interface IProps {
  setValues: (name: string, value: any) => void;
  formSelected: any;
  formAnt: any;
}

function GeneralInformation({ setValues, formSelected, formAnt }: IProps) {
  const { permissions } = userStore();
  const {
    departments,
    getDepartments,
    municipalies,
    getMunicipalies,
    vinculations,
    vinculationsType,
    associationTypes,
    federationCharacter,
    groupEconomicActivity,
    economicActivity,
    integrationProcess,
    categoriesAssociationTypes,
    getSeveralData,
    federationsTypesIds,
    chambersCommerce,
    niifs,
  } = severalStore();

  const watchAssociationTypeId = Form.useWatch("association_type_id", formAnt);
  const watchDepartmentId = Form.useWatch("department_id", formAnt);
  const watchHasBranches = Form.useWatch("has_branches", formAnt);
  const watchVinculationTypeId = Form.useWatch("vinculation_type_id", formAnt);
  const watchDepartmentIdEconomicActivity = Form.useWatch(
    "department_id_economic_activity",
    formAnt
  );
  const watchIntegrationProcess = Form.useWatch("integration_process", formAnt);
  // const watchEconomicActivity = Form.useWatch("economic_activity", formAnt);

  /*const loadSeveralData = async (
    departments: any[],
    getDepartments: any,
    municipalies: any[],
    getMunicipalies: any,
    vinculations: any[],
    vinculationsType: any[],
    associationTypes: any[],
    federationCharacter: any[],
    groupEconomicActivity: any[],
    economicActivity: any[],
    integrationProcess: any[],
    categoriesAssociationTypes: any[],
    chambersCommerce: any[],
    niifs: any[],
    getSeveralData: any
  ) => {
    departments.length <= 0 && (await getDepartments());
    municipalies.length <= 0 && (await getMunicipalies());

    if (
      vinculations.length <= 0 ||
      vinculationsType.length <= 0 ||
      associationTypes.length <= 0 ||
      federationCharacter.length <= 0 ||
      groupEconomicActivity.length <= 0 ||
      economicActivity.length <= 0 ||
      integrationProcess.length <= 0 ||
      categoriesAssociationTypes.length <= 0 ||
      chambersCommerce.length <= 0 ||
      niifs.length <= 0
    ) {
      await getSeveralData();
    }
  };*/

  useEffect(() => {
    /*loadSeveralData(
      departments,
      getDepartments,
      municipalies,
      getMunicipalies,
      vinculations,
      vinculationsType,
      associationTypes,
      federationCharacter,
      groupEconomicActivity,
      economicActivity,
      integrationProcess,
      categoriesAssociationTypes,
      chambersCommerce,
      niifs,
      getSeveralData
    );*/
    departments.length <= 0 && getDepartments();
    municipalies.length <= 0 && getMunicipalies();
    getSeveralData();
  }, [
    /*departments,
    getDepartments,
    municipalies,
    getMunicipalies,
    vinculations,
    vinculationsType,
    associationTypes,
    federationCharacter,
    groupEconomicActivity,
    economicActivity,
    integrationProcess,
    categoriesAssociationTypes,
    chambersCommerce,
    niifs,*/
    getSeveralData,
    departments.length,
    municipalies.length,
    getDepartments,
    getMunicipalies,
  ]);

  const orderAssociationTypes = (
    categoriesAssociationTypes: any,
    associationTypes: any
  ) => {
    const items: any[] = [];

    categoriesAssociationTypes.map((x: any) => {
      items.push({
        label: x.name,
        title: x.name,
        options: associationTypes
          .filter((z: any) => z.category === String(x.id))
          .map((y: any) => {
            return {
              label: y.name,
              value: y.id,
            };
          }),
      });
      return null;
    });

    items.push({
      label: "Otras",
      title: "Otras",
      options: associationTypes
        .filter((z: any) => z.category === "0")
        .map((y: any) => {
          return {
            label: y.name,
            value: y.id,
          };
        }),
    });

    return items;
  };

  const orderEconomicActivity = (
    groupEconomicActivity: any,
    economicActivity: any
  ) => {
    const items: any[] = [];

    groupEconomicActivity.map((x: any) => {
      items.push({
        label: x.name,
        title: x.name,
        options: economicActivity
          .filter((z: any) => String(z.id_group) === String(x.id))
          .map((y: any) => {
            return {
              label: y.name,
              value: y.id,
            };
          }),
      });
      return null;
    });

    return items;
  };

  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Row className="row-form">
            <Col xs={24} md={12} xl={12} style={{ marginRight: 5 }}>
              <Form.Item name="nit" label="NIT">
                <Input placeholder="Nit" disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={8}>
              <Form.Item
                name="dv"
                label="DV"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder="DV" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            name="name"
            label="Nombre"
            rules={[
              {
                required: true,
                message: "Ingrese nombre",
              },
            ]}
          >
            <Input
              placeholder="Nombre"
              style={{ textTransform: "uppercase" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            name="initials"
            label="Sigla"
            rules={[
              {
                required: true,
                message: "Ingrese sigla",
              },
            ]}
          >
            <Input placeholder="Sigla" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Fecha Constitución"
            tooltip={<p>Fecha de la asamble de constitución</p>}
          >
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              value={dayjs(formSelected?.created_date)}
              onChange={(date: any, dateString: any) =>
                setValues("created_date", dateString)
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Fecha de Registro Matrícula Mercantil">
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              value={dayjs(formSelected?.registration_date)}
              onChange={(date: any, dateString: any) =>
                setValues("registration_date", dateString)
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            name="commercial_registration_id"
            label="Matrícula Mercantil"
            rules={[
              {
                required: true,
                message: "Ingrese Matrícula Mercantil",
              },
            ]}
          >
            <Input placeholder="Matrícula Mercantil" type="number" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Registro Cámara de Comercio"
            name={"chamber_commerce_id"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={chambersCommerce.map((x: any) => ({
                value: x.id,
                label: x.abbreviated_name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Fecha de Última Renovación"
            tooltip={<p>Fecha de última renovación de cámara de comercio</p>}
          >
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              value={dayjs(formSelected?.last_renovation_date)}
              onChange={(date: any, dateString: any) =>
                setValues("last_renovation_date", dateString)
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Grupo NIIF"
            name={"niif_id"}
            tooltip={
              <ul>
                {niifs.map((x: any) => (
                  <li key={x.id}>
                    <p>
                      {x.group} - {x.name}
                    </p>
                    <p>{x.description}</p>
                  </li>
                ))}
              </ul>
            }
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              option0={true}
              options={niifs.map((x: any) => ({
                value: x.id,
                label: `${x.group} - ${x.name}`,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Pertenece al Régimen Tributario Especial"
            name={"special_tributary_regime"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={[
                { value: "S", label: "Sí" },
                { value: "N", label: "No" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Tipo de Organización" name={"association_type_id"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={orderAssociationTypes(
                categoriesAssociationTypes,
                associationTypes
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          {federationsTypesIds.includes(
            /*@ts-ignore*/
            String(watchAssociationTypeId || "0")
          ) && (
            <Form.Item
              label="Carácter de la Federación "
              name={"federation_character"}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Please select"
                option0={false}
                options={federationCharacter.map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="section-form-title">Domicilio Principal</p>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Área" name={"coverage"}>
            <Select
              style={{ width: "100%" }}
              option0={false}
              placeholder="Please select"
              options={[
                { value: "R", label: "Rural" },
                { value: "U", label: "Urbano" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="address" label="Dirección">
            <Input placeholder="Dirección" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Departamento" name={"department_id"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={departments.map((x: any) => ({
                value: x.code,
                label: x.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Municipio" name={"municipality_id"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={municipalies
                .filter(
                  (x: any) =>
                    String(x.department_id) === String(watchDepartmentId)
                )
                .map((x: any) => ({
                  value: x.code,
                  label: x.name,
                }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="village" label="Vereda">
            <Input placeholder="Vereda" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Cuenta con Sucursales?" name={"has_branches"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              option0={false}
              options={[
                { value: 1, label: "Sí" },
                { value: 0, label: "No" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      {String(watchHasBranches || "0") !== "0" && (
        <>
          <Row>
            <Col>
              <p className="section-form-title">Sucursales</p>
            </Col>
          </Row>
          <Branches form={formSelected} setValue={setValues} />
        </>
      )}

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Vinculación Territorial"
            name={"vinculation_type_id"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              option0={true}
              options={vinculationsType.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Nombre de la Vinculación Territorial"
            name={"vinculation_id"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={vinculations
                .filter(
                  (y: any) =>
                    String(y.vinculation_type_id) ===
                    String(watchVinculationTypeId)
                )
                .map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="¿Cuál es la Actividad Económica Principal de la Forma Asociativa?"
            name={"economic_activity"}
            tooltip={
              <p>
                Se refiere a la actividad económica principal por la cual la
                organización percibe sus mayores ingresos económicos
              </p>
            }
          >
            <Select
              option0={false}
              style={{ width: "100%" }}
              placeholder="Please select"
              options={orderEconomicActivity(
                groupEconomicActivity,
                economicActivity
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          {/* {String(watchEconomicActivity) === "43" && (
            <Form.Item name="economic_activity_other" label="¿Cuál?">
              <Input />
            </Form.Item>
          )} */}
          <Form.Item
            label="¿Cuál es la Actividad Económica Secundaria de la Forma Asociativa?"
            name={"economic_activity_2"}
            tooltip={
              <p>
                Se refiere a la actividad económica secundaria por la cual la
                organización percibe sus mayores ingresos económicos
              </p>
            }
          >
            <Select
              option0={false}
              style={{ width: "100%" }}
              placeholder="Please select"
              options={orderEconomicActivity(
                groupEconomicActivity,
                economicActivity
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="¿Cuál es la Actividad Económica Terciaria de la Forma Asociativa?"
            name={"economic_activity_3"}
            tooltip={
              <p>
                Se refiere a la actividad económica terciaria por la cual la
                organización percibe sus mayores ingresos económicos
              </p>
            }
          >
            <Select
              option0={false}
              style={{ width: "100%" }}
              placeholder="Please select"
              options={orderEconomicActivity(
                groupEconomicActivity,
                economicActivity
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="section-form-title">
            Ubicación de la actividad económica principal
          </p>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Área" name={"area_economic_activity"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={[
                { value: "R", label: "Rural" },
                { value: "U", label: "Urbano" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="address_economic_activity" label="Dirección">
            <Input placeholder="Dirección" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Departamento"
            name={"department_id_economic_activity"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={departments.map((x: any) => ({
                value: x.code,
                label: x.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="Municipio"
            name={"municipality_id_economic_activity"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={municipalies
                .filter(
                  (x: any) =>
                    String(x.department_id) ===
                    String(watchDepartmentIdEconomicActivity)
                )
                .map((x: any) => ({
                  value: x.code,
                  label: x.name,
                }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="village_economic_activity" label="Vereda">
            <Input placeholder="Vereda" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="latitude_economic_activity" label="Latitud">
            <Input placeholder="Latitud" type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="longitude_economic_activity" label="Longitud">
            <Input placeholder="Longitud" type="number" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label="¿Participa de Algún Proceso de Integración?"
            name={"integration_process"}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              option0={false}
              options={[
                { value: 0, label: "No" },
                { value: 1, label: "Sí" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          {String(watchIntegrationProcess) === "1" && (
            <Form.Item
              label="¿Cuál o Cuales?"
              name={"integration_process_which"}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select"
                option0={false}
                options={integrationProcess.map((x: any) => {
                  return {
                    value: x.id,
                    label: x.name,
                  };
                })}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Divider />
      <Row className="row-form">
        <Col xs={24} md={18} xl={18}></Col>
        <Col xs={24} md={6} xl={6}>
          {enableButtonSave(applicationModules, permissions) && (
            <Button block={true} type="primary" htmlType="submit">
              Guardar
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default GeneralInformation;
