import { useState } from "react";
import { Form, Row, Modal, Col, Input, Alert } from "antd";
import uuid from "react-uuid";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.item === "") errors.item = "Ingrese Item";
  if (data.unit === "") errors.unit = "Ingrese Unidad";
  if (data.quantity === "") errors.quantity = "Ingrese Cantidad";
  if (data.cost === "") errors.cost = "Ingrese Valor";
  return errors;
};

function CreateEditCostMatriz({
  open,
  handleClose,
  data,
  dataAll,
  handleChange,
}: any) {
  const [record, setRecord] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  const handleChangeLocal = (e: any) => {
    const { name, value } = e.target;
    setRecord((prev: any) => ({ ...prev, [name]: value }));
  };

  const create = (data: any) => {
    const a = [...dataAll, { ...data, new: false }];
    handleChange({ target: { name: "production_cost_matrices", value: a } });
    handleClose(false);
  };

  const edit = (data: any) => {
    const a = dataAll.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    handleChange({ target: { name: "production_cost_matrices", value: a } });
    handleClose(false);
  };

  const handleClickSave = () => {
    const errors = validateData(record);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (record.new) {
      const uid = uuid();
      const newPresentation = {
        ...record,
      };
      create({ ...newPresentation, id: uid, key: uid });
      return;
    }

    edit({
      ...record,
      new: false,
    });
    setErrors({});
  };

  return (
    <Modal
      title={`${record.new ? "Agregar" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${record.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"800px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Item">
              <Input
                name="item"
                value={record.item}
                onChange={handleChangeLocal}
              />
              {errors?.item && (
                <Alert message={errors.item} type="error" showIcon />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Unidad">
              <Input
                name="unit"
                value={record.unit}
                onChange={handleChangeLocal}
                type="number"
              />
              {errors?.unit && (
                <Alert message={errors.unit} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Cantidad">
              <Input
                name="quantity"
                value={record.quantity}
                onChange={handleChangeLocal}
                type="number"
              />
              {errors?.quantity && (
                <Alert message={errors.quantity} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Valor">
              <Input
                name="cost"
                value={record.cost}
                onChange={handleChangeLocal}
                type="number"
              />
              {errors?.cost && (
                <Alert message={errors.cost} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEditCostMatriz;
