import { Col, Form, Row } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function Processes() {
  const {
    diagnosticQuestions,
    questionsOptionsDiagnostic,
    formAntAnswers,
  }: any = severalStore();
  const watchQ38 = Form.useWatch("q38", formAntAnswers);

  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(35, diagnosticQuestions).question}
            name={"q35"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 35)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(36, diagnosticQuestions).question}
            name={"q36"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 36)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(37, diagnosticQuestions).question}
            name={"q37"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 37)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(38, diagnosticQuestions).question}
            name={"q38"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 38)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          {String(watchQ38) === "2" && (
            <Form.Item
              label={getDiagnosticQuestion(39, diagnosticQuestions).question}
              name={"q39"}
            >
              <Select
                placeholder="Seleccionar"
                options={questionsOptionsDiagnostic
                  .filter((x: any) => x.question_id === 39)
                  .map((y: any) => ({ value: y.id, label: y.name }))}
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(40, diagnosticQuestions).question}
            name={"q40"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 40)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(41, diagnosticQuestions).question}
            name={"q41"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 41)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(42, diagnosticQuestions).question}
            name={"q42"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 42)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(43, diagnosticQuestions).question}
            name={"q43"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 43)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(44, diagnosticQuestions).question}
            name={"q44"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 44)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(45, diagnosticQuestions).question}
            name={"q45"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 45)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(46, diagnosticQuestions).question}
            name={"q46"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 46)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>
    </>
  );
}

export default Processes;
