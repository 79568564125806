import { Segmented } from "antd";

function Home({ value, setValue, options = [], block = true }: any) {
  return (
    <Segmented
      options={options}
      value={value}
      onChange={setValue}
      size="large"
      block={block}
    />
  );
}

export default Home;
