import { useState } from "react";
import { Button, Col, Flex, Form, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "../../../../components";
import { userStore } from "../../../../stores";
import { applicationModules } from "../../../../constants";
import { enableButtonSave, messageSweetalert } from "../../../../utilities";
import CreateEditExpenditureMatriz from "./CreateEditExpenditureMatriz";

const columns = [
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
  },
  {
    title: "Valor",
    dataIndex: "cost",
    key: "cost",
  },
];

function ExpenditureMatriz({ handleChange, record }: any) {
  const { permissions } = userStore();
  const [selected, setSelected] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onChangeSelection = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelected(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          {enableButtonSave(applicationModules, permissions) && (
            <Flex style={{ marginBottom: 10 }}>
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                disabled={selected.length > 0}
                onClick={() => openCreate(selected)}
              >
                Agregar
              </Button>
              <Button
                type="primary"
                size="large"
                icon={<EditOutlined />}
                disabled={selected.length <= 0}
                onClick={() => openEdit(selected)}
              >
                Editar
              </Button>
            </Flex>
          )}

          <Table
            title={() => "Matriz de gastos por lote"}
            dataSource={
              record.expenditure_matrices.map((x: any) => ({
                ...x,
                key: x.id,
              })) || []
            }
            columns={columns}
            bordered
            scroll={{ x: "1000" }}
            onChangeSelect={onChangeSelection}
          />
          <Row>
            <Col
              xs={24}
              md={24}
              xl={24}
              xxl={24}
              style={{ textAlign: "center" }}
            >
              <span>Total gastos: {record.total_expenditure}</span>
            </Col>
          </Row>
        </Col>

        {modalOpen && (
          <CreateEditExpenditureMatriz
            open={modalOpen}
            handleClose={setModalOpen}
            dataAll={record.expenditure_matrices}
            data={
              selected.length > 0
                ? { ...selected[0], new: false }
                : {
                    id: 0,
                    item: "",
                    cost: "",
                    product_type_id: 1,
                    product_id: record.id,
                    new: true,
                  }
            }
            handleChange={handleChange}
          />
        )}
      </Row>
    </Form>
  );
}

export default ExpenditureMatriz;
