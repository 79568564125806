import { useState } from "react";
import { Modal } from "antd";
import { Accordion } from "../../../../components";
import { userStore } from "../../../../stores";
import { enableButtonSave } from "../../../../utilities";
import { applicationModules } from "../../../../constants";
import Characterization from "./Characterization";
import ProductionVolume from "./ProductionVolume";
import PeriodicityBatchProduction from "./PeriodicityBatchProduction";
import RawMaterials from "./RawMaterials";
import Inputs from "./Inputs";
import Labour from "./Labour";
import Machinery from "./Machinery";
import Costing from "./Costing";

function CreateUpdate({ open, handleClose, data, saveRecord, errors }: any) {
  const { permissions } = userStore();
  const [record, setRecord] = useState({ ...data });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setRecord((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleClickSave = () => {
    saveRecord(record);
  };

  return (
    <Modal
      title={`${data.new ? "Crear" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={"Guardar"}
      onCancel={() => handleClose(false)}
      width={"800px"}
      cancelText="Cerrar"
      okButtonProps={{
        style: {
          display: enableButtonSave(
            applicationModules,
            permissions,
            "economicActivity"
          )
            ? ""
            : "none",
        },
      }}
    >
      <Accordion
        size={"large"}
        items={[
          {
            key: "1",
            label: "Caracterización de producto",
            children: (
              <Characterization
                handleChange={handleChange}
                record={record}
                errors={errors}
              />
            ),
          },
          {
            key: "2",
            label: "Volumen de producción",
            children: (
              <ProductionVolume handleChange={handleChange} record={record} />
            ),
          },
          {
            key: "3",
            label: "Periodicidad de producción",
            children: (
              <PeriodicityBatchProduction
                handleChange={handleChange}
                record={record}
              />
            ),
          },
          {
            key: "4",
            label: "Materias primas",
            children: (
              <RawMaterials handleChange={handleChange} record={record} />
            ),
          },
          {
            key: "5",
            label: "Insumos",
            children: <Inputs handleChange={handleChange} record={record} />,
          },
          {
            key: "6",
            label: "Mano de obra",
            children: <Labour handleChange={handleChange} record={record} />,
          },
          {
            key: "7",
            label: "Maquinaria y equipos",
            children: <Machinery handleChange={handleChange} record={record} />,
          },
          {
            key: "8",
            label: "Costeo",
            children: <Costing handleChange={handleChange} record={record} />,
          },
        ]}
      />
    </Modal>
  );
}

export default CreateUpdate;
