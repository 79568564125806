import { useState } from "react";
import { Button, Col, Flex, Form, Input, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "../../../../components";
import { userStore } from "../../../../stores";
import { applicationModules } from "../../../../constants";
import { enableButtonSave, messageSweetalert } from "../../../../utilities";
import CreateEditRawMaterials from "./CreateEditRawMaterials";

const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
  },
];

function RawMaterials({ handleChange, record }: any) {
  const { permissions } = userStore();
  const [selected, setSelected] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onChangeSelection = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelected(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Qué materias primas requiere para la producción del cultivo por cada ciclo?">
            <Input
              name="raw_materials_crop_cycle"
              value={record.raw_materials_crop_cycle}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          {enableButtonSave(applicationModules, permissions) && (
            <Flex style={{ marginBottom: 10 }}>
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                disabled={selected.length > 0}
                onClick={() => openCreate(selected)}
              >
                Agregar materia prima
              </Button>
              <Button
                type="primary"
                size="large"
                icon={<EditOutlined />}
                disabled={selected.length <= 0}
                onClick={() => openEdit(selected)}
              >
                Editar materia prima
              </Button>
            </Flex>
          )}

          <Table
            title={() =>
              "¿Qué materias primas requiere para la producción del cultivo?"
            }
            dataSource={
              record.raw_materials.map((x: any) => ({ ...x, key: x.id })) || []
            }
            columns={columns}
            bordered
            scroll={{ x: "1000" }}
            onChangeSelect={onChangeSelection}
          />
        </Col>

        {modalOpen && (
          <CreateEditRawMaterials
            open={modalOpen}
            handleClose={setModalOpen}
            dataAll={record.raw_materials}
            data={
              selected.length > 0
                ? { ...selected[0], new: false }
                : {
                    id: 0,
                    name: "",
                    product_type_id: 1,
                    product_id: record.id,
                    new: true,
                  }
            }
            handleChange={handleChange}
          />
        )}
      </Row>
    </Form>
  );
}

export default RawMaterials;
