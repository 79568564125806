import { Col, Row } from "antd";
import { SquareTitleWidget } from "../../../components";
import idea from "../../../assets/Images/about-us-section/about-us-icons/idea.png";
import lupa from "../../../assets/Images/about-us-section/about-us-icons/lupa.png";
import settings from "../../../assets/Images/about-us-section/about-us-icons/settings.png";
import reloj from "../../../assets/Images/about-us-section/about-us-icons/reloj.png";
import objetivo from "../../../assets/Images/about-us-section/about-us-icons/objetivo.png";
import grupo from "../../../assets/Images/about-us-section/about-us-icons/grupo.png";
import location from "../../../assets/Images/about-us-section/about-us-icons/location.png";

function Platform() {
  return (
    <>
      <Row>
        <Col md={24} lg={24} xs={24}>
          <h2 style={{ textAlign: "center" }}>
            PLATAFORMA DE GESTIÓN DE INFORMACIÓN - REDCOMUN{" "}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={8} className="col-center">
          <SquareTitleWidget
            title="Qué es?"
            icon={idea}
            content={
              <span>
                Esquema articulado en red para <strong>fortalecimiento</strong>{" "}
                en lo <strong>organizativo</strong>y en lo{" "}
                <strong>productivo</strong> basado en gestión de información
              </span>
            }
          />
        </Col>
        <Col xs={24} md={8} className="col-center">
          <SquareTitleWidget
            title="Por qué?"
            icon={lupa}
            content={
              <>
                <span>
                  &#10004; Requerimientos en gestión organizativa y productiva
                  identificados.
                </span>
                <br />
                <span>&#10004; Ordenar oferta</span>
                <br />
                <span>&#10004; Fortalecimiento</span>
              </>
            }
          />
        </Col>
        <Col xs={24} md={8} className="col-center">
          <SquareTitleWidget
            title="Cómo?"
            icon={settings}
            content={
              <>
                <span>&#10004; Trabajo colaborativo</span>
                <br />
                <span>&#10004; Metodología</span>
                <br />
                <span>&#10004; Visión de procesos</span>
                <br />
                <span>&#10004; Herramientas</span>
              </>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={8} className="col-center">
          <SquareTitleWidget
            title="Cuándo?"
            icon={reloj}
            content={
              <>
                <span>&#10004; Corto P - Mejoramiento</span>
                <br />
                <span>&#10004; Mediano P - Automatización</span>
                <br />
                <span>&#10004; Largo P - Interoperabilidad</span>
              </>
            }
          />
        </Col>
        <Col xs={24} md={8} className="col-center">
          <SquareTitleWidget
            title="Para qué?"
            icon={objetivo}
            content={
              <>
                <span>
                  &#10004; Desarrollo de <strong>capacidades</strong>
                  {"       "}
                </span>
                <br />
                <span>&#10004; Compartir información</span>
                <br />
                <span>
                  &#10004; Construcción de <strong>saberes</strong>
                </span>
              </>
            }
          />
        </Col>
        <Col xs={24} md={8} className="col-center">
          <SquareTitleWidget
            title="Quién?"
            icon={grupo}
            content={
              <>
                <span>&#10004; ECOMUN</span>
                <br />
                <span>&#10004; Formas asociativas</span>
                <br />
                <span>&#10004; ARN</span>
                <br />
                <span>&#10004; Misión ONU</span>
              </>
            }
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={8} className="col-center"></Col>
        <Col xs={24} md={8} className="col-center">
          <SquareTitleWidget
            title="Dónde?"
            icon={location}
            content={
              <>
                <span>Redes articuladas</span>
                <br />
                <span>&#10004; En el territorio nacional</span>
                <br />
                <span>&#10004; En las regiones</span>
                <br />
                <span>&#10004; En el nivel nacional</span>
              </>
            }
          />
        </Col>
        <Col xs={24} md={8} className="col-center"></Col>
      </Row>
    </>
  );
}

export default Platform;
