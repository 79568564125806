import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";

import App from "./App";

import locale from "antd/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#3dd6d6",
          colorInfo: "#3dd6d6",
        },
        components: {
          Segmented: {
            itemSelectedBg: "#3dd6d6",
            itemSelectedColor: "#ffffff",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);
