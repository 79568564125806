import { Col, Form, Row } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function Integration() {
  const {
    diagnosticQuestions,
    questionsOptionsDiagnostic,
    formAntAnswers,
  }: any = severalStore();
  const watchQ54 = Form.useWatch("q54", formAntAnswers);
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(54, diagnosticQuestions).question}
            name={"q54"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 54)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          {String(watchQ54) === "2" && (
            <Form.Item
              label={getDiagnosticQuestion(55, diagnosticQuestions).question}
              name={"q55"}
            >
              <Select
                mode="multiple"
                placeholder="Seleccionar"
                options={questionsOptionsDiagnostic
                  .filter((x: any) => x.question_id === 55)
                  .map((y: any) => ({ value: String(y.id), label: y.name }))}
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(56, diagnosticQuestions).question}
            name={"q56"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 56)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(57, diagnosticQuestions).question}
            name={"q57"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 57)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default Integration;
