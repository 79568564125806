import { Col, Form, Input, Row } from "antd";
import SalesPrices from "./SalesPrices";

const { TextArea } = Input;

function Costing({ handleChange, record }: any) {
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Cuál es el costo producción de un lote/ animal o ciclo de producción?"
            }
          >
            <Input
              placeholder=""
              name="production_cost_cycle"
              onChange={handleChange}
              value={record.production_cost_cycle}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={"¿Cual es el gasto por cada lote o ciclo producido?"}
          >
            <Input
              placeholder=""
              name="expenditure_cycle"
              onChange={handleChange}
              value={record.expenditure_cycle}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Cuál es el costo de producción final de cada lote o ciclo de producción?"
            }
          >
            <Input
              placeholder=""
              name="production_cost_final"
              onChange={handleChange}
              value={record.production_cost_final}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <SalesPrices
            handleChange={handleChange}
            record={record}
            titleTable={
              "Precio de venta actual del lote/animal o ciclo de producción"
            }
            typeTable={"sales_price"}
          />
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <Form.Item label="¿Cómo establece el precio de venta del lote/animal o ciclo de producción?">
            <TextArea
              name="how_you_set_selling_price"
              value={record.how_you_set_selling_price}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Costing;
