import { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "antd";
import { CircularProgressWithLabel, TabsTitle } from "../../../components";
import { severalStore, userStore } from "../../../stores";
import General from "./General";
import Organizational from "./organizational/Organizational";
import EconomicActivity from "./economicActivity/EconomicActivity";
import Financial from "./financial/Financial";
import Administrative from "./administrative/Administrative";
import Accountant from "./accountant/Accountant";
import { getDiagnosticsAnswers } from "../../../servicesHTTP";
import { applicationModules } from "../../../constants";
import { enableButtonSave } from "../../../utilities";

const initialData = {
  id: 0,
  diagnostic_id: 0,
  q1: null,
  q2: null,
  q3: null,
  q4: null,
  q5: [],
  q6: null,
  q7: null,
  q8: null,
  q9: null,
  q10: null,
  q11: null,
  q12: null,
  q13: null,
  q14: null,
  q15: null,
  q16: null,
  q17: null,
  q18: null,
  q19: null,
  q20: null,
  q21: null,
  q22: null,
  q23: null,
  q24: null,
  q25: null,
  q26: null,
  q27: null,
  q28: null,
  q29: null,
  q30: null,
  q31: null,
  q32: null,
  q33: null,
  q34: null,
  q35: null,
  q36: null,
  q37: null,
  q38: null,
  q39: null,
  q40: null,
  q41: null,
  q42: null,
  q43: null,
  q44: null,
  q45: null,
  q46: null,
  q47: null,
  q48: [],
  q49: null,
  q50: null,
  q51: null,
  q52: null,
  q53: null,
  q54: null,
  q55: [],
  q56: null,
  q57: null,
  q58: null,
  q59: null,
  q60: null,
  q61: null,
  q62: null,
  q63: null,
  q64: null,
  q65: null,
  q66: null,
  q67: null,
  q68: null,
  q69: null,
  q70: null,
  q71: null,
  q72: null,
  q73: null,
  q74: null,
  q75: null,
  q76: null,
  q77: null,
  q78: null,
  q79: null,
  q80: null,
  q81: null,
  q82: null,
  q83: null,
  q84: null,
  q85: null,
  q86: null,
  q87: null,
  q88: null,
  q89: null,
  q90: null,
  q91: null,
  q92: null,
  q93: null,
  q94: null,
  q95: null,
  q96: null,
  q97: null,
  q98: null,
  q99: null,
  q100: null,
  q101: null,
  q102: null,
  q103: null,
  q104: null,
  q105: null,
  q106: null,
  q107: null,
  create_date: null,
  update_date: null,
  user: 0,
  key: 1,
  completedOrganizational: {
    completedOrganizationalStructure: 0,
    completedFunctioningOrganizationalStructure: 0,
    completedParticipation: 0,
    completedGenderEquity: 0,
    completedConflictResolution: 0,
    completedRolesAndDecisions: 0,
    total: 0,
  },
  completedEconomicActivity: {
    completedGeneral: 0,
    completedProductionResources: 0,
    completedProcesses: 0,
    completedSustainability: 0,
    completedEnvironmentalGoodPractices: 0,
    completedIntegration: 0,
    total: 0,
  },
  completedFinancial: {
    completedFinancialAnalysis: 0,
    total: 0,
  },
  completedAdministrative: {
    completedObligations: 0,
    completedGoodPractices: 0,
    total: 0,
  },
  completedAccountant: {
    completedObligations: 0,
    completedGoodPractices: 0,
    total: 0,
  },
};

function Answers({ open, handleClose, data, saveRecord }: any) {
  const {
    categoryQuestionDiagnostic,
    update: updateStore,
    formSelected,
  } = severalStore();
  const { permissions } = userStore();
  const [form] = Form.useForm();
  const [errors] = useState<any>({});
  const [value, setValue] = useState<number>(1);
  const [answers, setAnswers] = useState<any>({
    ...initialData,
    diagnostic_id: data.id,
  });
  
  useEffect(() => {
    (async () => {
      const result = await getDiagnosticsAnswers(data.id);
      if (result.status === "ok") {
        result.data.length > 0 &&
          setAnswers({
            ...result.data[0],
            q27: String(formSelected.economic_activity),
            q28: String(formSelected.economic_activity_2),
            q107: String(formSelected.economic_activity_3),
          });
      }
    })();
  }, [
    data,
    formSelected.economic_activity,
    formSelected.economic_activity_2,
    formSelected.economic_activity_3,
  ]);

  useEffect(() => {
    //const values = form.getFieldsValue();
    form.setFieldsValue({ ...answers });
  }, [form, answers]);

  useEffect(() => {
    updateStore({ formAntAnswers: form });
  }, [form, updateStore]);

  const setValues = (name: string, value: any) => {
    setAnswers((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleClickSave = () => {
    const values = form.getFieldsValue();
    const a = { ...answers, ...values };
    const newData = {
      ...a,
      q58: a.q58 ? a.q58.replace(",", ".") : null,
      q59: a.q59 ? a.q59.replace(",", ".") : null,
      q60: a.q60 ? a.q60.replace(",", ".") : null,
      q61: a.q61 ? a.q61.replace(",", ".") : null,
      q62: a.q62 ? a.q62.replace(",", ".") : null,
      q63: a.q63 ? a.q63.replace(",", ".") : null,
      q64: a.q64 ? a.q64.replace(",", ".") : null,
      q65: a.q65 ? a.q65.replace(",", ".") : null,
      q66: a.q66 ? a.q66.replace(",", ".") : null,
      q67: a.q67 ? a.q67.replace(",", ".") : null,
      q68: a.q68 ? a.q68.replace(",", ".") : null,
      q69: a.q69 ? a.q69.replace(",", ".") : null,
      q5: a.q5 ? a.q5.join() : null,
      q48: a.q48 ? a.q48.join() : null,
      q55: a.q55 ? a.q55.join() : null,
    };
    saveRecord(newData);
  };

  const getCircularProgress = (answers: any, section: any) => {
    let value = 0;
    switch (section) {
      case "1":
        return null;
      case "2":
        value = answers.completedOrganizational.total;
        break;
      case "3":
        value = answers.completedEconomicActivity.total;
        break;
      case "4":
        value = answers.completedFinancial.total;
        break;
      case "5":
        value = answers.completedAdministrative.total;
        break;
      case "6":
        value = answers.completedAccountant.total;
        break;
      default:
        break;
    }

    return <CircularProgressWithLabel size={40} value={value} />;
  };

  return (
    <Modal
      title={"Diagnóstico"}
      centered
      open={open}
      onOk={handleClickSave}
      okText={"Guardar"}
      onCancel={() => handleClose(false)}
      width={"1500px"}
      cancelText="Cerrar"
      okButtonProps={{
        style: {
          display: enableButtonSave(
            applicationModules,
            permissions,
            "diagnostic"
          )
            ? ""
            : "none",
        },
      }}
    >
      <Form
        form={form}
        name="normal_login"
        initialValues={answers}
        //onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
        autoComplete="off"
        size="large"
      >
        <Row style={{ marginTop: 30 }}>
          <Col span={24}>
            <TabsTitle
              options={categoryQuestionDiagnostic.map((x: any) => ({
                value: x.id,
                label: (
                  <div className="ant-segmented-item-label" title={x.name}>
                    {x.name}
                    {getCircularProgress(answers, String(x.id))}
                  </div>
                ),
              }))}
              value={value}
              setValue={setValue}
            />
            <div style={{ marginTop: 50 }}>
              {value === 1 && <General />}

              <Organizational
                handleClickSave={() => {}}
                errors={errors}
                data={answers}
                setValues={setValues}
                visible={value === 2}
              />

              <EconomicActivity
                handleClickSave={() => {}}
                errors={errors}
                data={answers}
                setValues={setValues}
                visible={value === 3}
              />

              <Financial
                handleClickSave={() => {}}
                errors={errors}
                data={answers}
                setValues={setValues}
                visible={value === 4}
              />

              <Administrative
                handleClickSave={() => {}}
                errors={errors}
                data={answers}
                setValues={setValues}
                visible={value === 5}
              />

              <Accountant
                handleClickSave={() => {}}
                errors={errors}
                data={answers}
                setValues={setValues}
                visible={value === 6}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Answers;
