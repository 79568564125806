import "./history.scss";

function History() {
  return (
    <div className="w-100 text-center container">
      <div className="linea-tiempo">
        <div className="momento">
          <h3>2016</h3>
          <div className="descripcion">
            <span>&#10004; Firma del acuerdo de paz</span>
            <br />
            <span>&#10004; Establecimiento de las ZVTN</span>
          </div>
        </div>
        <div className="momento">
          <h3>2017</h3>
          <div className="descripcion">
            <span>&#10004; Constitución ECOMUN</span>
            <br />
            <span>&#10004; Inscripción de partidos comunes</span>
          </div>
        </div>
        <div className="momento">
          <h3>2018</h3>
          <div className="descripcion">
            <span>
              &#10004; Emisión del CONPES 3931 <br />
              <i>
                Política nacional para la reincorporación social y económica de
                exintengrantes de las FARC-EP
              </i>
            </span>
            <br />
            <span>&#10004; Constitución 51 FA</span>
          </div>
        </div>
        <div className="momento">
          <h3>2019</h3>
          <div className="descripcion">
            <span>
              &#10004; Participación en AGROEXPO de las formas asociativas
            </span>
            <br />
            <span>&#10004; Procesos fortalecimiento FA</span>
            <br />
            <span>&#10004; Constitución 38 FA</span>
          </div>
        </div>
        <div className="momento">
          <h3>2020</h3>
          <div className="descripcion">
            <span>&#10004; Mesa nacional de asociatividad rural</span>
            <br />
            <span>&#10004; Constitución 31 FA</span>
          </div>
        </div>
        <div className="momento">
          <h3>2021</h3>
          <div className="descripcion">
            <span>&#10004; REDCOMUN implementación</span>
            <br />
            <span>&#10004; Constitución 20 FA</span>
          </div>
        </div>
        <div className="momento">
          <h3>2022</h3>
          <div className="descripcion">
            <span>&#10004; REDCOMUN operación</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
