import { Col, Form, Row } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function FunctioningOrganizationalStructure() {
  const { diagnosticQuestions, questionsOptionsDiagnostic } = severalStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(4, diagnosticQuestions).question}
            name={"q4"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 4)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(5, diagnosticQuestions).question}
            name={"q5"}
          >
            <Select
              mode="multiple"
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 5)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(6, diagnosticQuestions).question}
            name={"q6"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 6)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(7, diagnosticQuestions).question}
            name={"q7"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 7)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(8, diagnosticQuestions).question}
            name={"q8"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 8)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(9, diagnosticQuestions).question}
            name={"q9"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 9)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(10, diagnosticQuestions).question}
            name={"q10"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 10)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(11, diagnosticQuestions).question}
            name={"q11"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 11)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={24} xl={124}>
          <Form.Item
            label={getDiagnosticQuestion(12, diagnosticQuestions).question}
            name={"q12"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 12)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default FunctioningOrganizationalStructure;
