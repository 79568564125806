import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Input,
  theme,
  Typography,
  Row,
  Col,
  Alert,
} from "antd";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";

import { severalStore } from "../../../stores";
import { validateRecaptch, requestResetPassword } from "../../../servicesHTTP";
import Header from "../header/Header";
import { messageSweetalert } from "../../../utilities";
import { Loading } from "../../../components";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title } = Typography;

const validateData = (data: any) => {
  const errors: any = {};
  if (data.email === "") errors.email = "Ingrese Correo Electrónico";
  if (!data.recaptcha) errors.recaptcha = "Error validando reCAPTCHA";
  return errors;
};

const initialData = {
  email: "",
  recaptcha: false,
};

function ForgotPassword() {
  const { token: themeToken } = useToken();
  const screens = useBreakpoint();
  const { setShowLoading } = severalStore();

  const [token, setToken] = useState("");
  const [refreshReCaptcha] = useState(false);
  const [data, setData] = useState({
    ...initialData,
  });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    (async () => {
      if (token !== "") {
        const result = await validateRecaptch(token);
        if (result.success && result.score > 0.5) {
          setData((prev: any) => ({ ...prev, recaptcha: true }));
        }
      }
    })();
  }, [token]);

  const styles: any = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${themeToken.paddingXL}px`
        : `${themeToken.sizeXXL}px ${themeToken.padding}px`,
    },
    footer: {
      marginTop: themeToken.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: themeToken.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: themeToken.colorBgContainer,
      display: "flex",
      height: "auto",
      padding: screens.md ? `${themeToken.sizeXXL}px 0px` : "0px",
      borderRadius: "5px",
      boxShadow: "1px 1px 12px 0px rgba(0, 0, 0, 0.1)",
    },
    text: {
      color: themeToken.colorTextSecondary,
    },
    title: {
      fontSize: screens.md
        ? themeToken.fontSizeHeading2
        : themeToken.fontSizeHeading3,
    },
  };

  const handleSend = async (data: any) => {
    delete data.recaptcha;
    const formData = new FormData();
    const keysData = Object.keys(data);
    keysData.forEach((key) => {
      formData.append(key, data[key]);
    });

    setShowLoading(true);

    const result = await requestResetPassword(data);
    if (result.status === "ok") {
      messageSweetalert(
        "Terminado",
        <p>Solicitud enviada, revise su E-mail.</p>
      );
      setData({ ...initialData });
    } else if (result.status === "no_found")
      messageSweetalert(
        "Correo no encontrado",
        "Revise el correo ingresado",
        "info"
      );
    else if (result.status !== "error")
      messageSweetalert(result.message, <p>Solicitud no enviada.</p>, "error");
    setShowLoading(false);
  };

  const handleVerify = async (_token: any) => {
    token === "" && setToken(_token);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prev: any) => ({ ...prev, [name]: value }));
  };

  const save = async (data: any) => {
    setErrors({});
    const errors = validateData(data);
    if (Object.keys(errors).length <= 0) {
      handleSend(data);
      return;
    }
    setErrors(errors);
  };

  const handleClick = () => {
    save({ ...data });
  };

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/coffee-background.png"
          })`,
        }}
      >
        <section style={styles.section}>
          <div style={styles.container}>
            <div style={styles.header}>
              <Title style={styles.title}>Recordar contraseña</Title>
            </div>
            <Loading />
            <Form layout="vertical">
              <Row className="row-form">
                <Col xs={24} md={24} xl={24}>
                  <Form.Item label="Correo electrónico">
                    <Input
                      name="email"
                      placeholder="Correo electrónico"
                      onChange={handleChange}
                      value={data.email}
                    />
                  </Form.Item>
                  {errors?.email && (
                    <Alert
                      style={{ marginTop: "-20px" }}
                      message={errors.email}
                      type="error"
                      showIcon
                    />
                  )}
                </Col>
              </Row>

              <GoogleReCaptchaProvider
                reCaptchaKey={String(process.env.REACT_APP_KEY_RECAPTCHA)}
              >
                <GoogleReCaptcha
                  onVerify={handleVerify}
                  refreshReCaptcha={refreshReCaptcha}
                />
              </GoogleReCaptchaProvider>
              {errors?.recaptcha && (
                <Row className="row-form">
                  <Col xs={24} md={24} xl={24}>
                    <Alert
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                      message={errors.recaptcha}
                      type="error"
                      showIcon
                    />
                  </Col>
                </Row>
              )}
              <Form.Item style={{ marginBottom: "0px" }}>
                <Button block={true} type="primary" onClick={handleClick}>
                  Enviar solicitud
                </Button>
                <div style={styles.footer}>
                  <Link to="/login">Volver</Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        </section>
      </div>
    </>
  );
}

export default ForgotPassword;
