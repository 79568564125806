import { Col, Form, Row } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function RolesAndDecisions() {
  const { diagnosticQuestions, questionsOptionsDiagnostic } = severalStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(24, diagnosticQuestions).question}
            name={"q24"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 24)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>
    </>
  );
}

export default RolesAndDecisions;
