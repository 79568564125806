import { useState } from "react";
import { Alert, Col, DatePicker, Form, Modal, Row } from "antd";
import { Select } from "../../../components";
import dayjs from "dayjs";
import { severalStore, userStore } from "../../../stores";
import { enableButtonSave } from "../../../utilities";
import { applicationModules } from "../../../constants";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.year === "") errors.year = "Seleccione Año evaluado";
  if (String(data.type) === "0")
    errors.type = "Seleccione Tipo de periodicidad";
  if (String(data.period) === "0")
    errors.period = "Seleccione Periodo evaluado";

  return errors;
};

function Create({ open, handleClose, data, saveRecord }: any) {
  const { diagnosticPeriodicity, diagnosticTypePeriodicity }: any =
    severalStore();
  const { permissions } = userStore();
  const [localData, setLodalData] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "type") {
      setLodalData((prev: any) => ({ ...prev, [name]: value, period: 0 }));
      return;
    }
    setLodalData((prev: any) => ({ ...prev, [name]: value }));
  };

  const save = async (data: any) => {
    setErrors({});
    const errors = validateData(data);
    if (Object.keys(errors).length <= 0) {
      saveRecord(data);
      return;
    }

    setErrors(errors);
  };

  const handleClickSave = () => {
    save(localData);
  };
  return (
    <Modal
      title={`${data.new ? "Crear" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={"Guardar"}
      onCancel={() => handleClose(false)}
      width={"700px"}
      cancelText="Cerrar"
      okButtonProps={{
        style: {
          display: enableButtonSave(
            applicationModules,
            permissions,
            "diagnostic"
          )
            ? ""
            : "none",
        },
      }}
    >
      <p>Diligencie la información del referente al nuevo diagnóstico.</p>
      <Form
        layout="vertical"
        requiredMark="optional"
        autoComplete="off"
        size="large"
      >
        <Row className="row-form">
          <Col xs={24} md={7} lg={7}>
            <Form.Item label="Año evaluado">
              <DatePicker
                allowClear={false}
                style={{ width: "100%" }}
                value={dayjs(String(localData.year))}
                picker="year"
                onChange={(date: any, dateString: any) => {
                  handleChange({ target: { name: "year", value: dateString } });
                }}
              />
            </Form.Item>
            {errors?.year && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.year}
                type="error"
                showIcon
              />
            )}
          </Col>
          <Col span={1}></Col>
          <Col xs={24} md={7} lg={7}>
            <Form.Item label="Tipo de periodicidad">
              <Select
                name="type"
                placeholder="Please select"
                options={diagnosticTypePeriodicity.map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
                value={localData.type}
                onChange={handleChange}
              />
            </Form.Item>
            {errors?.type && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.type}
                type="error"
                showIcon
              />
            )}
          </Col>
          <Col span={1}></Col>
          <Col xs={24} md={7} lg={7}>
            <Form.Item label="Periodo evaluado">
              <Select
                name="period"
                placeholder="Please select"
                options={diagnosticPeriodicity
                  .filter((y: any) => y.type_periodicity === localData.type)
                  .map((x: any) => ({
                    value: x.id,
                    label: x.period,
                  }))}
                value={localData.period}
                onChange={handleChange}
              />
            </Form.Item>
            {errors?.period && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.period}
                type="error"
                showIcon
              />
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Create;
