import platformregistrationicon01 from "../../../../assets/Images/sarlaft-images/platform-registration-icon-01.png";
import sirelcapacitationicon01 from "../../../../assets/Images/sarlaft-images/sirel-capacitation-icon-01.png";
import exonaratedclients from "../../../../assets/Images/sarlaft-images/exonarated-clients.png";
import loadverificationicon01 from "../../../../assets/Images/sarlaft-images/load-verification-icon-01.png";
import { downloadFile } from "../../../../http";
import { URL_SERVER } from "../../../../config";
import { Button } from "antd";

function SirelManual() {
  const download = (name: string) => {
    downloadFile(`${URL_SERVER}/sarlaft/dowloadfiles`, name, {
      file: name,
    });
  };
  return (
    <div className="container sirelManualexpPanel">
      <div className="row mx-auto">
        <div className="col-sm-6 col-xl-3 d-flex justify-content-center align-items-center flex-column mb-3 mb-lg-0">
          <h1 className="sirelManual-title">Registro en plataforma</h1>
          <div className="matExp-imgContainer mb-3">
            <img
              className="matExp-img"
              src={platformregistrationicon01}
              alt=""
            />
          </div>
          <Button
            className="helpButtons"
            type="primary"
            onClick={() => download("Registro_Plataforma.pdf")}
          >
            Descargar documento
          </Button>
        </div>
        <div className="col-sm-6 col-xl-3 d-flex justify-content-center align-items-center flex-column mb-3 mb-lg-0">
          <h1 className="sirelManual-title">Menu principal SIREL</h1>
          <div className="matExp-imgContainer mb-3">
            <img className="matExp-img" src={sirelcapacitationicon01} alt="" />
          </div>
          <Button
            className="helpButtons"
            type="primary"
            onClick={() => download("Menu_Principal.pdf")}
          >
            Descargar documento
          </Button>
        </div>
        <div className="col-sm-6 col-xl-3 d-flex justify-content-center align-items-center flex-column mb-3 mb-lg-0">
          <h1 className="sirelManual-title">Capacitación SIREL</h1>
          <div className="matExp-imgContainer mb-3">
            <img className="matExp-img" src={exonaratedclients} alt="" />
          </div>
          <Button
            className="helpButtons"
            type="primary"
            onClick={() =>
              window.open(
                "https://reportes.uiaf.gov.co/reportesfsmcif64/Modules/Home/html/default.aspx"
              )
            }
          >
            Ingresa aquí
          </Button>
        </div>
        <div className="col-sm-6 col-xl-3 d-flex justify-content-center align-items-center flex-column mb-3 mb-lg-0">
          <h1 className="sirelManual-title">Verificación Cargue</h1>
          <div className="matExp-imgContainer mb-3">
            <img className="matExp-img" src={loadverificationicon01} alt="" />
          </div>
          <Button
            className="helpButtons"
            type="primary"
            onClick={() => download("Verificacion_Cargue.pdf")}
          >
            Descargar documento
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SirelManual;
