export const localStorageAddUpdate = (item: string, data: any) => {
  sessionStorage.setItem(item, JSON.stringify(data));
};

export const localStorageSelect = (item: string) => {
  let a: any = sessionStorage.getItem(item);
  return JSON.parse(a);
};

export const localStorageDelete = (item: string) => {
  sessionStorage.removeItem(item);
};
