import "../../../../styles/styles_old/styles_old.scss";
import "./home.scss";
import { Breadcrumb, Col, Row } from "antd";
import { Page } from "../../../../components";
import banner from "../../../../assets/Images/sarlaft-images/mean-banner-2.png";
import { Link } from "react-router-dom";
import { Accordion } from "../../../../components";
import WarningSigns from "./WarningSigns";
import NonFinancialAf from "./NonFinancialAf";
import SirelManual from "./SirelManual";

function Home() {
  return (
    <Page>
      <Row>
        <Col flex="auto">
          <div className="container-image-banner-page">
            <img
              className="image-banner-page"
              src={banner}
              alt="Banner Guía para generar reportes UIAF"
            />
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: 50, paddingLeft: "50px", paddingRight: "50px" }}>
        <Col xs={24} md={12} xl={12} xxl={12}>
          <div className="mt-4">
            <h1 className="associative-forms-list-title">Centro de ayuda</h1>
          </div>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link
                    color="inherit"
                    to="/"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    Inicio
                  </Link>
                ),
              },
              {
                title: "Guía Sarlaft",
              },
              {
                title: "Centro de ayuda",
              },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 50, paddingLeft: "50px", paddingRight: "50px" }}>
        <div className="col-12">
          <h2 className="infText">Tenemos la siguiente información para ti:</h2>
        </div>
      </Row>

      <Row className="no-padding-help-center" style={{ marginTop: 10, paddingLeft: "50px", paddingRight: "50px" }}>
        <Col span={24}>
          <Accordion
            size={"large"}
            items={[
              {
                key: "1",
                label: "Señales de alerta",
                children: <WarningSigns />,
              },
              {
                key: "2",
                label:
                  "Conoce los tipos de de reportes de las formas asociativas No financieras",
                children: <NonFinancialAf />,
              },
              {
                key: "3",
                label: "Manual de usuario SIREL",
                children: <SirelManual />,
              },
            ]}
          />
        </Col>
      </Row>
    </Page>
  );
}

export default Home;
