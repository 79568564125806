import "./calendarReportsModal.scss";
import suspiciousOperationsAusenceCalendar from "../../../assets/Images/reports-calendar/suspicious-operations-ausence-calendar.png";
import cashTransactionsCalendar from "../../../assets/Images/reports-calendar/cash-transactions-calendar.png";
import debitAndCreditCardTransactionCalendar from "../../../assets/Images/reports-calendar/debit-and-credit-card-transaction-calendar.png";
import exonaratedClientsCalendar from "../../../assets/Images/reports-calendar/exonarated-clients-calendar.png";
import productCalendar from "../../../assets/Images/reports-calendar/product-calendar.png";
import reportsSarlaftCalendar from "../../../assets/Images/reports-calendar/reports-sarlaft-calendar.png";
import { downloadFile } from "../../../http";
import { URL_SERVER } from "../../../config";
import { Button, Card, Modal } from "antd";

function CalendarReportsModal({ open, handleClose }: any) {
  const downloadCalendar = () => {
    downloadFile(`${URL_SERVER}/sarlaft/dowloadfiles`, "Calendario.pdf", {
      file: "Calendario.pdf",
    });
  };
  return (
    <Modal
      centered
      open={open}
      onCancel={() => handleClose(false)}
      width={"800px"}
      footer={null}
      title={<h1>Consultar calendario</h1>}
      className="modal-cash-transactions"
      style={{ maxHeight: 500 }}
    >
      <Card
        title={
          <Button
            type="primary"
            className="helpButtons"
            onClick={() => downloadCalendar()}
          >
            Descargar
          </Button>
        }
      >
        <div
          style={{
            position: "relative",
            overflowY: "visible",
          }}
        >
          <div className="calendarImg-Container">
            <img
              className="img-fluid"
              src={suspiciousOperationsAusenceCalendar}
              alt=""
            />
          </div>
          <div className="calendarImg-Container">
            <img className="img-fluid" src={cashTransactionsCalendar} alt="" />
          </div>
          <div className="calendarImg-Container">
            <img
              className="img-fluid"
              src={debitAndCreditCardTransactionCalendar}
              alt=""
            />
          </div>
          <div className="calendarImg-Container">
            <img className="img-fluid" src={exonaratedClientsCalendar} alt="" />
          </div>
          <div className="calendarImg-Container">
            <img className="img-fluid" src={productCalendar} alt="" />
          </div>
          <div className="calendarImg-Container">
            <img className="img-fluid" src={reportsSarlaftCalendar} alt="" />
          </div>
        </div>
      </Card>
    </Modal>
  );
}

export default CalendarReportsModal;
