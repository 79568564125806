import { Col, Form, Input, Row } from "antd";
const { TextArea } = Input;

function Costing({ handleChange, record }: any) {
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"¿Cuál es el costo de prestación del servicio?"}>
            <Input
              placeholder=""
              name="cost_service"
              onChange={handleChange}
              value={record.cost_service}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Cuáles son los gastos asociados a la prestación del servicio?">
            <Input
              placeholder=""
              name="expenditure_service"
              onChange={handleChange}
              value={record.expenditure_service}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Cuál es el precio general (al público) del servicio prestado?"
            }
          >
            <Input
              placeholder=""
              name="service_price"
              onChange={handleChange}
              value={record.service_price}
              type="number"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={24} xl={24}>
          <Form.Item label="¿Cómo establece el precio general (al público) del servicio prestado?">
            <TextArea
              name="how_you_set_price"
              value={record.how_you_set_price}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Costing;
