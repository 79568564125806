import { Col, Form, Row } from "antd";
import { CurrencyInput } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function FinancialAnalysis() {
  const { diagnosticQuestions }: any = severalStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(58, diagnosticQuestions).question}
            name={"q58"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(59, diagnosticQuestions).question}
            name={"q59"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(60, diagnosticQuestions).question}
            name={"q60"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(61, diagnosticQuestions).question}
            name={"q61"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(62, diagnosticQuestions).question}
            name={"q62"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(63, diagnosticQuestions).question}
            name={"q63"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(64, diagnosticQuestions).question}
            name={"q64"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(65, diagnosticQuestions).question}
            name={"q65"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(66, diagnosticQuestions).question}
            name={"q66"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(67, diagnosticQuestions).question}
            name={"q67"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(68, diagnosticQuestions).question}
            name={"q68"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(69, diagnosticQuestions).question}
            name={"q69"}
          >
            <CurrencyInput min="0" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default FinancialAnalysis;
