import { Col, Form, Input, Row } from "antd";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

function ProductionVolume({ handleChange, record }: any) {
  const { unitMeasurementPresentation }: any = severalStore();
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Cuál es la cantidad de unidades de producto en presentación final elaboradas por lote?"
            }
          >
            <Input
              placeholder=""
              name="units_product_final_presentation_produced_batch"
              onChange={handleChange}
              value={record.units_product_final_presentation_produced_batch}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Unidad de medida">
            <Select
              name="production_measurement_batch_id"
              value={record.production_measurement_batch_id}
              onChange={handleChange}
              options={unitMeasurementPresentation.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
              option0={true}
              placeholder="Seleccionar"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default ProductionVolume;
