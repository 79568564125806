import { Col, Form, Row } from "antd";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

function Inputs({ handleChange, record }: any) {
  const { inputsFarmingProduct } = severalStore();

  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="¿Qué insumos requiere para producir un cultivo?">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              option0={false}
              options={inputsFarmingProduct.map((x: any) => {
                return {
                  value: String(x.id),
                  label: x.name,
                };
              })}
              name="inputs_id"
              value={record.inputs_id}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>
    </Form>
  );
}

export default Inputs;
