import { useState, useEffect } from "react";
import { Button, Col, Flex, Row } from "antd";
import { PlusOutlined, EyeOutlined } from "@ant-design/icons";
import { severalStore, userStore } from "../../../../stores";
import {
  getFarmingProductsByAssociativeForm,
  createFarmingProduct,
  updateFarmingProduct,
} from "../../../../servicesHTTP";
import { Loading, Table } from "../../../../components";
import CreateUpdate from "./CreateUpdate";
import { enableButtonSave, messageSweetalert } from "../../../../utilities";
import { applicationModules } from "../../../../constants";

const initialData = {
  id: 0,
  production_oriented_marketing: 2,
  area_used_development_economic_activity_hectares: "",
  name: "",
  description: "",
  use_ownership_land_economic_activity_id: 0,
  production_type_id: [],
  quantity_produced_crop_cycle: "",
  unit_measurement_crop_cycle_id: 0,
  raw_materials_crop_cycle: "",
  inputs_id: [],
  people_required_crop_cycle: "",
  type_labor_contract_id: 0,
  type_machinery_require_production: "",
  machinery_property_id: 0,
  selling_price_harvest: "",
  associative_form_id: 0,
  use_ownership_land_economic_activity: null,
  type_labor_contract: null,
  machinery_property: null,
  raw_materials: [],
  production_cost_matrices: [],
  expenditure_matrices: [],
  sowing_epoch: [],
  harvest_period: [],
  total_cost: 0,
  total_expenditure: 0,
  final_cost_cycle: 0,
  harvest_utility: 0,
  new: true,
};

const validateData = (data: any) => {
  const errors: any = {};
  if (data.name === "") errors.name = "Ingrese Nombre";
  if (data.description === "") errors.description = "Ingrese Descripción";

  return errors;
};

function Home() {
  const { setShowLoading, formSelected, getSeveralDataEconomicActivity }: any =
    severalStore();
  const { permissions } = userStore();
  const [records, setRecords] = useState([]);
  const [selected, setSelected] = useState({
    ...initialData,
    associative_form_id: formSelected.id,
  });
  const [modalOpenCreate, setModalOpenCreate] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [columns] = useState([
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Cantidad producida por ciclo del cultivo",
      dataIndex: "quantity_produced_crop_cycle",
      key: "quantity_produced_crop_cycle",
    },
    {
      title: "Precio de venta de la cosecha",
      dataIndex: "selling_price_harvest",
      key: "selling_price_harvest",
    },
    {
      title: "Acción",
      dataIndex: "acciones",
      key: "acciones",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return (
          <Flex style={{ marginBottom: 10 }}>
            <Button
              type="link"
              size="large"
              icon={<EyeOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => {
                setSelected({ ...record, new: false });
                setModalOpenCreate(true);
              }}
            >
              Ver producto
            </Button>
          </Flex>
        );
      },
    },
  ]);

  const getProducts = async (formSelected: any) => {
    if (formSelected.id !== 0) {
      const result = await getFarmingProductsByAssociativeForm(formSelected.id);
      if (result.status === "ok") {
        setRecords(result.data);
      }
    }
  };

  useEffect(() => {
    getSeveralDataEconomicActivity();
  }, [getSeveralDataEconomicActivity]);

  useEffect(() => {
    (async () => {
      setShowLoading(true);
      await getProducts(formSelected);
      setShowLoading(false);
    })();
  }, [formSelected, setShowLoading]);

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      const pre: any = records.find((x: any) => x.id === selected.id);
      if (pre) {
        setSelected(pre);
      }
    }
    // eslint-disable-next-line
  }, [records]);

  const orderData = (data: any) => {
    const newData = {
      ...data,
      inputs_id: data.inputs_id ? data.inputs_id.join() : null,
      production_type_id: data.production_type_id
        ? data.production_type_id.join()
        : null,
    };

    return newData;
  };

  const saveRecord = async (data: any) => {
    setErrors({});
    const errors = validateData(data);
    data = orderData(data);
    if (Object.keys(errors).length <= 0) {
      if (data.new) {
        setShowLoading(true);
        const result = await createFarmingProduct(data);
        if (result.status === "ok") {
          messageSweetalert("Terminado", <p>Datos guardados.</p>);
          await getProducts(formSelected);
          setModalOpenCreate(false);
          setSelected({
            ...initialData,
            associative_form_id: formSelected.id,
          });
        } else {
          messageSweetalert(
            "Terminado",
            <p>No se guardaron los datos.</p>,
            "error"
          );
        }
        setShowLoading(false);
      } else {
        setShowLoading(true);
        const result = await updateFarmingProduct(data);
        if (result.status === "ok") {
          messageSweetalert("Terminado", <p>Datos guardados.</p>);
          await getProducts(formSelected);
        } else {
          messageSweetalert(
            "Terminado",
            <p>No se guardaron los datos.</p>,
            "error"
          );
        }
        setShowLoading(false);
      }
      return;
    }

    setErrors(errors);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Flex style={{ marginBottom: 10 }}>
            {enableButtonSave(
              applicationModules,
              permissions,
              "economicActivity"
            ) && (
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                onClick={() => {
                  setSelected({
                    ...initialData,
                    associative_form_id: formSelected.id,
                  });
                  setModalOpenCreate(true);
                }}
              >
                Agregar producto
              </Button>
            )}
          </Flex>
          <Table
            dataSource={records || []}
            columns={columns}
            bordered
            scroll={{ x: "1000" }}
            selection={false}
          />
        </Col>
      </Row>

      {modalOpenCreate && (
        <CreateUpdate
          open={modalOpenCreate}
          handleClose={setModalOpenCreate}
          data={{ ...selected }}
          saveRecord={saveRecord}
          errors={errors}
        />
      )}
      <Loading />
    </>
  );
}

export default Home;
