import { Col, Form, Row } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function Obligations() {
  const { diagnosticQuestions, questionsOptionsDiagnostic } = severalStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(70, diagnosticQuestions).question}
            name={"q70"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 70)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(71, diagnosticQuestions).question}
            name={"q71"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 71)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(72, diagnosticQuestions).question}
            name={"q72"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 72)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(73, diagnosticQuestions).question}
            name={"q73"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 73)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(74, diagnosticQuestions).question}
            name={"q74"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 74)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(75, diagnosticQuestions).question}
            name={"q75"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 75)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(76, diagnosticQuestions).question}
            name={"q76"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 76)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(77, diagnosticQuestions).question}
            name={"q77"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 77)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(78, diagnosticQuestions).question}
            name={"q78"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 78)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(79, diagnosticQuestions).question}
            name={"q79"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 79)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(80, diagnosticQuestions).question}
            name={"q80"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 80)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(81, diagnosticQuestions).question}
            name={"q81"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 81)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(82, diagnosticQuestions).question}
            name={"q82"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 82)
                .map((y: any) => ({ value: String(y.id), label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>
    </>
  );
}

export default Obligations;
