import nonfinancialassociativeform01 from "../../../../assets/Images/sarlaft-images/non-financial-associative-form-01.png";
import { downloadFile } from "../../../../http";
import { URL_SERVER } from "../../../../config";
import { Button } from "antd";

function NonFinancialAf() {
  const getTypeOfReports = () => {
    downloadFile(
      `${URL_SERVER}/sarlaft/dowloadfiles`,
      "Tipos_De_Reportes.pdf",
      {
        file: "Tipos_De_Reportes.pdf",
      }
    );
  };
  return (
    <div className="container-md p-0 nonFinancialAfexpPanel">
      <div className="row w-100 mx-auto">
        <div className="col-md-8">
          <h2 className="matExp-title">
            Tipos de reporte de la forma asociativa NO financiera
          </h2>
          <div className="matExp-desc">
            Consideramos importante diferenciar una operación inusual y una
            operación sospechosa para su entendimiento pleno.
          </div>

          <Button
            className="helpButtons"
            type="primary"
            onClick={() => getTypeOfReports()}
          >
            Descargar documento
          </Button>
        </div>
        <div className="col-md-4 d-flex justify-content-center order-first order-md-last">
          <img
            className="matExp-img"
            src={nonfinancialassociativeform01}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default NonFinancialAf;
