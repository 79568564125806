import "./styles/styles.scss";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { userStore } from "./stores";
import { localStorageSelect } from "./localStorage";
import HomePublic from "./pages/public/homePublic/HomePublic";
import HomePublicList from "./pages/public/homePublic/HomePublicList";
import FormAssociativeView from "./pages/public/homePublic/formAssociativeView/Home";
import ProjectIniciativeView from "./pages/public/homePublic/projectIniciativeView/Home";
import AboutUs from "./pages/public/aboutUs/AboutUs";
import Login from "./pages/public/Login";
import { PrivateRoute } from "./components";
import { routes, applicationModules } from "./constants";
import Home from "./pages/private/Home";
import HomeCharacterization from "./pages/private/characterization/Home";
import HomeDiagnostic from "./pages/private/diagnostic/Home";
import SarlaftHome from "./pages/private/sarlaft/Home";
import SarlaftHelpCenter from "./pages/private/sarlaft/helpCenter/Home";
import TypeRegister from "./pages/public/register/TypeRegister";
import Register from "./pages/public/register/Register";
import ForgotPassword from "./pages/public/resetPassword/ForgotPassword";
import ResetPassword from "./pages/public/resetPassword/ResetPassword";
import ValidatedEmail from "./pages/public/register/ValidatedEmail";
import HomeEconomicActivity from "./pages/private/economicActivity/Home";
import HomeAdministration from "./pages/private/administration/Home";

function App() {
  const { setToken } = userStore();
  const [urlReload, setUrlReload] = useState("/");
  if (window.location.pathname !== "/") {
    urlReload === "/" && setUrlReload(window.location.pathname);
  }

  useEffect(() => {
    const to = localStorageSelect("token");
    if (to) {
      setToken(to);
    }
  }, [setToken]);
  return (
    <Router>
      <Routes>
        <Route path={`/mapa`} element={<HomePublic urlReload={urlReload} />} />
        <Route
          path={`/associative-form-project-list`}
          element={<HomePublicList />}
        />
        <Route
          path={`/associative-form-view`}
          element={<FormAssociativeView />}
        />
        <Route
          path={`/project-iniciative-view`}
          element={<ProjectIniciativeView />}
        />
        <Route path={`/aboutus`} element={<AboutUs />} />
        <Route path={`/login`} element={<Login />} />
        <Route path={`/`} element={<Login />} />
        <Route path={`/type-register`} element={<TypeRegister />} />
        <Route path={`/register/:id`} element={<Register />} />
        <Route path={`/forgot-password`} element={<ForgotPassword />} />
        <Route
          path={`/reset-password/:userToken/:id`}
          element={<ResetPassword />}
        />
        <Route
          path={`/confirm-email/:userToken/:id`}
          element={<ValidatedEmail />}
        />
        <Route
          path={routes.home}
          element={<PrivateRoute module={applicationModules.home} />}
        >
          <Route path={routes.home} element={<Home />} />
        </Route>
        <Route
          path={routes.characterization}
          element={
            <PrivateRoute module={applicationModules.characterization} />
          }
        >
          <Route
            path={routes.characterization}
            element={<HomeCharacterization />}
          />
        </Route>

        <Route
          path={routes.diagnostic}
          element={<PrivateRoute module={applicationModules.diagnostic} />}
        >
          <Route path={routes.diagnostic} element={<HomeDiagnostic />} />
        </Route>

        <Route
          path={routes.economicActivity}
          element={
            <PrivateRoute module={applicationModules.economicActivity} />
          }
        >
          <Route
            path={routes.economicActivity}
            element={<HomeEconomicActivity />}
          />
        </Route>

        <Route
          path={routes.sarlaft}
          element={<PrivateRoute module={applicationModules.sarlaft} />}
        >
          <Route path={routes.sarlaft} element={<SarlaftHome />} />
        </Route>

        <Route
          path={routes.sarlaftHelpCenter}
          element={
            <PrivateRoute module={applicationModules.sarlaftHelpCenter} />
          }
        >
          <Route
            path={routes.sarlaftHelpCenter}
            element={<SarlaftHelpCenter />}
          />
        </Route>

        <Route
          path={routes.administration}
          element={<PrivateRoute module={applicationModules.adminitration} />}
        >
          <Route
            path={routes.administration}
            element={<HomeAdministration />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
