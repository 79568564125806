import { Alert, Col, Form, Input, Row } from "antd";

function Characterization({ handleChange, record, errors }: any) {
  return (
    <Form name="cara" layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"Nombre"}>
            <Input
              placeholder=""
              name="name"
              onChange={handleChange}
              value={record.name}
            />
          </Form.Item>
          {errors?.name && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.name}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"Descripción"}>
            <Input
              placeholder=""
              name="description"
              onChange={handleChange}
              value={record.description}
            />
          </Form.Item>
          {errors?.description && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.description}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>
    </Form>
  );
}

export default Characterization;
