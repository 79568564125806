import { useState } from "react";
import { Modal } from "antd";
import { Accordion } from "../../../../components";
import { userStore } from "../../../../stores";
import { enableButtonSave } from "../../../../utilities";
import { applicationModules } from "../../../../constants";
import Characterization from "./Characterization";
import PermitsLicenses from "./PermitsLicenses";
import Labour from "./Labour";
import Machinery from "./Machinery";
import Costing from "./Costing";

function CreateUpdate({ open, handleClose, data, saveRecord, errors }: any) {
  const { permissions } = userStore();
  const [record, setRecord] = useState({ ...data });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setRecord((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleClickSave = () => {
    saveRecord(record);
  };

  return (
    <Modal
      title={`${data.new ? "Crear" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={"Guardar"}
      onCancel={() => handleClose(false)}
      width={"800px"}
      cancelText="Cerrar"
      okButtonProps={{
        style: {
          display: enableButtonSave(
            applicationModules,
            permissions,
            "economicActivity"
          )
            ? ""
            : "none",
        },
      }}
    >
      <Accordion
        size={"large"}
        items={[
          {
            key: "1",
            label: "Caracterización del servicio",
            children: (
              <Characterization
                handleChange={handleChange}
                record={record}
                errors={errors}
              />
            ),
          },
          {
            key: "2",
            label: "Permisos y licencias",
            children: (
              <PermitsLicenses handleChange={handleChange} record={record} />
            ),
          },
          {
            key: "3",
            label: "Mano de obra",
            children: <Labour handleChange={handleChange} record={record} />,
          },
          {
            key: "4",
            label: "Maquinaria y equipos",
            children: <Machinery handleChange={handleChange} record={record} />,
          },
          {
            key: "5",
            label: "Costos",
            children: <Costing handleChange={handleChange} record={record} />,
          },
        ]}
      />
    </Modal>
  );
}

export default CreateUpdate;
