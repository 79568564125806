import { Col, Form, Input, Row } from "antd";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

function Machinery({ handleChange, record }: any) {
  const { machineryProperty }: any = severalStore();
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Qué tipo de maquinaria requiere/implementa para la producción?"
            }
          >
            <Input
              placeholder=""
              name="type_machinery_require_production"
              onChange={handleChange}
              value={record.type_machinery_require_production}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="La maquinaria que usa es">
            <Select
              name="machinery_property_id"
              value={record.machinery_property_id}
              onChange={handleChange}
              options={machineryProperty.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
              option0={true}
              placeholder="Seleccionar"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Machinery;
