import { Col, Form, Input, Row } from "antd";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";
import { getDiagnosticQuestion } from "../../../../utilities";

function Participation({ handleClickSave, errors, data, setValues }: any) {
  const { diagnosticQuestions, questionsOptionsDiagnostic } = severalStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(13, diagnosticQuestions).question}
            name={"q13"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 13)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            name="q14"
            label={getDiagnosticQuestion(14, diagnosticQuestions).question}
          >
            <Input placeholder="" type="number" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            name="q15"
            label={getDiagnosticQuestion(15, diagnosticQuestions).question}
          >
            <Input placeholder="" type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(16, diagnosticQuestions).question}
            name={"q16"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 16)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(17, diagnosticQuestions).question}
            name={"q17"}
          >
            <Select
              placeholder="Seleccionar"
              option0={false}
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 17)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(18, diagnosticQuestions).question}
            name={"q18"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 18)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={getDiagnosticQuestion(19, diagnosticQuestions).question}
            name={"q19"}
          >
            <Select
              placeholder="Seleccionar"
              options={questionsOptionsDiagnostic
                .filter((x: any) => x.question_id === 19)
                .map((y: any) => ({ value: y.id, label: y.name }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>
    </>
  );
}

export default Participation;
