import { useEffect, useState } from "react";
import { severalStore } from "../../../../../stores";
import uuid from "react-uuid";
import { Alert, Col, Form, Input, Modal, Row } from "antd";
import { Select } from "../../../../../components";

const validateData = (data: any) => {
  const errors: any = {};
  if (String(data.bank_id) === "0") errors.bank_id = true;
  if (String(data.account_number) === "") errors.account_number = true;
  return errors;
};

function CreateEdit({ open, handleClose, data, create, edit }: any) {
  const { banks }: any = severalStore();
  const [account, setAccount] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setAccount({ ...data });
  }, [data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setAccount((x: any) => ({ ...x, [name]: value }));
    setErrors({});
  };

  const handleClickSave = () => {
    const errors = validateData(account);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (account.new) {
      const uid = uuid();
      const newAccount = {
        ...account,
        bank_account_type_o:
          account.bank_account_type === "A" ? "Ahorros" : "Corriente",
        bank: banks.find((x: any) => String(x.id) === String(account.bank_id)),
      };
      create({ ...newAccount, id: uid, key: uid });
      return;
    }

    edit({ ...account, new: false });
    setErrors({});
  };

  return (
    <Modal
      title={`${data.new ? "Crear" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${data.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"1000px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Entidad">
              <Select
                name={"bank_id"}
                value={account.bank_id}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={banks.map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
                onChange={handleChange}
              />
              {errors?.bank_id && (
                <Alert message={errors.bank_id} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Tipo">
              <Select
                name="bank_account_type"
                value={account.bank_account_type}
                style={{ width: "100%" }}
                placeholder="Please select"
                option0={false}
                options={[
                  { value: "A", label: "Ahorros" },
                  { value: "C", label: "Corriente" },
                ]}
                onChange={handleChange}
              />
              {errors?.bank_account_type && (
                <Alert
                  message={errors.bank_account_type}
                  type="error"
                  showIcon
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Número de cuenta">
              <Input
                placeholder="Número de cuenta"
                name="account_number"
                value={account.account_number}
                onChange={handleChange}
                type="number"
              />
              {errors?.account_number && (
                <Alert message={errors.account_number} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}></Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEdit;
