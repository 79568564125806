export const routes = {
  home: "/home",
  characterization: "/characterization",
  diagnostic: "/diagnostic",
  sarlaft: "/sarlaft",
  sarlaftHelpCenter: "/sarlaft-help-center",
  economicActivity: "/economic-activity",
  administration: "/administration"
};

export const applicationModules = {
  home: 0,
  characterization: 1,
  diagnostic: 2,
  sarlaft: 3,
  sarlaftHelpCenter: 4,
  economicActivity: 5,
  adminitration: 6,
};

export const optionsYesNo = [
  {
    value: 2,
    label: "SÍ",
  },
  {
    value: 1,
    label: "NO",
  },
];
