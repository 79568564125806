import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Input, Modal, Row } from "antd";
import { Select } from "../../../../../components";
import uuid from "react-uuid";
import { severalStore } from "../../../../../stores";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.address === "") errors.address = true;
  if (String(data.department_id) === "0") errors.department_id = true;
  if (String(data.municipality_id) === "0") errors.municipality_id = true;
  if (data.village === "") errors.village = true;

  return errors;
};

function CreateEdit({ open, handleClose, data, create, edit }: any) {
  const { departments, municipalies } = severalStore();
  const [branch, setBranch] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setBranch({ ...data });
  }, [data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBranch((x: any) => ({ ...x, [name]: value }));
    setErrors({});
  };

  const handleClickSave = () => {
    const errors = validateData(branch);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (branch.new) {
      const uid = uuid();
      const newBranche = {
        ...branch,
        department: departments.find(
          (x: any) => String(x.code) === String(branch.department_id)
        ),
        municipality: municipalies.find(
          (x: any) => String(x.code) === String(branch.municipality_id)
        ),
        area_o: branch.area === "U" ? "Urbano" : "Rural",
      };
      create({ ...newBranche, id: uid, key: uid });
      return;
    }

    edit({ ...branch, new: false });
    setErrors({});
  };
  return (
    <Modal
      title={`${data.new ? "Agregar" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${data.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"1000px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Área">
              <Select
                name="area"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={branch.area}
                placeholder="Please select"
                option0={false}
                options={[
                  { value: "R", label: "Rural" },
                  { value: "U", label: "Urbano" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Dirección">
              <Input
                placeholder="Dirección"
                name="address"
                onChange={handleChange}
                value={branch.address}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Departamento">
              <Select
                name={"department_id"}
                value={branch.department_id}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={departments.map((x: any) => ({
                  value: x.code,
                  label: x.name,
                }))}
                onChange={handleChange}
              />
              {errors?.department_id && (
                <Alert message={errors.department_id} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Municipio">
              <Select
                onChange={handleChange}
                style={{ width: "100%" }}
                name={"municipality_id"}
                placeholder="Please select"
                value={branch.municipality_id}
                options={municipalies
                  .filter(
                    (x: any) =>
                      String(x.department_id) === String(branch.department_id)
                  )
                  .map((x: any) => ({
                    value: x.code,
                    label: x.name,
                  }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Vereda">
              <Input
                placeholder="Vereda"
                name="village"
                value={branch.village}
                onChange={handleChange}
              />
              {errors?.village && (
                <Alert message={errors.village} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}></Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEdit;
