import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import "mapbox-gl/dist/mapbox-gl.css";
// @ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "./styles.scss";
import pointmap from "../../assets/Images/pointmap.png";
import pointmap_pp from "../../assets/Images/pointmap_pp.png";
import pointmap_ip from "../../assets/Images/pointmap_ip.png";

import * as departmentColombia from "./colombia.json";
import * as municipalyColombia from "./colombia-municipios.json";
import * as venezuela from "./venezuela.json";
import * as ecuador from "./ecuador.json";
import * as panama from "./panama.json";
import * as peru from "./peru.json";
import * as brasil from "./brasil.json";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = forwardRef(
  (
    {
      LayerExternal = [],
      heightMap = "calc(100vh - 65px)",
      widthMap = "99vw",
      floatingMenu = null,
      floatingFooter = null,
    }: any,
    ref
  ) => {
    const mapContainer = useRef(null);
    const map = useRef<any>(null);
    const [lng, setLng] = useState(-74.2285866);
    const [lat, setLat] = useState(2.9027073);
    const [zoom, setZoom] = useState(4.4);

    const addBlurCountries = (map: any) => {
      map.addSource("venezuela", {
        type: "geojson",
        // @ts-ignore
        data: venezuela.default,
      });

      map.addSource("brasil", {
        type: "geojson",
        // @ts-ignore,
        data: brasil.default,
      });

      map.addSource("ecuador", {
        type: "geojson",
        // @ts-ignore
        data: ecuador.default,
      });

      map.addSource("panama", {
        type: "geojson",
        // @ts-ignore
        data: panama.default,
      });

      map.addSource("peru", {
        type: "geojson",
        // @ts-ignore
        data: peru.default,
      });

      map.addLayer({
        id: "ecuador",
        type: "fill",
        source: "ecuador",
        paint: {
          "fill-outline-color": "rgba(255,250,250,0.93)",
          "fill-color": "rgba(255,250,250,0.93)",
        },
      });

      map.addLayer({
        id: "peru",
        type: "fill",
        source: "peru",
        paint: {
          "fill-outline-color": "rgba(255,250,250,0.93)",
          "fill-color": "rgba(255,250,250,0.93)",
        },
      });

      map.addLayer({
        id: "panama",
        type: "fill",
        source: "panama",
        paint: {
          "fill-outline-color": "rgba(255,250,250,0.93)",
          "fill-color": "rgba(255,250,250,0.93)",
        },
      });

      map.addLayer({
        id: "venezuela",
        type: "fill",
        source: "venezuela",
        paint: {
          "fill-outline-color": "rgba(255,250,250,0.93)",
          "fill-color": "rgba(255,250,250,0.93)",
        },
      });

      map.addLayer({
        id: "brasil",
        type: "fill",
        source: "brasil",
        paint: {
          "fill-outline-color": "rgba(255,250,250,0.93)",
          "fill-color": "rgba(255,250,250,0.93)",
        },
      });
    };

    useEffect(() => {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lng, lat],
        zoom: zoom,
      });

      map.current.on("move", () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });

      map.current.loadImage(pointmap, (error: any, image: any) => {
        if (error) throw error;
        map.current.addImage("custom-marker", image);
      });

      map.current.loadImage(pointmap_pp, (error: any, image: any) => {
        if (error) throw error;
        map.current.addImage("custom-marker-pp", image);
      });

      map.current.loadImage(pointmap_ip, (error: any, image: any) => {
        if (error) throw error;
        map.current.addImage("custom-marker-ip", image);
      });

      map.current.on("load", () => {
        addBlurCountries(map.current);

        map.current.addSource("departments", {
          type: "geojson",
          // @ts-ignore
          data: departmentColombia.default,
        });

        map.current.addSource("municipaly", {
          type: "geojson",
          // @ts-ignore
          data: municipalyColombia.default,
        });

        map.current.addLayer({
          id: "departments-highlighted",
          type: "fill",
          source: "departments",
          paint: {
            "fill-outline-color": "rgba(0,0,0,0.3)",
            "fill-color": "#eef0f9",
            "fill-opacity": 0.5,
          },
        });

        map.current.addLayer({
          id: "outline",
          type: "line",
          source: "departments",
          layout: {},
          paint: {
            "line-color": "#627ca3",
            "line-width": 3,
          },
        });

        /* if(this.router.url.includes("productive-initiatives")){
          this.addClusterDepartment_ip();
          this.addClusterDepartment_pp();
        } else {
          this.addClusterDepartment_fa();
        } */
      });
    });

    const getMap = () => map.current;

    useImperativeHandle(ref, () => ({
      getMap,
    }));

    return (
      <div>
        <div
          style={{ height: heightMap, width: widthMap }}
          ref={mapContainer}
          className="map-container"
        />
        <div className="floating-menu">{floatingMenu}</div>
        <div className="floating-footer">{floatingFooter}</div>
      </div>
    );
  }
);

Map.displayName = "Map";

export default Map;
