import { Select } from "antd";

interface IProps {
  value?: any;
  onChange?: (e: any) => void;
  size?: any;
  showSearch?: boolean;
  options: any[];
  placeholder: string;
  name?: string;
  option0?: boolean;
  labelOption0?: string;
  style?: React.CSSProperties;
  mode?: any;
  disabled?: boolean;
}

function SelectCustom({
  value,
  onChange = () => {},
  size = "large",
  showSearch = false,
  options = [],
  placeholder = "",
  name = "",
  option0 = true,
  labelOption0 = "Seleccionar",
  style = {},
  mode = null,
  disabled = false,
}: IProps) {
  const modiOptions = (options: any, option0: boolean) => {
    if (option0) {
      let valueString = true;
      if (options.length > 0) {
        if (typeof options[0].value === "number") valueString = false;
      }
      return [
        { value: valueString ? "0" : 0, label: labelOption0 },
        ...options,
      ];
    }

    return options;
  };
  return (
    <Select
      mode={mode}
      style={{ width: "100%", ...style }}
      size={size}
      showSearch={showSearch}
      value={value}
      placeholder={placeholder}
      optionFilterProp="label"
      onChange={(value: any) => {
        onChange({ target: { name, value } });
      }}
      options={modiOptions(options, option0)}
      disabled={disabled}
    />
  );
}

export default SelectCustom;
