import { Col, Form, Input, Row } from "antd";
import { Accordion } from "../../../components";
import { severalStore } from "../../../stores";

function General() {
  const { formSelected }: any = severalStore();
  return (
    <Accordion
      size={"large"}
      items={[
        {
          key: "1",
          label: "Datos basicos",
          children: (
            <Row className="row-form">
              <Col xs={24} md={11} xl={11}>
                <Row className="row-form">
                  <Col xs={24} md={12} xl={12} style={{ marginRight: 5 }}>
                    <Form.Item label="Nit">
                      <Input
                        placeholder="Nit"
                        disabled={true}
                        value={formSelected.nit}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item label="DV">
                      <Input
                        placeholder="DV"
                        disabled={true}
                        value={formSelected.dv}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={11} xl={11}>
                <Form.Item label="Nombre">
                  <Input
                    placeholder="Nombre"
                    disabled={true}
                    value={formSelected.name}
                  />
                </Form.Item>
              </Col>
            </Row>
          ),
        },
      ]}
    />
  );
}

export default General;
