import CurrencyInput from "react-currency-input-field";

function CurrencyInputCustom({
  placeholder = "",
  name = "",
  onChange,
  disabled = false,
  style = {},
  value = "",
  readOnly = false,
  min = "",
  autoComplete = "off",
}: any) {
  return (
    <CurrencyInput
      style={{ ...style }}
      name={name}
      id={name}
      placeholder={placeholder}
      value={value}
      decimalsLimit={2}
      decimalScale={2}
      decimalSeparator=","
      groupSeparator="."
      disabled={disabled}
      readOnly={readOnly}
      min={min}
      className={
        "ant-input css-dev-only-do-not-override-jprjbc css-jprjbc ant-input-outlined ant-input-lg css-dev-only-do-not-override-d2wai0"
      }
      prefix="$"
      autoComplete={autoComplete}
      onValueChange={(value: any, name) =>
        onChange({
          target: { name, value: value ? value : "" },
        })
      }
    />
  );
}

export default CurrencyInputCustom;
