import "./style.scss";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  FileTextOutlined,
  LogoutOutlined,
  Loading3QuartersOutlined,
  ProjectOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { routes, applicationModules } from "../../constants";
import { validatePermissions } from "../../utilities";
import { userStore, severalStore } from "../../stores";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: String(applicationModules.home),
    label: "INICIO",
    icon: <HomeOutlined style={{ fontSize: 18 }} />,
  },
  {
    key: String(applicationModules.characterization),
    label: "CARACTERIZACIÓN",
    icon: <TeamOutlined style={{ fontSize: 18 }} />,
  },
  {
    key: String(applicationModules.diagnostic),
    label: "DIAGNÓSTICO",
    icon: <Loading3QuartersOutlined style={{ fontSize: 18 }} />,
  },
  /* {
    key: String(applicationModules.economicActivity),
    label: "ACTIVIDAD ECONÓMICA",
    icon: <ProjectOutlined style={{ fontSize: 18 }} />,
  }, */
  
  {
    key: String(applicationModules.sarlaft),
    label: "GUÍA SARLAFT",
    icon: <FileTextOutlined style={{ fontSize: 18 }} />,
  },
  /* {
    key: String(applicationModules.adminitration),
    label: "ADMINISTRACIÓN",
    icon: <SettingOutlined style={{ fontSize: 18 }}/>,
  }, */
  {
    key: "10000",
    label: "SALIR",
    icon: <LogoutOutlined style={{ fontSize: 18 }} />,
  },
];

interface IProps {
  style?: React.CSSProperties;
}

function MyMenu({ style = { width: 200 } }: IProps) {
  const navigate = useNavigate();
  const { permissions, logout } = userStore();
  const { itemMenuSelected, update } = severalStore();

  const selectOption = (option: string) => {
    update({ itemMenuSelected: option });
    switch (option) {
      case String(applicationModules.home):
        navigate(routes.home);
        break;

      case String(applicationModules.characterization):
        navigate(routes.characterization);
        break;
      case String(applicationModules.diagnostic):
        navigate(routes.diagnostic);
        break;

      case String(applicationModules.sarlaft):
        navigate(routes.sarlaft);
        break;
      case String(applicationModules.economicActivity):
        navigate(routes.economicActivity);
        break;
      case String(applicationModules.adminitration):
        navigate(routes.administration);
        break;
      case "10000":
        logout();
        break;

      default:
        navigate(routes.home);
        break;
    }
  };

  const onClick: MenuProps["onClick"] = (e) => {
    selectOption(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      style={{ height: "100%", ...style }}
      defaultSelectedKeys={[itemMenuSelected]}
      defaultOpenKeys={[itemMenuSelected]}
      mode="inline"
      items={items.filter(
        (x: any) =>
          validatePermissions(parseInt(x.key), permissions) ||
          x.key === "0" ||
          x.key === "10000"
      )}
    />
  );
}

export default MyMenu;
