import { create } from "zustand";
import dayjs from "dayjs";
import { paxios } from "../http";
import { URL_SERVER } from "../config";
import { orderArrayObject } from "../utilities";
const now = dayjs();

const initialData = {
  departments: [],
  municipalies: [],
  faSelectdMap: null,
  ipSelectedMap: null,
  showLoading: false,
  menuMobileOpen: false,
  menuIsClosing: false,
  itemMenuSelected: "0",
  vinculations: [],
  vinculationsType: [],
  categoriesAssociationTypes: [],
  associationTypes: [],
  federationCharacter: [],
  groupEconomicActivity: [],
  economicActivity: [],
  integrationProcess: [],
  federationsTypesIds: [],
  chambersCommerce: [],
  niifs: [],
  documentTypes: [],
  banks: [],
  formSelected: {
    id: 0,
    commercial_contact_cellphone_number: "",
    commercial_contact_email: "",
    commercial_contact_cellphone_number_2: "",
    commercial_contact_web_site: "",
    commercial_registration_id: "",
    contact_cellphone_number: "",
    contact_document_number: "",
    contact_document_type: "",
    contact_first_name: " ",
    contact_last_name: "",
    contact_cellphone_number_2: "",
    coverage: "",
    created_date: now.format("YYYY-MM-DD"),
    dv: "",
    initials: "",
    last_renovation_date: now.format("YYYY-MM-DD"),
    legality_control_date: now.format("YYYY-MM-DD"),
    legality_control_supersolidaria: "",
    name: "",
    nit: "",
    other_territorial_link: "",
    registration_date: now.format("YYYY-MM-DD"),
    special_tributary_regime: "N",
    village: "",
    association_type_id: "0",
    department_id: "",
    municipality_id: "",
    vinculation_id: 0,
    vinculation_type_id: 0,
    latitude: "",
    longitude: "",
    address: "",
    description: "",
    constitution_act: "",
    women: 0,
    men: 0,
    associated: 0,
    lgtbi: null,
    admin_women: null,
    disabled: null,
    indigenous: null,
    afro: null,
    ecomun_region: null,
    status: null,
    pdet_zone_id: null,
    migration: null,
    percentage: 79,
    observations: null,
    has_branches: 0,
    federation_character: 0,
    economic_activity: 0,
    economic_activity_object: null,
    economic_activity_2: 0,
    economic_activity_2_object: null,
    economic_activity_3: 0,
    economic_activity_3_object: null,
    economic_activity_other: "",
    area_economic_activity: "U",
    address_economic_activity: "",
    department_id_economic_activity: 0,
    municipality_id_economic_activity: 0,
    village_economic_activity: "",
    latitude_economic_activity: 0,
    longitude_economic_activity: 0,
    integration_process: 0,
    integration_process_which: [],
    branches: [],
    chamber_commerce_id: 0,
    niif_id: 0,
    legal_representative: [],
    contact_email: "",
    contact_website: "",
    contact_facebook: "",
    contact_twitter: "",
    contact_instagram: "",
    bank_accounts: [],
    percentage_progress_information: {
      percentageGeneral: 0,
      percentageContact: 0,
      percentageLegal: 0,
      percentageBank: 0,
      percentageTotal: 0,
    },
    numberLoad: 1,
  },
  associateRoles: [],
  associateRolesComittee: [],
  associateTypes: [],
  genders: [],
  associateTypesVinculation: [],
  ageRange: [],
  levelSchooling: [],
  maritalStatus: [],
  disabilities: [],
  ethnicGroups: [],
  associativeSituation: [],
  associativeFormInitials: [],
  categoryQuestionDiagnostic: [],
  subCategoryQuestionDiagnostic: [],
  diagnosticPeriodicity: [],
  diagnosticTypePeriodicity: [],
  questionsOptionsDiagnostic: [],
  diagnosticQuestions: [],
  formAntAnswers: null,
  entities: [],
  unitMeasurementPresentation: [],
  periodicityBatchProduction: [],
  typeLaborContract: [],
  machineryProperty: [],
  landProperty: [],
  productionType: [],
  inputsFarmingProduct: [],
  productionPurpose: [],
  unitsMeasurementSalePriceLivestockProduct: [],
};

const getDepartmentsServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/home_public/getDepartments`);
    const depa = orderArrayObject(u.data, "name");
    set({ departments: depa });
  } catch (error) {}
};

const getMunicipaliesServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/home_public/getMunicipalies`);
    const muni = orderArrayObject(u.data, "name");
    set({ municipalies: muni });
  } catch (error) {}
};

const getVinculationsServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/several/getVinculations`);
    set({ vinculations: u.data.data });
  } catch (error) {}
};

const getVinculationsTypesServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/several/getVinculationsTypes`);
    set({ vinculationsType: u.data.data });
  } catch (error) {}
};

const getAssociationTypesServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/several/getAssociationTypes`);
    set({ associationTypes: u.data.data });
  } catch (error) {}
};

const getFederationCharacterServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/several/getFederationCharacter`);
    set({ federationCharacter: u.data.data });
  } catch (error) {}
};

const getGroupEconomicActivityServer = async (set: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/several/getGroupEconomicActivity`
    );
    set({ groupEconomicActivity: u.data.data });
  } catch (error) {}
};

const getEconomicActivityServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/several/getEconomicActivity`);
    set({ economicActivity: u.data.data });
  } catch (error) {}
};

const getSeveralDataServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/several/getSeveralData`);
    set({ ...u.data.data });
  } catch (error) {}
};

const getEntitiesServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/home_public/getEntities`);
    set({ entities: u.data.data });
  } catch (error) {}
};

const getSeveralDataEconomicActivityServer = async (set: any) => {
  try {
    const u = await paxios.get(
      `${URL_SERVER}/several/getSeveralDataEconomicActivity`
    );
    set({ ...u.data.data });
  } catch (error) {}
};

const store = (set: any) => ({
  ...initialData,
  update: (data: any) => {
    set({ ...data });
  },
  setFaSelectdMap: (faSelectdMap: any) => {
    set({ faSelectdMap });
  },
  setIpSelectedMap: (ipSelectedMap: any) => {
    set({ ipSelectedMap });
  },
  setShowLoading: (value: boolean) => {
    set({ showLoading: value });
  },
  getDepartments: async () => {
    await getDepartmentsServer(set);
  },
  getMunicipalies: async () => {
    await getMunicipaliesServer(set);
  },
  getVinculations: async () => {
    await getVinculationsServer(set);
  },
  getVinculationsTypes: async () => {
    await getVinculationsTypesServer(set);
  },
  getAssociationTypes: async () => {
    await getAssociationTypesServer(set);
  },
  getFederationCharacter: async () => {
    await getFederationCharacterServer(set);
  },
  getGroupEconomicActivity: async () => {
    await getGroupEconomicActivityServer(set);
  },
  getEconomicActivity: async () => {
    await getEconomicActivityServer(set);
  },
  getSeveralData: async () => {
    await getSeveralDataServer(set);
  },
  getEntities: async () => {
    await getEntitiesServer(set);
  },
  getSeveralDataEconomicActivity: async () => {
    await getSeveralDataEconomicActivityServer(set);
  },
});

const severalStore = create(store);

export default severalStore;
