import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import { enableButtonSave } from "../../../../utilities";
import { applicationModules } from "../../../../constants";
import { severalStore, userStore } from "../../../../stores";
import dayjs from "dayjs";

function GeneralInformation({ handleClickSave, errors, data, setValues }: any) {
  const { permissions } = userStore();
  const { documentTypes, genders }: any = severalStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="first_name" label="Nombres">
            <Input placeholder="Nombres" />
          </Form.Item>
          {errors?.first_name && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.first_name}
              type="error"
              showIcon
            />
          )}
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="last_name" label="Apellidos">
            <Input placeholder="Apellidos" />
          </Form.Item>
          {errors?.last_name && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.last_name}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Tipo documento" name={"document_type"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={documentTypes.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item name="document_number" label="No. de documento">
            <Input placeholder="No. de documento" type="number" />
          </Form.Item>
          {errors?.document_number && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.document_number}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Genero" name={"gender_id"}>
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={genders.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Fecha nacimiento">
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              value={dayjs(data?.birth_date)}
              onChange={(date: any, dateString: any) =>
                setValues("birth_date", dateString)
              }
            />
          </Form.Item>
          {errors?.birth_date && (
            <Alert
              style={{ marginTop: "-20px" }}
              message={errors.birth_date}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>

      <Divider />
      <Row className="row-form">
        <Col xs={24} md={18} xl={18}></Col>
        <Col xs={24} md={6} xl={6}>
          {enableButtonSave(applicationModules, permissions) && (
            <Button block={true} type="primary" onClick={handleClickSave}>
              Guardar
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default GeneralInformation;
