import alertImg01 from "../../../../assets/Images/sarlaft-images/alert-img-01.png";
import { downloadFile } from "../../../../http";
import { URL_SERVER } from "../../../../config";
import { Button } from "antd";

function WarningSigns() {
  const getAlertSigns = () => {
    downloadFile(
      `${URL_SERVER}/sarlaft/dowloadfiles`,
      "Señales_De_Alerta.pdf",
      {
        file: "Senales_De_Alerta.pdf",
      }
    );
  };

  return (
    <div className="container-md p-0 alertSignsexpPanel">
      <div className="row w-100 mx-auto">
        <div className="col-md-4 d-md-flex justify-content-center">
          <img className="matExp-img" src={alertImg01} alt="" />
        </div>
        <div className="col-md-8">
          <h2 className="matExp-title">Señales de Alerta</h2>
          <div className="matExp-desc">
            De acuerdo con la UIAF y algunas recomendaciones internacionales
            dispuestas por el GAFI y el sector solidario es importante tener en
            cuenta las siguientes señales de alertas:
          </div>

          <Button
            className="helpButtons"
            type="primary"
            onClick={() => getAlertSigns()}
          >
            Descargar documento
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WarningSigns;
