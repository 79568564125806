import { Grid, theme, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import Header from "../header/Header";
import { validateEmail } from "../../../servicesHTTP";
import { useEffect, useState } from "react";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

function ValidatedEmail() {
  let { userToken, id } = useParams();
  const { token } = useToken();
  const screens = useBreakpoint();
  const [texto, setTexto] = useState("");
  const styles: any = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
      borderRadius: "5px",
      boxShadow: "1px 1px 12px 0px rgba(0, 0, 0, 0.1)",
    },
    text: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  useEffect(() => {
    (async () => {
      if (userToken !== "" && id !== "") {
        const result = await validateEmail(userToken, id);
        if (result.status === "success")
          setTexto(
            "Su correo ha sido validado, el administrador verificará su información para permitir el ingreso a la plataforma "
          );
        else
          setTexto(
            "Este enlace ya fue utilizado o ya se encuentra vencido, por favor registre sus datos nuevamente"
          );
      }
    })();
  }, [userToken, id]);

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/coffee-background.png"
          })`,
        }}
      >
        <section style={styles.section}>
          <div style={styles.container}>
            <div style={styles.header}>
              <div style={styles.header}>
                <Title style={styles.title}>Validar correo</Title>
                <Text style={styles.text}>{texto}</Text>
              </div>
            </div>
            <div style={styles.footer}>
              <Link to="/">Ir al Inicio</Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ValidatedEmail;
