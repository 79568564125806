import { create } from "zustand";
import { setJWT, paxios } from "../http";
import { URL_SERVER } from "../config";
import { localStorageAddUpdate, localStorageDelete } from "../localStorage";
import severalStore from "./severalStore";

const initialData = {
  id: 5000,
  active: true,
  cellphone_number: "",
  created_date: null,
  email: "",
  name: "",
  status: "",
  role_id: 0,
  user_type_id: 0,
  associative_form_id: 0,
  username: "",
  created_at: "",
  updated_at: "",
  token: null,
  permissions: [],
};

const getUserServer = async (set: any) => {
  try {
    const u = await paxios.get(`${URL_SERVER}/user/me`);
    set({ ...u.data.user, permissions: u.data.permissions });
  } catch (error) {}
};

const loginServer = async (email: string, password: string) => {
  try {
    const u = await paxios.post(`${URL_SERVER}/login`, {
      email,
      password,
    });
    if (u.data.status === "success") {
      return [u.data.authorisation.token, u.data.status];
    }
    return ["", u.data.status];
  } catch (error: any) {
    return ["", "error"];
  }
};

const store = (set: any) => ({
  ...initialData,
  setToken: (token: any) => {
    setJWT(token);
    set({ token });
    localStorageAddUpdate("token", token);
  },
  getUser: async () => {
    await getUserServer(set);
  },
  login: async (email: string, password: string) => {
    const result = await loginServer(email, password);
    if (result[0] !== "") {
      setJWT(result[0]);
      set({ token: result[0] });
      localStorageAddUpdate("token", result[0]);
      getUserServer(set);
    }

    return result;
  },
  logout: () => {
    localStorageDelete("token");
    set({ ...initialData });
    severalStore
      .getState()
      .update({ formSelected: null, itemMenuSelected: "0" });
  },
});

const userStore = create(store);

export default userStore;
