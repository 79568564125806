import { Col, Form, Input, Row } from "antd";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

function ProductionVolume({ handleChange, record }: any) {
  const { unitMeasurementPresentation }: any = severalStore();
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"¿Qué cantidad espera producir en este ciclo?"}>
            <Input
              placeholder=""
              name="how_much_expect_produce_this_cycle"
              onChange={handleChange}
              value={record.how_much_expect_produce_this_cycle}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Unidad de medida">
            <Select
              name="unit_measurement_expect_produce_this_cycle_id"
              value={record.unit_measurement_expect_produce_this_cycle_id}
              onChange={handleChange}
              options={unitMeasurementPresentation.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
              option0={true}
              placeholder="Seleccionar"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default ProductionVolume;
