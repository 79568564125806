import { Col, Form, Row } from "antd";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

function PeriodicityBatchProduction({ handleChange, record }: any) {
  const { periodicityBatchProduction }: any = severalStore();
  return (
    <Form layout="vertical" autoComplete="off">
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label="Unidad de medida">
            <Select
              name="periodicity_batch_production_id"
              value={record.periodicity_batch_production_id}
              onChange={handleChange}
              options={periodicityBatchProduction.map((x: any) => ({
                value: x.id,
                label: x.name,
              }))}
              option0={true}
              placeholder="Seleccionar"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>
    </Form>
  );
}

export default PeriodicityBatchProduction;
