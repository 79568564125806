import { useState, forwardRef, useImperativeHandle, useRef } from "react";
import { Button, Input, Space, Table } from "antd";
import type { TableProps, TableColumnType, InputRef } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

interface IProps extends TableProps {
  multiSelect?: boolean;
  onChangeSelect?: (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => void;
  selection?: boolean;
  clickSelect?: boolean;
}
//EL clickSelect NO SE USA CON EL multiSelect
const TableCustom = forwardRef((props: IProps, ref: any) => {
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    props.onChangeSelect &&
      props.onChangeSelect(newSelectedRowKeys, newSelectedRows);
  };

  useImperativeHandle(ref, () => ({
    getSelectedRowKeys: () => selectedRowKeys,
    setSelectedRowKeys,
  }));

  const propsLocal = () => {
    const propsL: any = {};

    if (props.selection === undefined || props.selection) {
      propsL.rowSelection = {
        //type: "radio",
        hideSelectAll: true,
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: any) => {
          if (props.multiSelect) return {};

          let disa = false;
          if (selectedRowKeys.length > 0) {
            const row = selectedRowKeys.includes(record.key);
            if (!row) {
              disa = true;
            }
          }
          return {
            disabled: disa,
          };
        },
      };
    }

    propsL.onRow = (record: any, rowIndex: any) => {
      let events: any = {};

      if (props.clickSelect) {
        events.onClick = (event: any) => {
          setSelectedRowKeys([record.key]);
          props.onChangeSelect && props.onChangeSelect([record.key], [record]);
        };
      }

      return events;
    };
    return propsL;
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: any,
    title: string,
    render: any
  ): TableColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) =>
      render ? null : (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: render
      ? render
      : (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
            text
          ),
  });

  return (
    <Table
      {...props}
      columns={props.columns?.map((x: any) => ({
        ...x,
        sorter: (a: any, b: any) => {
          if (a[x.key] > b[x.key]) {
            return 1;
          }
          if (a[x.key] < b[x.key]) {
            return -1;
          }

          return 0;
        },
        ...getColumnSearchProps(x.key, x.title, x.render),
      }))}
      {...propsLocal()}
      showSorterTooltip={{ target: "sorter-icon" }}
      /* rowSelection={{
        //type: "radio",
        hideSelectAll: true,
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: any) => {
          if (props.multiSelect) return {};

          let disa = false;
          if (selectedRowKeys.length > 0) {
            const row = selectedRowKeys.includes(record.key);
            if (!row) {
              disa = true;
            }
          }
          return {
            disabled: disa,
          };
        },
      }} */
    />
  );
});

export default TableCustom;
