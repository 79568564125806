import { Accordion, CircularProgressWithLabel } from "../../../../components";
import { severalStore } from "../../../../stores";
import Obligations from "./Obligations";
import GoodPractices from "./GoodPractices";

function Accountant({
  handleClickSave,
  errors,
  data,
  setValues,
  visible = true,
}: any) {
  const { subCategoryQuestionDiagnostic }: any = severalStore();
  return (
    <Accordion
      style={visible ? {} : { display: "none" }}
      size={"large"}
      defaultActiveKey={["16"]}
      items={[
        {
          key: "16",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 16)
            ?.name,
          children: <Obligations />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedAccountant.completedObligations}
            />
          ),
        },
        {
          key: "17",
          label: subCategoryQuestionDiagnostic.find((x: any) => x.id === 17)
            ?.name,
          children: <GoodPractices />,
          extra: (
            <CircularProgressWithLabel
              size={35}
              value={data.completedAccountant.completedGoodPractices}
            />
          ),
        },
      ]}
    />
  );
}

export default Accountant;
